import { DateFormats, FormActions, TournamentSignUpPage, TournamentSignUpPageActions } from 'core.frontend';
import * as React from 'react';
import Button from '../../misc/components/Button';
import ErrorMessage from '../../misc/components/ErrorMessage';
import FormattedDate from '../../misc/components/FormattedDate';
import AnchorButton from '../../misc/components/AnchorButton';
import ObservingComponent from '../../misc/components/ObservingComponent';
import TournamentSignUpAtheleteInfo from './TournamentSignUpAthleteInfo';

interface TournamentSignUpFormProps {
    page: TournamentSignUpPage;
}

class TournamentSignUpForm extends ObservingComponent<TournamentSignUpFormProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, TournamentSignUpPageActions.TournamentLoaded);
        this.registerUpdateObserver(page.signUpForm.observationProvider, FormActions.FormSubmissionFailure);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <React.Fragment>
                {page.tournament && (
                    <React.Fragment>
                        <ErrorMessage errorMessage={page.signUpForm.errorMessage} />
                        <div className="text-right bottom-m-1">
                            Access Code: <b>{page.currentParentAccountManager.parentAccount.accessCode} </b>
                            <AnchorButton
                                className="underline"
                                onClick={page.showForgetAccessCodeConfirmationPrompt}
                                text="Change"/>
                        </div>

                        <div className="tournament-signup-info">
                            <div>Location: <b>{ page.tournament.location }</b></div>
                            <div>Date: <b><FormattedDate date={ page.tournament.date}/></b></div>
                            <div>Warmup Time: <b><FormattedDate date={ page.tournament.date} format={DateFormats.hmmA}/></b></div>
                            <div>Division Cutoff: <b>{ page.tournament.divisionCuttoffDescription }</b></div>
                        </div>

                        <form>

                            {page.currentParentAccountManager.parentAccount.athletes.map((athlete, index: number) => {
                                return <TournamentSignUpAtheleteInfo athlete={athlete} athleteForm={page.signUpForm.forms[index]} />;
                            })}

                            <footer>
                                <div>
                                    <Button
                                        type="submit"
                                        className="btn btn-primary btn-wide-phone"
                                        onClick={page.signUpForm.submit}
                                        rightIcon="fa-chevron-right"
                                        text="Next" />
                                </div>
                            </footer>
                        </form>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default TournamentSignUpForm;
