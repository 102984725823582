import { DateFormats, TournamentSignUpPage } from 'core.frontend';
import * as React from 'react';
import Button from '../../misc/components/Button';
import FormattedDate from '../../misc/components/FormattedDate';

interface TournamentSignUpInactiveProps {
    page: TournamentSignUpPage;
}

class TournamentSignUpInactive extends React.Component<TournamentSignUpInactiveProps> {
    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <div className="container top-p-2">

            { page.tournament.signUpNotYetActive && (
                    <React.Fragment>

                        <div className="text-center content-header-block">
                            <h1>{page.tournament.name}</h1>
                            <i className="fa fa-clock"></i>
                            <h3>Sign-Up Not Active Yet</h3>
                            <div>Sign-Up Begins: <FormattedDate date={page.tournament.signUpStartDateTime}  format={DateFormats.dddMMMDhmmA}/></div>
                            <div>Sign-Up Expires: <FormattedDate date={page.tournament.signUpEndDateTime}  format={DateFormats.dddMMMDhmmA}/></div>
                        </div>

                        <p>
                            Your a little early. Please check back once the sign-up date is here. Be sure to sign-up prior to the deadline.
                        </p>

                        <Button
                            className="btn btn-primary btn-wide-phone"
                            onClick={page.goToHome}
                            text="Back to Home Page"
                            leftIcon="fa-chevron-left"/>

                    </React.Fragment>
                )}

                { page.tournament.signUpIsExpired && (
                    <React.Fragment>

                        <div className="text-center content-header-block">
                            <h1>{page.tournament.name}</h1>
                            <i className="fa fa-calendar-times red"></i>
                            <h3>Sign Up Expired</h3>
                            <div>Sign-Up Expired: <FormattedDate date={page.tournament.signUpEndDateTime} format={DateFormats.dddMMMDhmmA}/></div>
                        </div>

                        <p>
                            It looks like you have missed the deadline to sign up for the { page.tournament.name} tournament.
                            Tournament coordinators keep strict deadlines due to the effort it takes to organize hundreds or
                            thousands of entries. Please be sure to sign-up for each tournament soon after sign-ups are activated
                            so that you don't miss the deadlines.
                        </p>

                        <Button
                            className="btn btn-primary btn-wide-phone"
                            onClick={page.goToHome}
                            text="Back to Home Page"
                            leftIcon="fa-chevron-left"/>

                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default TournamentSignUpInactive;
