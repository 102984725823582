import { RegistrationActions, RegistrationPage } from 'core.frontend';
import * as React from 'react';
import Field from '../../misc/components/fields/Field';
import ObservingComponent from '../../misc/components/ObservingComponent';

interface RegistrationEmergencyContactFormContainerProps {
    page: RegistrationPage;
}

class RegistrationEmergencyContactFormContainer extends ObservingComponent<RegistrationEmergencyContactFormContainerProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, RegistrationActions.SubmissionFailure);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <div className="form-container">
                <h4>Additional Emergency Contacts</h4>

                <p>To ensure that we can contact someone in the event of an emergency, please provide as may additional individuals as possible. These folks will not receive regular team notifications.</p>

                <div className="row">

                    {page.emergencyContactForm.fields.map((field) => (
                        <div className="col-12 col-sm-4">
                            <Field field={field} />
                        </div>
                    ))}

                </div>
            </div>
        );
    }
}

export default RegistrationEmergencyContactFormContainer;
