import { TournamentsPage, TournamentTableItemDto } from 'core.frontend';
import * as React from 'react';
import AnchorButton from '../../misc/components/AnchorButton';
import Button from '../../misc/components/Button';
import Card from '../../misc/components/Card';
import FormattedDate from '../../misc/components/FormattedDate';
import SmartTable from '../../misc/components/smartTable';
import SmartTableColumn from '../../misc/smartTableColumn';

interface TournamentsListProps {
    page: TournamentsPage;
}

class TournamentsList extends React.PureComponent<TournamentsListProps> {

    private buildColumns = (): SmartTableColumn<TournamentTableItemDto>[] => {
        const { page } = this.props;

        return [
            {
                heading: 'Name',
                sortExpression: 'name',
                cellTemplate: (tournament) => <span>{tournament.name}</span>,
                appendSavingIndicator: true
            }, {
                heading: 'Date',
                sortExpression: 'date',
                cellTemplate: (tournament) => <FormattedDate date={tournament.date} />
            }, {
                heading: 'Location',
                sortExpression: 'location',
                className: 'dt-only',
                cellTemplate: (tournament) => <span>{tournament.location}</span>
            }, {
                headingClassName: 'button-column-spacer',
                hideWhenSaving: true,
                cellTemplate: (tournament) => <AnchorButton
                                                    className="btn-details"
                                                    onClick={page.goToTournamentDetail(tournament.id)}
                                                    rightIcon="fa-arrow-right" />
            }
        ];
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <Card
                heading={page.title}
                headingRightContent={
                    <Button
                        className="btn btn-primary"
                        onClick={page.addTournament}
                        leftIcon="fa-plus"
                        text="New" />
                }>

                <SmartTable
                    table={page.table}
                    columns={this.buildColumns()} />
            </Card>
        );
    }
}

export default TournamentsList;
