import { ActionUtils, CheckBoxField as FrontEndCheckBoxField } from 'core.frontend';
import FieldActions from 'core.frontend/dist/components/fieldActions';
import * as React from 'react';
import ObservingComponent from '../ObservingComponent';

export interface CheckboxFieldProps {
    className?: string;
    field: FrontEndCheckBoxField;
    labelClassName: string;
}

class CheckboxField extends ObservingComponent<CheckboxFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public valueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { field } = this.props;
        const isChecked = e.target.checked;

        field.setValue(isChecked ? true : false);
    }

    public render(): JSX.Element {
        const { field, labelClassName, className } = this.props;

        return (
            <div className={'checkbox ' + className} >
                <label
                    className={labelClassName}
                    htmlFor={field.key}>

                    {field.label}

                    <input
                        type="checkbox"
                        id={field.key}
                        name={field.key}
                        onFocus={field.focus}
                        onBlur={field.blur}
                        checked={field.value === true}
                        onChange={this.valueChanged}
                        className={field.errorMessage ? 'invalid' : ''}
                        disabled={field.isDisabled} />

                </label>

                { field.errorMessage && (
                    <span className="invalid error-message">{field.errorMessage}</span>
                )}
            </div>
        );
    }
}

export default CheckboxField;
