import RegistrationDto from 'core.frontend/dist/api/registration/registrationDto';
import * as React from 'react';
import Table from '../../misc/components/Table';

interface RegistrationAdditionalInfoProps {
    registration: RegistrationDto;
}

class RegistrationAdditionalInfo extends React.PureComponent<RegistrationAdditionalInfoProps> {

    public render(): JSX.Element {
        const { registration } = this.props;

        const items = [
            {
                label: 'Emergency Contact',
                value: registration.emergencyContactName
            },
            {
                label: 'Emergency Contact Phone',
                value: registration.emergencyContactCellPhone
            },
            {
                label: 'Emergency Contact',
                value: registration.emergencyContactRelationship
            },
            {
                label: 'Health Care Provider',
                value: registration.healthCareProviderName
            },
            {
                label: 'Health Care Provider Phone',
                value: registration.healthCareProviderPhone
            },
            {
                label: 'Insurance Company',
                value: registration.insuranceCompany
            },
            {
                label: 'Policy Holder',
                value: registration.insurancePolicyHolder
            },
            {
                label: 'Group Number',
                value: registration.insuranceGroupNumber
            },
            {
                label: 'Policy Number',
                value: registration.insurancePolicyNumber
            },
            {
                label: 'Insurance Phone',
                value: registration.insurancePhone
            },
            {
                label: 'Preferred Hospital',
                value: registration.preferredHospital
            }
        ];

        return (
            <div className="form-container">
                <h6>Additional Info</h6>
                <Table
                    hideHeading={true}
                    columns={[{
                            heading: '',
                            cellTemplate: (item) => item.label
                        }, {
                            heading: '',
                            cellTemplate: (item) => item.value
                        }]}
                    items={items} />
            </div>
        );
    }
}

export default RegistrationAdditionalInfo;
