// tslint:disable-next-line:ordered-imports
import 'bootstrap/dist/css/bootstrap.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ReactApp from './misc/reactApp';
import AppRoot from './root/Components/AppRoot';
import './styles/main.css';

const reactApp = new ReactApp();

ReactDOM.render(<AppRoot reactApp={reactApp}/>, document.getElementById('root'));
