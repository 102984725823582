"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var baseField_1 = require("./baseField");
var DecimalField = /** @class */ (function (_super) {
    __extends(DecimalField, _super);
    function DecimalField(key, label, isRequired, requiredValidationMessage, initialValue) {
        var _this = _super.call(this, _1.FieldType.Decimal, key, label, isRequired, 10, requiredValidationMessage) || this;
        _this.key = key;
        if (initialValue) {
            _this.setValue(initialValue);
        }
        return _this;
    }
    Object.defineProperty(DecimalField.prototype, "errorMessage", {
        get: function () {
            var baseErrorMessage = _super.prototype.getErrorMessage.call(this);
            if (baseErrorMessage) {
                return baseErrorMessage;
            }
            if (this.value && !this.isValidDecimal() && this.isDirty) {
                return 'Amount is invalid';
            }
            return baseErrorMessage;
        },
        enumerable: false,
        configurable: true
    });
    DecimalField.prototype.isValidDecimal = function () {
        var regex = /^\d+(\.\d{1,2})?$/;
        return regex.test(this.value.toString());
    };
    DecimalField.prototype.isValid = function () {
        var isValid = true;
        if (!this.checkRequiredStatus()) {
            isValid = false;
        }
        if (this.stringValue && !this.isValidDecimal()) {
            isValid = false;
        }
        this.setIsValid(isValid);
        return isValid;
    };
    DecimalField.prototype.setValue = function (newValue) {
        if (newValue === undefined || newValue === null) {
            this.setStringValue('');
        }
        else {
            this.setStringValue(this.formatFromPartial(newValue.toString()));
        }
    };
    Object.defineProperty(DecimalField.prototype, "value", {
        get: function () {
            if (!this.stringValue) {
                return null;
            }
            return parseFloat(this.stringValue);
        },
        enumerable: false,
        configurable: true
    });
    DecimalField.prototype.formatFromPartial = function (value) {
        var formattedStr = '';
        for (var i = 0; i < value.length; i++) {
            var char = value.charAt(i);
            if ('0123456789.'.indexOf(char) !== -1) {
                formattedStr += char;
            }
        }
        return formattedStr;
    };
    return DecimalField;
}(baseField_1.default));
exports.default = DecimalField;
