"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var endpointUrls_1 = require("../../api/endpointUrls");
var registrationDto_1 = require("../../api/registration/registrationDto");
var components_1 = require("../../components");
var observationProvider_1 = require("../../utils/observationProvider");
var RegistrationActions_1 = require("./RegistrationActions");
var registrationPageState_1 = require("./registrationPageState");
var RegistrationPage = /** @class */ (function (_super) {
    __extends(RegistrationPage, _super);
    function RegistrationPage(api, navigator) {
        var _this = _super.call(this, __1.PageTypes.Registration, 'Registration') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.removeAthlete = function (index) {
            if (_this._atheleteForms.length > 1 && index < _this._atheleteForms.length && index >= 0) {
                _this._atheleteForms.splice(index, 1);
                _this.observationProvider.notifyObservers(RegistrationActions_1.default.AthleteRemoved);
            }
        };
        _this.addAthlete = function () {
            if (_this._atheleteForms.length < 5) {
                _this._atheleteForms.push(_this.buildAthleteForm());
                _this.observationProvider.notifyObservers(RegistrationActions_1.default.AthleteAdded);
            }
        };
        _this.removeGuardian = function (index) {
            if (_this._guardianForms.length > 1 && index < _this._guardianForms.length && index >= 0) {
                _this._guardianForms.splice(index, 1);
                _this.observationProvider.notifyObservers(RegistrationActions_1.default.GuardianRemoved);
            }
        };
        _this.addGuardian = function () {
            if (_this._guardianForms.length < 3) {
                _this._guardianForms.push(_this.buildGuardianForm());
                _this.observationProvider.notifyObservers(RegistrationActions_1.default.GuardianAdded);
            }
        };
        _this.submit = function () {
            _this.submitAllForms();
            if (_this.isFormValid()) {
                var model = _this.getModel();
                _this._isSubmitting = true;
                var promise = void 0;
                if (model.id) {
                    promise = _this.api.put(endpointUrls_1.default.Registrations, model.id, model);
                }
                else {
                    promise = _this.api.post(endpointUrls_1.default.Registrations, model)
                        .then(function (response) {
                        _this._registrationId = response.registrationId;
                        _this._accessCode = response.accessCode;
                    });
                }
                promise
                    .then(function () {
                    _this._isSubmitting = false;
                    _this.setState(registrationPageState_1.default.Review);
                })
                    .catch(function () {
                    _this._isSubmitting = false;
                    _this.observationProvider.notifyObservers(RegistrationActions_1.default.SubmissionFailure);
                });
                _this.observationProvider.notifyObservers(RegistrationActions_1.default.FormSubmission, model);
            }
            else {
                _this.observationProvider.notifyObservers(RegistrationActions_1.default.SubmissionFailure);
            }
        };
        _this.setState = function (pageState) {
            _this._pageState = pageState;
            _this.observationProvider.notifyObservers(RegistrationActions_1.default.StateChanged, pageState);
        };
        _this.submitAllForms = function () {
            for (var _i = 0, _a = _this._atheleteForms; _i < _a.length; _i++) {
                var form = _a[_i];
                form.submit();
            }
            for (var _b = 0, _c = _this._guardianForms; _b < _c.length; _b++) {
                var form = _c[_b];
                form.submit();
            }
            _this._emergencyContactForm.submit();
            _this._insuranceForm.submit();
            _this._healthCareProviderForm.submit();
        };
        _this.getModel = function () {
            var model = new registrationDto_1.default();
            model = __assign(__assign(__assign(__assign(__assign({}, model), { id: _this._registrationId, athletes: [], guardians: [] }), _this.emergencyContactForm.model), _this.insuranceForm.model), _this.healthCareProviderForm.model);
            for (var _i = 0, _a = _this.atheleteForms; _i < _a.length; _i++) {
                var athleteForm = _a[_i];
                model.athletes.push(athleteForm.model);
            }
            for (var _b = 0, _c = _this.guardianForms; _b < _c.length; _b++) {
                var guardianForm = _c[_b];
                model.guardians.push(guardianForm.model);
            }
            return model;
        };
        _this._pageState = registrationPageState_1.default.GettingStarted;
        _this.buildForm();
        _this.observationProvider = new observationProvider_1.default();
        return _this;
    }
    Object.defineProperty(RegistrationPage.prototype, "isSubmitting", {
        get: function () {
            return this._isSubmitting;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RegistrationPage.prototype, "registrationId", {
        get: function () {
            return this._registrationId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RegistrationPage.prototype, "pageState", {
        get: function () {
            return this._pageState;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RegistrationPage.prototype, "accessCode", {
        get: function () {
            return this._accessCode;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RegistrationPage.prototype, "atheleteForms", {
        get: function () {
            return this._atheleteForms;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RegistrationPage.prototype, "guardianForms", {
        get: function () {
            return this._guardianForms;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RegistrationPage.prototype, "emergencyContactForm", {
        get: function () {
            return this._emergencyContactForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RegistrationPage.prototype, "insuranceForm", {
        get: function () {
            return this._insuranceForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RegistrationPage.prototype, "healthCareProviderForm", {
        get: function () {
            return this._healthCareProviderForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RegistrationPage.prototype, "isValid", {
        get: function () {
            return this.isFormValid();
        },
        enumerable: false,
        configurable: true
    });
    RegistrationPage.prototype.isFormValid = function () {
        var allFormsValid;
        allFormsValid = true;
        for (var _i = 0, _a = this._atheleteForms; _i < _a.length; _i++) {
            var form = _a[_i];
            allFormsValid = allFormsValid && form.isValid();
        }
        for (var _b = 0, _c = this._guardianForms; _b < _c.length; _b++) {
            var form = _c[_b];
            allFormsValid = allFormsValid && form.isValid();
        }
        allFormsValid = allFormsValid && this._emergencyContactForm.isValid();
        allFormsValid = allFormsValid && this._insuranceForm.isValid();
        allFormsValid = allFormsValid && this._healthCareProviderForm.isValid();
        return allFormsValid;
    };
    RegistrationPage.prototype.buildForm = function () {
        this._atheleteForms = [this.buildAthleteForm()];
        this._guardianForms = [this.buildGuardianForm()];
        this._emergencyContactForm = this.buildEmergencyContactForm();
        this._insuranceForm = this.buildInsuranceForm();
        this._healthCareProviderForm = this.buildHealthCareProviderForm();
    };
    RegistrationPage.prototype.buildEmergencyContactForm = function () {
        var fields = [
            new components_1.TextField('emergencyContactName', 'Emergency Contact Name', true, 100, 'Name is Required'),
            new components_1.TextField('emergencyContactRelationship', 'Relationship', true, 50, 'Relationship is Required'),
            new components_1.PhoneField('emergencyContactCellPhone', 'Cell Phone', true, 'Cell number is required'),
        ];
        return new components_1.Form(fields);
    };
    RegistrationPage.prototype.buildInsuranceForm = function () {
        var fields = [
            new components_1.TextField('insuranceCompany', 'Insurance Company', true, 500, 'Insurance company is Required'),
            new components_1.TextField('insurancePolicyHolder', 'Policy Holder\'s Name', true, 200, 'Policy holder is required'),
            new components_1.PhoneField('insurancePhone', 'Phone Number', true, 'Phone number is Required'),
            new components_1.TextField('insuranceGroupNumber', 'Group Number', true, 200, 'Group number is Required'),
            new components_1.TextField('insurancePolicyNumber', 'Policy Number', true, 200, 'Policy number is Required'),
        ];
        return new components_1.Form(fields);
    };
    RegistrationPage.prototype.buildHealthCareProviderForm = function () {
        var fields = [
            new components_1.TextField('healthCareProviderName', 'Primary Care Provider\'s Name', true, 500, 'Primary care provider is Required'),
            new components_1.PhoneField('healthCareProviderPhone', 'Phone Number', true, 'Phone is Required'),
            new components_1.TextField('preferredHospital', 'Preferred Hospital', true, 200, 'Preferred hospital is required'),
        ];
        return new components_1.Form(fields);
    };
    RegistrationPage.prototype.buildGuardianForm = function () {
        var fields = [
            new components_1.TextField('fullName', 'Full Name', true, 500, 'Name is Required'),
            new components_1.PhoneField('cellPhone', 'Cell Phone', true, 'Phone number is Required'),
            new components_1.EmailField('email', true),
        ];
        return new components_1.Form(fields);
    };
    RegistrationPage.prototype.buildAthleteForm = function () {
        var fields = [
            new components_1.TextField('firstName', 'First Name', true, 200, 'First Name is Required'),
            new components_1.TextField('middleName', 'Middle Name', false, 200),
            new components_1.TextField('lastName', 'Last Name', true, 200, 'Last Name is Required'),
            new components_1.TextField('address', 'Address', true, 200, 'Address is Required'),
            new components_1.TextField('aptNumber', 'Apt Number', false, 200),
            new components_1.TextField('city', 'City', true, 200, 'City is Required'),
            new components_1.StateField('state', true),
            new components_1.TextField('zip', 'Zip Code', true, 200, 'Zip is Required', '64850'),
            new components_1.GenderField('gender', true),
            new components_1.TextField('school', 'Which school does this wrestler attend?', true, 200, 'School is required'),
            new components_1.SchoolGradeField('grade', true),
            new components_1.TextField('allergies', 'List any allergies', false, 500),
            new components_1.TextField('medicalConditions', 'List any medical conditions', false, 500),
            new components_1.DateField('dateOfBirth', 'Date of Birth', true, 'Date of birth is required'),
            new components_1.ShirtSizeField('shirtSize', true),
        ];
        return new components_1.Form(fields);
    };
    return RegistrationPage;
}(__1.BasePage));
exports.default = RegistrationPage;
