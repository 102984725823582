"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var __2 = require("../..");
var endpointUrls_1 = require("../../api/endpointUrls");
var app_1 = require("../../app");
var components_1 = require("../../components");
var observationProvider_1 = require("../../utils/observationProvider");
var tournamentSignUpPageActions_1 = require("./tournamentSignUpPageActions");
var tournamentSignUpPageState_1 = require("./tournamentSignUpPageState");
var TournementSignUpPage = /** @class */ (function (_super) {
    __extends(TournementSignUpPage, _super);
    function TournementSignUpPage(api, navigator, currentParentAccountManager, tournamentId) {
        var _this = _super.call(this, __1.PageTypes.TournamentSignUp, 'Tournament') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentParentAccountManager = currentParentAccountManager;
        _this.tournamentId = tournamentId;
        _this.loadAccountFormSubmitted = function () {
            _this.currentParentAccountManager.loadAccountToken(_this._loadAccountForm.model.accountCode);
        };
        _this.backToSignUpForm = function () {
            _this.setPageState(tournamentSignUpPageState_1.default.SignUpForm);
        };
        _this.forgetAccessCode = function () {
            _this.currentParentAccountManager.clearAccountToken();
        };
        _this.parentAccountLoaded = function () {
            _this.loadTournament(_this.tournamentId);
        };
        _this.setPageState = function (pageState) {
            _this._pageState = pageState;
            _this.observationProvider.notifyObservers(tournamentSignUpPageActions_1.default.StateChanged);
        };
        _this.loadTournament = function (tournamentId) {
            _this.observationProvider.notifyObservers(tournamentSignUpPageActions_1.default.TournamentLoading);
            _this.api.get(endpointUrls_1.default.Tournaments + "/GetForSignUp/" + tournamentId)
                .then(function (tournament) {
                _this._tournament = tournament;
                if (!tournament.signUpIsExpired && !tournament.signUpNotYetActive) {
                    _this.initSignUpForm();
                }
                else {
                    _this.setPageState(tournamentSignUpPageState_1.default.SignUpInactive);
                }
                _this.observationProvider.notifyObservers(tournamentSignUpPageActions_1.default.TournamentLoaded);
            });
        };
        // this fires after state is changed
        _this.initSignUpForm = function () {
            _this._signUpForm = new components_1.TournamentSignUpAthleteFormCollection(_this.tournament, _this.currentParentAccountManager);
            _this._signUpForm.observationProvider.observe(_this.signUpFormSubmitted, components_1.FormActions.FormSubmitted);
            _this.setPageState(tournamentSignUpPageState_1.default.SignUpForm);
        };
        _this.signUpFormSubmitted = function (requestDto) {
            _this.observationProvider.notifyObservers(tournamentSignUpPageActions_1.default.SignUpSubmitting);
            requestDto.registrationId = _this.currentParentAccountManager.parentAccount.registrationId;
            _this.api.post(endpointUrls_1.default.Tournaments + "/signup", requestDto)
                .then(function (insertedRequestDto) {
                _this._tournamentSignUpDto = insertedRequestDto;
                _this.observationProvider.notifyObservers(tournamentSignUpPageActions_1.default.SignUpSubmitted);
                if (_this._tournamentSignUpDto.totalAmountDue === 0) {
                    _this.navigator.goToTournamentSignUpSuccess();
                }
                else {
                    _this.setPageState(tournamentSignUpPageState_1.default.Pay);
                }
            })
                .catch(function () {
                _this.observationProvider.notifyObservers(tournamentSignUpPageActions_1.default.SignUpSubmissionFailed);
            });
        };
        _this.goToHome = function () {
            _this.navigator.goToHome();
        };
        _this.payFromAccount = function () {
            _this.observationProvider.notifyObservers(tournamentSignUpPageActions_1.default.PayingFromAccount);
            _this.api.post(endpointUrls_1.default.Tournaments + "/pay-from-account/" + _this.tournamentSignUpDto.id, {})
                .then(function () {
                _this.observationProvider.notifyObservers(tournamentSignUpPageActions_1.default.PaidFromAccount);
                _this.navigator.goToTournamentSignUpSuccess();
            })
                .catch(function () {
                _this.observationProvider.notifyObservers(tournamentSignUpPageActions_1.default.PayingFromAccountFailed);
            });
        };
        _this.showForgetAccessCodeConfirmationPrompt = function () {
            _this.setPageState(tournamentSignUpPageState_1.default.ForgetCodeConfirmationPrompt);
        };
        _this.observationProvider = new observationProvider_1.default();
        _this.initialize();
        return _this;
    }
    TournementSignUpPage.prototype.initialize = function () {
        var _this = this;
        this._loadAccountForm = new __2.Form([
            new components_1.PhoneField('accountCode', 'Primary Phone Number', true)
        ]);
        this._loadAccountForm.observationProvider.observe(this.loadAccountFormSubmitted, components_1.FormActions.FormSubmitted);
        this.currentParentAccountManager.observationProvider.observe(this.parentAccountLoaded, app_1.CurrentParentAccountManagerActions.ParentAccountLoaded);
        this.currentParentAccountManager.observationProvider.observe(function () { _this.setPageState(tournamentSignUpPageState_1.default.LoadParentAccount); }, app_1.CurrentParentAccountManagerActions.ParentAccountCleared);
        if (this.currentParentAccountManager.parentAccount === null) {
            this.setPageState(tournamentSignUpPageState_1.default.LoadParentAccount);
        }
        else {
            this.parentAccountLoaded();
        }
    };
    Object.defineProperty(TournementSignUpPage.prototype, "tournamentSignUpDto", {
        get: function () {
            return this._tournamentSignUpDto;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournementSignUpPage.prototype, "pageState", {
        get: function () {
            return this._pageState;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournementSignUpPage.prototype, "loadAccountForm", {
        get: function () {
            return this._loadAccountForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournementSignUpPage.prototype, "tournament", {
        get: function () {
            return this._tournament;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournementSignUpPage.prototype, "signUpForm", {
        get: function () {
            return this._signUpForm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournementSignUpPage.prototype, "allowPaymentFromAccount", {
        get: function () {
            if (!this._tournamentSignUpDto || !this._tournamentSignUpDto.totalAmountDue) {
                return false;
            }
            if (this._tournamentSignUpDto.totalAmountDue > this.currentParentAccountManager.parentAccount.balance) {
                return false;
            }
            return true;
        },
        enumerable: false,
        configurable: true
    });
    return TournementSignUpPage;
}(__1.BasePage));
exports.default = TournementSignUpPage;
