import * as React from 'react';

interface ButtonProps {
    className: string;
    type?: 'button' | 'submit' | 'reset';
    leftIcon?: string;
    rightIcon?: string;
    onClick: () => void;
    processing?: boolean;
    disabled?: boolean;
    text?: string;
    isLoading?: boolean;
}

class Button extends React.PureComponent<ButtonProps> {

    public handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const { onClick } = this.props;

        e.preventDefault();

        if (typeof onClick === 'function'){
            onClick();
        }
    }

    public render(): JSX.Element {
        const { className, type, leftIcon, rightIcon, children, processing, disabled, text, isLoading } = this.props;

        return (
            <React.Fragment>
                { isLoading && <div className="loading-placeholder shimmer button"></div> }

                { !isLoading && (
                    <button
                        className={className}
                        type={type}
                        onClick={this.handleClick}
                        disabled={processing || disabled}>

                        { processing && 'Working...' }

                        {!processing && leftIcon && <i className={'fa ' + leftIcon}></i>}

                        {!processing && <span>{text}</span>}

                        {!processing && children}

                        {!processing && rightIcon && <i className={'fa ' + rightIcon}></i>}
                    </button>
                )}
            </React.Fragment>
        );
    }
}

export default Button;
