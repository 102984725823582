"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var stringDropdownField_1 = require("./stringDropdownField");
var SchoolGradeField = /** @class */ (function (_super) {
    __extends(SchoolGradeField, _super);
    function SchoolGradeField(key, isRequired, initialValue) {
        var _this = _super.call(this, key, 'School Grade', isRequired, [
            new Option('Pre-K', 'Pre-K'),
            new Option('Kindergarten', 'Kindergarten'),
            new Option('1st', '1st'),
            new Option('2nd', '2nd'),
            new Option('3rd', '3rd'),
            new Option('4th', '4th'),
            new Option('5th', '5th'),
            new Option('6th', '6th'),
            new Option('7th', '7th'),
            new Option('8th', '8th'),
            new Option('Freshman', 'Freshman'),
            new Option('Sophmore', 'Sophmore'),
            new Option('Junior', 'Junior'),
            new Option('Senior', 'Senior')
        ], 'Grade is required', initialValue, _1.FieldType.DropDown) || this;
        _this.key = key;
        return _this;
    }
    return SchoolGradeField;
}(stringDropdownField_1.default));
exports.default = SchoolGradeField;
