"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../utils");
var _1 = require("./");
var fieldActions_1 = require("./fieldActions");
var tournamentSignUpAthleteFormActions_1 = require("./tournamentSignUpAthleteFormActions");
var TournamentSignUpAthleteForm = /** @class */ (function (_super) {
    __extends(TournamentSignUpAthleteForm, _super);
    function TournamentSignUpAthleteForm(athleteId, tournamentDivisions) {
        var _this = _super.call(this) || this;
        _this.athleteId = athleteId;
        _this.tournamentDivisions = tournamentDivisions;
        _this.setActiveStatus = function () {
            _this.divisionField.setVisibility(_this.activeField.value);
            _this.weightClassField.setVisibility(_this.activeField.value);
            _this.observationProvider.notifyObservers(tournamentSignUpAthleteFormActions_1.default.ActiveStatusChanged);
        };
        _this.divisionSelected = function () {
            var division;
            for (var _i = 0, _a = _this.tournamentDivisions; _i < _a.length; _i++) {
                var currentDivision = _a[_i];
                if (currentDivision.id === _this._divisionField.value) {
                    division = currentDivision;
                }
            }
            if (division) {
                _this._weightClassField.setDivision(division);
                _this.observationProvider.notifyObservers(tournamentSignUpAthleteFormActions_1.default.DivisionSelected);
            }
        };
        _this.buildSignUpFormFields = function () {
            var fields = [];
            _this._activeField = new _1.CheckBoxField('isActive', 'Sign-Up', false, null, null, true);
            _this._activeField.observationProvider.observe(_this.setActiveStatus, fieldActions_1.default.ValueChanged);
            _this._divisionField = new _1.NumberDropdownField('tournamentDivisionId', 'Division', true, _this.buildDivisionOptions());
            _this._divisionField.observationProvider.observe(_this.divisionSelected, fieldActions_1.default.ValueChanged);
            _this._weightClassField = new _1.WeightClassField('weightClass', true, null);
            fields.push(_this._activeField);
            fields.push(_this._divisionField);
            fields.push(_this._weightClassField);
            _this.setFields(fields);
        };
        _this.buildDivisionOptions = function () {
            var options = [];
            for (var _i = 0, _a = _this.tournamentDivisions; _i < _a.length; _i++) {
                var division = _a[_i];
                options.push(new _1.FieldOption(division.name, division.id));
            }
            return options;
        };
        _this.buildSignUpFormFields();
        _this.setModel({ athleteId: _this.athleteId });
        _this.observationProvider = new utils_1.ObservationProvider();
        return _this;
    }
    Object.defineProperty(TournamentSignUpAthleteForm.prototype, "activeField", {
        get: function () {
            return this._activeField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournamentSignUpAthleteForm.prototype, "divisionField", {
        get: function () {
            return this._divisionField;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournamentSignUpAthleteForm.prototype, "weightClassField", {
        get: function () {
            return this._weightClassField;
        },
        enumerable: false,
        configurable: true
    });
    return TournamentSignUpAthleteForm;
}(_1.Form));
exports.default = TournamentSignUpAthleteForm;
