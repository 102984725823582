import * as React from 'react';

class RegistrationSuccess extends React.Component {
    public render(): JSX.Element {
        return (
            <div className="container top-p-2">
                <div className="text-center content-header-block">
                    <i className="fa fa-check green"></i>
                    <h3>Done for now!</h3>
                </div>

                <p>
                    Thanks for signing up with Neosho Youth Wrestling. We are excited to kick-off another great season.
                    We have made several improvements to our wrestling club that we think will help make this year great!
                </p>

                <p>
                    <h4>Important Dates</h4>

                    <hr/>

                    <b>Parent Meeting November 2, 2023 6:00pm</b>

                    <br/>
                    Carver Elementary School

                    <br/>
                    12350 Norway Rd

                    <br/>
                    Neosho MO, 64850

                    <br/>
                    <br/>
                    <b>First Practice </b>

                    <br/>
                    November 13, 2023

                    <br/>
                    <br/>
                    <b>Pictures</b>
                    <br/>
                    December 11, 2023
                    <br/>
                    Details at parent meeting

                    <br/>
                    <br/>
                    <b>Practice schedule</b>
                    <br/>
                    <b>Novice</b>
                    <br/>
                    Tuesday 5:15 to 6:15pm
                    <br/>
                    Thursday 5:15 to 6:15pm

                    <br/>
                    <br/>
                    <b>Intermediate</b>
                    <br/>
                    Monday 5:15 to 6:30pm
                    <br/>
                    Tuesday 6:15 to 7:30pm
                    <br/>
                    Thursday 6:15 to 7:30pm

                    <br/>
                    <br/>
                    <b>Advanced</b>
                    <br/>
                    Monday 5:15 to 6:30pm
                    <br/>
                    Tuesday 6:15 to 7:30pm
                    <br/>
                    Thursday 6:15 to 7:30pm

                    <br/>
                    <br/>
                    <b>Girls</b>
                    <br/>
                    All Girls Practice on Wednesday, Time to be determined
                </p>
            </div>
        );
    }
}

export default RegistrationSuccess;
