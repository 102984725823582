import { RegistrationActions, RegistrationPage } from 'core.frontend';
import * as React from 'react';
import Field from '../../misc/components/fields/Field';
import ObservingComponent from '../../misc/components/ObservingComponent';

interface RegistrationInsuranceFormContainerProps {
    page: RegistrationPage;
}

class RegistrationInsuranceFormContainer extends ObservingComponent<RegistrationInsuranceFormContainerProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, RegistrationActions.SubmissionFailure);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <div className="form-container">
                <h4>Insurance Information</h4>

                <p>Please list insurance coverage for your wrestlers. We never share, publish or distribute this information.</p>

                <div className="row">
                    {page.insuranceForm.fields.map((field) => (
                        <div className="col-12 col-sm-4">
                            <Field field={field} />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default RegistrationInsuranceFormContainer;
