import { SubmittableFormActions, TournamentDetailPage, TournamentDetailPageActions } from 'core.frontend';
import * as React from 'react';
import AnchorButton from '../../misc/components/AnchorButton';
import Button from '../../misc/components/Button';
import Field from '../../misc/components/fields/Field';
import ObservingComponent from '../../misc/components/ObservingComponent';
import EmptyState from '../../misc/components/EmptyState';

interface TournamentDetailProps {
    page: TournamentDetailPage;
}

class TournamentDetailForm extends ObservingComponent<TournamentDetailProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, [TournamentDetailPageActions.RecordLoaded, TournamentDetailPageActions.DivisionAdded, TournamentDetailPageActions.DivisionRemoved, TournamentDetailPageActions.CancelRemoveDivision]);
        this.registerUpdateObserver(page.form.observationProvider, SubmittableFormActions.SubmissionFailure);
    }

    private removeDivisionClicked = (index: number) => () => {
        this.props.page.removeDivision(index);
    }

    private cancelRemoveDivision = (index: number) => () => {
        this.props.page.cancelRemoveDivision(index);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <form noValidate>

                <div className="field-container">
                    {page.form.fields.map((field) =>
                        <Field
                            field={field}
                            isLoading={page.isLoading} />
                    )}
                </div>

                <div className="text-right">
                    <Button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={page.addDivision}
                        text="Add Division"
                        isLoading={page.isLoading} />
                </div>

                {!page.divisionForms.length &&
                    <EmptyState
                        title="No Divisions Set"
                        message="This tournament has no divisions defined" />
                }

                {!!page.divisionForms.length &&
                    <ul className="data-list">
                        {page.divisionForms.map((divisionForm, index) =>
                            <li
                                key={index}
                                className={
                                'flex ' +
                                (divisionForm.model.isRemoved ? 'deleting' : '')}>

                                <Field
                                    className="fill-7 mt-p5"
                                    field={divisionForm.fields[0]} />

                                <Field
                                    className="fill-2 mt-p5"
                                    field={divisionForm.fields[1]} />

                                {!divisionForm.model.isRemoved &&
                                    <AnchorButton
                                        leftIcon="fa-trash"
                                        className="btn-icon"
                                        onClick={this.removeDivisionClicked(index)} />
                                }

                                {divisionForm.model.isRemoved &&
                                    <AnchorButton
                                        className="btn-icon"
                                        leftIcon="fa-plus"
                                        onClick={this.cancelRemoveDivision(index)} />
                                }

                                <div className="flex-break">
                                    <Field
                                        className="fill"
                                        field={divisionForm.fields[2]} />
                                </div>
                            </li>
                        )}
                    </ul>
                }

                <footer>
                    {page.isDeletable && !page.isLoading && (
                        <AnchorButton
                            className="button"
                            onClick={page.delete}
                            leftIcon="fa-trash" />
                    )}

                    <div>
                        <Button
                            type="button"
                            className="btn btn-secondary"
                            onClick={page.cancel}
                            text="Cancel"
                            isLoading={page.isLoading} />

                        <Button
                            type="submit"
                            className="btn btn-primary"
                            onClick={page.save}
                            text="Save"
                            isLoading={page.isLoading} />
                    </div>
                </footer>

            </form>
        );
    }
}

export default TournamentDetailForm;
