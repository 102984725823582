"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../..");
var endpointUrls_1 = require("../../../api/endpointUrls");
var balanceAdjustmentDto_1 = require("../../../api/registration/balanceAdjustmentDto");
var observationProvider_1 = require("../../../utils/observationProvider");
var parentAccountBalanceAdjustmentActions_1 = require("./parentAccountBalanceAdjustmentActions");
var ParentAccountBalanceAdjustmentType_1 = require("./ParentAccountBalanceAdjustmentType");
var ParentAccountBalanceAdjustmentForm = /** @class */ (function () {
    function ParentAccountBalanceAdjustmentForm(api, _currentBalance, registrationId) {
        var _this = this;
        this.api = api;
        this._currentBalance = _currentBalance;
        this.registrationId = registrationId;
        this.show = function () {
            _this._visible = true;
            _this.init();
            _this.observationProvider.notifyObservers(parentAccountBalanceAdjustmentActions_1.default.Shown);
        };
        this.hide = function () {
            _this._visible = false;
            _this.observationProvider.notifyObservers(parentAccountBalanceAdjustmentActions_1.default.Hidden);
        };
        this.setAdjustmentType = function (adjustmentType) {
            _this.adjustmentType = adjustmentType;
            _this.setRequiredFieldStatuses();
            _this.setVisibility();
            _this.observationProvider.notifyObservers(parentAccountBalanceAdjustmentActions_1.default.AdjustmentTypeChanged, adjustmentType);
        };
        this.setRequiredFieldStatuses = function () {
            switch (_this.adjustmentType) {
                case ParentAccountBalanceAdjustmentType_1.default.SetBalance: {
                    _this.newBalanceField.setRequiredStatus(true);
                    _this.creditField.setRequiredStatus(false);
                    _this.debitField.setRequiredStatus(false);
                    break;
                }
                case ParentAccountBalanceAdjustmentType_1.default.Credit: {
                    _this.newBalanceField.setRequiredStatus(false);
                    _this.creditField.setRequiredStatus(true);
                    _this.debitField.setRequiredStatus(false);
                    break;
                }
                case ParentAccountBalanceAdjustmentType_1.default.Debit: {
                    _this.newBalanceField.setRequiredStatus(false);
                    _this.creditField.setRequiredStatus(false);
                    _this.debitField.setRequiredStatus(true);
                    break;
                }
            }
        };
        this.setVisibility = function () {
            switch (_this.adjustmentType) {
                case ParentAccountBalanceAdjustmentType_1.default.SetBalance: {
                    _this.newBalanceField.setVisibility(true);
                    _this.creditField.setVisibility(false);
                    _this.debitField.setVisibility(false);
                    break;
                }
                case ParentAccountBalanceAdjustmentType_1.default.Credit: {
                    _this.newBalanceField.setVisibility(false);
                    _this.creditField.setVisibility(true);
                    _this.debitField.setVisibility(false);
                    break;
                }
                case ParentAccountBalanceAdjustmentType_1.default.Debit: {
                    _this.newBalanceField.setVisibility(false);
                    _this.creditField.setVisibility(false);
                    _this.debitField.setVisibility(true);
                    break;
                }
            }
        };
        this.save = function () {
            _this.form.submit();
        };
        this.formSubmitted = function () {
            var newBalance = _this.getNewBalance();
            _this.observationProvider.notifyObservers(parentAccountBalanceAdjustmentActions_1.default.Saving, newBalance);
            _this._currentBalance = newBalance;
            _this._isSaving = true;
            var promise;
            switch (_this.adjustmentType) {
                case ParentAccountBalanceAdjustmentType_1.default.SetBalance: {
                    promise = _this.api.post(endpointUrls_1.default.Registrations + "/" + _this.registrationId + "/balance/set", _this.getAdjustmentDto());
                    break;
                }
                case ParentAccountBalanceAdjustmentType_1.default.Credit: {
                    promise = _this.api.post(endpointUrls_1.default.Registrations + "/" + _this.registrationId + "/balance/credit", _this.getAdjustmentDto());
                    break;
                }
                case ParentAccountBalanceAdjustmentType_1.default.Debit: {
                    promise = _this.api.post(endpointUrls_1.default.Registrations + "/" + _this.registrationId + "/balance/debit", _this.getAdjustmentDto());
                    break;
                }
            }
            promise.then(function (ledgerEntryDto) {
                _this._isSaving = false;
                _this.observationProvider.notifyObservers(parentAccountBalanceAdjustmentActions_1.default.Saved, ledgerEntryDto);
            }).catch(function () {
                _this._isSaving = false;
                _this.observationProvider.notifyObservers(parentAccountBalanceAdjustmentActions_1.default.Error);
            });
            _this.hide();
        };
        this.getNewBalance = function () {
            switch (_this.adjustmentType) {
                case ParentAccountBalanceAdjustmentType_1.default.SetBalance: {
                    return _this.newBalanceField.value;
                }
                case ParentAccountBalanceAdjustmentType_1.default.Credit: {
                    return _this._currentBalance + _this.creditField.value;
                }
                case ParentAccountBalanceAdjustmentType_1.default.Debit: {
                    return _this._currentBalance - _this.debitField.value;
                }
            }
        };
        this.getAdjustmentDto = function () {
            var dto = new balanceAdjustmentDto_1.default();
            dto.memo = _this.memoField.value;
            switch (_this.adjustmentType) {
                case ParentAccountBalanceAdjustmentType_1.default.SetBalance: {
                    dto.amount = _this.newBalanceField.value;
                    break;
                }
                case ParentAccountBalanceAdjustmentType_1.default.Credit: {
                    dto.amount = _this.creditField.value;
                    break;
                }
                case ParentAccountBalanceAdjustmentType_1.default.Debit: {
                    dto.amount = _this.debitField.value;
                    break;
                }
            }
            return dto;
        };
        this.observationProvider = new observationProvider_1.default();
    }
    ParentAccountBalanceAdjustmentForm.prototype.init = function () {
        var _this = this;
        this.adjustmentType = null;
        this.newBalanceField = new __1.MoneyField('newBalance', 'New Balance', false);
        this.creditField = new __1.MoneyField('creditAmount', 'Credit Amount', false);
        this.debitField = new __1.MoneyField('debitAmount', 'Debit Amount', false);
        this.memoField = new __1.TextField('memo', 'Memo', true, 250);
        this.form = new __1.Form([
            this.memoField,
            this.newBalanceField,
            this.creditField,
            this.debitField
        ]);
        this.form.observationProvider.observe(function () { _this.observationProvider.notifyObservers(parentAccountBalanceAdjustmentActions_1.default.FormInvalid); }, __1.FormActions.FormSubmissionFailure);
        this.form.observationProvider.observe(this.formSubmitted, __1.FormActions.FormSubmitted);
    };
    Object.defineProperty(ParentAccountBalanceAdjustmentForm.prototype, "isSaving", {
        get: function () {
            return this._isSaving;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ParentAccountBalanceAdjustmentForm.prototype, "visible", {
        get: function () {
            return this._visible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ParentAccountBalanceAdjustmentForm.prototype, "currentBalance", {
        get: function () {
            return this._currentBalance;
        },
        enumerable: false,
        configurable: true
    });
    return ParentAccountBalanceAdjustmentForm;
}());
exports.default = ParentAccountBalanceAdjustmentForm;
