"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var app_1 = require("../app");
var parentAccountDetailPage_1 = require("./parentAccount/parentAccountDetailPage");
var parentAccountsPage_1 = require("./parentAccount/parentAccountsPage");
var registrationPage_1 = require("./registration/registrationPage");
var registrationSuccess_1 = require("./registration/registrationSuccess");
var tournamentDetailPage_1 = require("./tournaments/tournamentDetailPage");
var tournamentSignUpSuccessPage_1 = require("./tournaments/tournamentSignUpSuccessPage");
var PageFactory = /** @class */ (function () {
    function PageFactory(api, currentUserManager, currentParentAccountManager) {
        var _this = this;
        this.api = api;
        this.currentUserManager = currentUserManager;
        this.currentParentAccountManager = currentParentAccountManager;
        this.clearCachedPageInstances = function () {
            _this.usersPage = null;
            _this.parentAccountsPage = null;
            _this.tournamentsPage = null;
        };
        this.currentUserManager.observationProvider.observe(this.clearCachedPageInstances, app_1.CurrentUserManagerActions.LoggedOut);
    }
    PageFactory.prototype.createPage = function (pageType, navigator, id) {
        switch (pageType) {
            case _1.PageTypes.Users: {
                return this.createUsersPage(navigator);
            }
            case _1.PageTypes.UserDetail: {
                return this.createUserDetailPage(navigator, id);
            }
            case _1.PageTypes.Registration: {
                return this.createRegistrationPage(navigator);
            }
            case _1.PageTypes.RegistrationSuccess: {
                return this.createRegistrationSuccessPage(navigator);
            }
            case _1.PageTypes.Login: {
                return this.createLoginPage(navigator);
            }
            case _1.PageTypes.ParentAccounts: {
                return this.createParentAccountsPage(navigator);
            }
            case _1.PageTypes.ParentAccountDetail: {
                return this.createParentAccountDetailPage(navigator, id);
            }
            case _1.PageTypes.TournamentSignUp: {
                return this.createTournamentSignUpPage(navigator, id);
            }
            case _1.PageTypes.TournamentSignUpSuccess: {
                return this.createTournamentSignUpSuccess(navigator);
            }
            case _1.PageTypes.Tournaments: {
                return this.createTournamentsPage(navigator);
            }
            case _1.PageTypes.TournamentDetail: {
                return this.createTournamentDetail(navigator, id);
            }
            default: {
                return this.createHomePage(navigator);
            }
        }
    };
    PageFactory.prototype.createUsersPage = function (navigator) {
        if (!this.usersPage) {
            this.usersPage = new _1.UsersPage(this.api, navigator);
        }
        return this.usersPage;
    };
    PageFactory.prototype.createParentAccountsPage = function (navigator) {
        if (!this.parentAccountsPage) {
            this.parentAccountsPage = new parentAccountsPage_1.default(this.api, navigator);
        }
        return this.parentAccountsPage;
    };
    PageFactory.prototype.createParentAccountDetailPage = function (navigator, id) {
        return new parentAccountDetailPage_1.default(this.api, navigator, id);
    };
    PageFactory.prototype.createUserDetailPage = function (navigator, id) {
        return new _1.UserDetailPage(this.api, navigator, id);
    };
    PageFactory.prototype.createRegistrationPage = function (navigator) {
        return new registrationPage_1.default(this.api, navigator);
    };
    PageFactory.prototype.createHomePage = function (navigator) {
        return new _1.HomePage(this.api, navigator);
    };
    PageFactory.prototype.createRegistrationSuccessPage = function (navigator) {
        return new registrationSuccess_1.default(this.api, navigator);
    };
    PageFactory.prototype.createLoginPage = function (navigator) {
        return new _1.LoginPage(this.api, navigator, this.currentUserManager);
    };
    PageFactory.prototype.createTournamentSignUpPage = function (navigator, tournamentId) {
        return new _1.TournamentSignUpPage(this.api, navigator, this.currentParentAccountManager, tournamentId);
    };
    PageFactory.prototype.createTournamentSignUpSuccess = function (navigator) {
        return new tournamentSignUpSuccessPage_1.default(this.api, navigator);
    };
    PageFactory.prototype.createTournamentsPage = function (navigator) {
        if (!this.tournamentsPage) {
            this.tournamentsPage = new _1.TournamentsPage(this.api, navigator);
        }
        return this.tournamentsPage;
    };
    PageFactory.prototype.createTournamentDetail = function (navigator, tournamentId) {
        return new tournamentDetailPage_1.default(this.api, navigator, tournamentId);
    };
    return PageFactory;
}());
exports.default = PageFactory;
