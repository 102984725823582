import classNames = require('classnames');
import { HomePage } from 'core.frontend';
import * as React from 'react';
import Button from '../../misc/components/Button';
import SectionHeading from './SectionHeading';
import TournamentSchedule from '../../misc/components/tournaments/TournamentSchedule';

interface HomeProps{
    page: HomePage;
}

class Home extends React.Component<HomeProps> {

    private goToRegistration = (): void => {
        const { page } = this.props;

        page.goToRegistration();
    }

    private goToTop(event: any) {
        window.scrollTo(0,0);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <React.Fragment>
                <div className="home-hero">
                    <div className="container cta-container">
                        <h2>
                            <span className="small">We are excited for the upcoming 2023-2024 season. Our season is underway and registration is now closed!</span>
{/*
                            <br/>
                            <br/>
                            <span className="small">Registration is now open!</span> */}

                            {/* <p className="text-center">
                                <Button
                                    className="btn btn-primary"
                                    onClick={this.goToRegistration}
                                    text="Start Registration" />
                            </p> */}
                        </h2>
                    </div>
                </div>

                <div className="container">
                    <div className="text-center">
                        <p>
                            <b>Please read the following important information</b>
                        </p>

                        <div className="mt-1 page-nav">
                            <a href="#tournament_schedule">Tournament Schedule</a>
                            <a href="#banner_tournament_sponsor_sales">Banner and Tournament Sponshors Sales</a>
                            <a href="#about_neosho_wrestling_program">About Neosho Wrestling Program</a>
                            <a href="#who_can_join">Who can join?</a>
                            <a href="#important_dates">Important Dates</a>
                            <a href="#practice_schedule">Practice Schedule</a>
                            <a href="#coaching_staff">Coaching Staff</a>
                            <a href="#club_fees">Club Fees</a>
                            <a href="#fundraising">Fundraising</a>
                            <a href="#scholarships">Scholarships</a>
                            <a href="#volunteer_coaches">Volunteer Coaches</a>
                            <a></a>
                        </div>
                    </div>
                </div>

                <div
                    className="page_link_locator"
                    id="tournament_schedule">
                </div>

                <div className="container">

                    <SectionHeading>Tournament Schedule</SectionHeading>

                    <p>
                        We are finializing our season schedule now.
                        Once the scheule is set, you'll be able to access it here
                        and sign up for weekly tournaments all online.
                    </p>

                    <TournamentSchedule tournamentSchedule={page.tournamentSchedule} />
                </div>

                <div
                    className="page_link_locator"
                    id="banner_tournament_sponsor_sales">
                </div>

                <div className="container">

                    <SectionHeading>Banner Add Sale</SectionHeading>

                    <p>We will be displaying our team banner at all tournaments that our club attends. Earn credit toward tournament dues, gear and other wrestling related expenses by recruiting sponsors for our banner. Use the form below to enroll a sponsor for our banner and earn credit toward your account.</p>

                    <div className="text-center"><a href="pdf/Neosho Youth Wrestling Banner.pdf" target="_blank">Click here to download the banner sponsorship form</a></div>
                </div>

                <div className="container">
                    <h2 className="mt-3">Neosho Tournament Sponsors</h2>

                    <p>
                        We need help with our home tournament to offset the cost of the awards. There are three levels of sponsorship.
                        <br/>
                        More information at parent meeting
                    </p>

                    <div className="text-center">
                        <a
                            href="pdf/Neosho Youth Wrestling Tournament Sponsor.pdf"
                            target="_blank">
                                Click here to download the tournament sponsorship form
                        </a>
                    </div>
                </div>

                <div className="container">
                    <hr className="mt-1"/>

                    <div
                        className="page_link_locator"
                        id="about_neosho_wrestling_program">
                    </div>

                    <SectionHeading>Neosho Youth Wrestling 2023-2024</SectionHeading>

                    <div className="flex">
                        <img
                            className="info-image"
                            src="img\2023_Wrestling_Info_Image.png" />
                    </div>

                    <div className="text-center">
                        <img
                            className="mw-100"
                            src="img\Neosho_Wrestling_Heading.png" />
                    </div>
                </div>

                <div className="container">
                    <br/>
                    <b>
                        <u>One Program - Four Levels</u> ALL for ONE, ONE for ALL (Youth, Junior High, High School, Callegiate)
                    </b>

                    "Building Champions on &amp; off the mat."
                    &nbsp;&nbsp;
                    High School State Champions 2010, 11, 13, 14, 15, 16, 17, 19, 20

                    <br/>
                    <br/>

                    <b>
                        <u>Philosophy of Neosho Wrestling Program</u>
                    </b>
                    <br/>

                    <b>Mission Statement: </b> "The Mission of Neosho Wrestling is to build CHAMPIONS on and off the mat." Champions are defined by our character, not particularly gold medals.  <b>Our vision</b> for the program is to teach each wrestler a solid foundation of fundamentals in all three aspects of training (technical, physical, &amp; mental) while emphasizing understanding and development of our core values (faith, team/FAMILY, work, persistence, loyalty, learning from loss, &amp; humility).  With the help of parents and wrestlers trusting in this process we will accomplish our mission and vision.  With mutual respect and a commitment from all those involved we can and will be prepared for overcoming challenges, accomplishing goals, and finding success in all pursuits throughout the journey of life.

                    <br/>
                    <br/>

                    <b>
                        The System and Coaching Staff
                    </b>
                    <br/>
                    My Belief - you get out of something truly what you put into it.  You harvest what you plant and WE will reap what WE sow.  Everything has a price, even success and it must be paid daily.  A critical key for me is to sell "Our System '' in a way that has all wrestlers and parents believe in the process, buy-in, and invest with their time/efforts.  It is crucial to our program’s success that everyone is committed and works together.  The parental support is a vital component for our wrestlers and OUR program.

                    <br/>
                    <br/>
                    <b>"People acting TOGETHER as a group can accomplish things in which no individual acting alone could ever hope to bring about"</b>
                    <br/>
                    <b>Franklin D. Roosevelt</b>

                    <br/>
                    <br/>
                    Neosho Wrestling is one PROGRAM consisting of four different levels (Youth, Jr. High, High School, and Collegiate).  Each level is coached on the same philosophy, principals, and core values.  Our mentality is All for One – One for All.  Using the strengths of each member we will work hard to maintain a tradition of excellence founded in "OUR roots" – those core values for us are found in the following:  Faith, family, work ethic, persistence, loyalty, learning, and humility - All of which, I believe, to be keys in our success.  With a focus on these throughout our training and development I believe, we can maximize the potential we have as individuals but most importantly as a team/program.  This is done in a systematic and progressive approach to meeting the kids where they are at and using the experience of our advanced wrestlers/coaches to lay a strong foundation in fundamentals which we then build upon gradually.  Our training is based on high percentage fundamentals and understanding pressure and body position more than anything; not fancy, flashy moves.

                    <br/>
                    <br/>
                    We will have high expectations as we train at each level and with all student/athletes from 5 to 18 years of age, boy or girl.  We work hard to gain an edge on our opposition in one, if not all three, aspects of our training triangle - technical, physical, and mental.  Each is important but understanding the power of the mind in developing the other two aspects is critical.  Without discipline and focus of the mind our technique will suffer.  Without mental fortitude/toughness our physical development will not be maximized.  Preparing with a "three birds one stone" approach is something we want our young people to understand - meaning as a wrestler works on the technical side oftentimes they can work at a pace that improves their strength/endurance and with the right mental focus/toughness we build the correct habits and grit to be adequately prepared in all three sides to the training triangle.  This is after a step by step approach is taken for techniques so that all the details are done correctly.  Slow and right is always going to be better than fast and wrong.  We have developed many drills that allow us to work on basic techniques, positions, and pressure that helps us get needed repetitions to develop muscle memory and eventually reaction time/speed.   We are creatures of habit and <b>practice DOES NOT make perfect</b>, it makes permanent.

                    <br/>
                    <br/>
                    <b>Aristotle said, "We are what we repeatedly do.  Excellence, then, is not a singular act, but a habit."</b>

                    <br/>
                    <br/>
                    I am confident we can get our program battling again to be one of the top HS teams in the country.   We have an amazing coaching staff (listed above).  If you ever have a problem or concern please see the coach(es) first.  We do not want confrontation so be respectful and communicate appropriately so we can resolve issues adequately.  We are not perfect but I assure you we will work hard to build a positive relationship with all our wrestlers and keep a "what is best for our kids/program" mindset as we make decisions and train our wrestlers to be their very best.

                </div>

                <div className="container">
                    <i>
                        Dear Wrestling Parents,

                        <br/>
                        <br/>
                        Welcome to the sport of WRESTLING; a sport like no other.  I believe it is one of the greatest sports ever because of the ways it helps young men/women shape their character, develop self-esteem, improve discipline, and strengthen their will to succeed.  It is a very intense, demanding, and physical sport.  Our practices are designed to prepare us technically, physically, and mentally.  They are never necessarily fun but rather a necessity to develop the habits and toughness that allows each wrestler to compete at their very best and move closer to obtaining their/our goals.  Goal setting and understanding the ingredients necessary to reach goals that are set is one of many things I think is important to our progression as a program.  One of many valuable lessons for our staff is making sure our wrestlers learn the value of our attitude and effort - the only two factors that we always control.  I am confident that if one learns to have a positive attitude, a growth mindset, and learns how powerful it is to give their very best, we will not only be prepared for our toughest competitors, but also the toughest circumstances we face in our life's journey.

                        <br/>
                        <br/>
                        I ask that you support and encourage your child by helping them "believe in the process."  This means helping them understand OUR factors to success, which are in my opinion - a strong work ethic, loyalty, persistence, and learning from failure.  I want each of them to know what they do today matters for tomorrow, failure is not fatal but necessary for growth, giving up is never an option, and being a person that people can count on and trust is priceless.  Practicing humility, understanding we are family, and keeping the faith when things are tough are also key for us.  These core values will be emphasized daily and that is one reason why I believe you will see many positives come from your child's participation in our wrestling program.  Welcome to the Wildcat Wrestling Family!
                    </i>

                    <br/>
                    <br/>
                    <u><b>This year's motto:  Change</b></u>
                    <br/>
                    <br/>
                    Do you hate losing so much that you are willing to CHANGE, or do you hate CHANGE so much that you are willing to lose?  If you continue to do what you’ve always done, you will continue to get what you have always got.  Is that what you want?
                    <br/>
                    <br/>
                    OUR mindset must CHANGE first. (Growth vs. Fixed)
                    <br/>
                    <br/>
                    Change is tough but we have to be willing to do hard things. Starting with changing our mindset followed with a change in our actions. Consistent discipline with our actions will lead to a change with our habits and ultimately our destiny. Therefore a positive mindset change leads to positive habits that will shape our character development and consequently our outcomes on and off the mat.
                    <br/>

                    <br/>
                    <br/>

                </div>

                <div
                    className="page_link_locator"
                    id="who_can_join">
                </div>

                <div className="container">

                    <SectionHeading>Who can join Neosho Youth Wrestling?</SectionHeading>

                    <hr/>
                    Must be enrolled in the Neosho School District, grades K-8.

                    <br/>
                    Cannot be 15 years old before January 1, 2024
                </div>

                <div
                    className="page_link_locator"
                    id="important_dates">
                </div>

                <div className="container">
                    <SectionHeading>Important Dates</SectionHeading>

                    <hr/>

                    <b>Parent Meeting November 2, 2023 6:00pm</b>

                    <br/>
                    Carver Elementary School

                    <br/>
                    12350 Norway Rd

                    <br/>
                    Neosho MO, 64850

                    <br/>
                    <br/>
                    <b>First Practice </b>

                    <br/>
                    November 13, 2023

                    <br/>
                    <br/>
                    <b>Pictures</b>
                    <br/>
                    December 11, 2023
                    <br/>
                    Details at parent meeting
                </div>

                <div
                    className="page_link_locator"
                    id="practice_schedule">
                </div>

                <div className="container">
                    <SectionHeading>Practice Schedule</SectionHeading>
                    <hr/>
                    <b>Novice</b>
                    <br/>
                    Tuesday 5:15 to 6:15pm
                    <br/>
                    Thursday 5:15 to 6:15pm

                    <br/>
                    <br/>
                    <b>Intermediate</b>
                    <br/>
                    Monday 5:15 to 6:30pm
                    <br/>
                    Tuesday 6:15 to 7:30pm
                    <br/>
                    Thursday 6:15 to 7:30pm

                    <br/>
                    <br/>
                    <b>Advanced</b>
                    <br/>
                    Monday 5:15 to 6:30pm
                    <br/>
                    Tuesday 6:15 to 7:30pm
                    <br/>
                    Thursday 6:15 to 7:30pm

                    <br/>
                    <br/>
                    <b>Girls</b>
                    <br/>
                    All Girls Practice on Wednesday, Time to be determined
                </div>

                <div
                    className="page_link_locator"
                    id="coaching_staff">
                </div>

                <div className="container">
                    <SectionHeading>Coaching Staff</SectionHeading>
                    <hr/>

                    <b>Program Coordinator</b>
                    <br/>
                    Jeremy Phillips

                    <br/>
                    <br/>
                    <b>HS &amp; JH Boys Head Coach</b>
                    <br/>
                    Jeremy Phillips

                    <br/>
                    <br/>
                    <b>HS &amp; JH Head Girls Coach</b>
                    <br/>
                    Joel Schofield

                    <br/>
                    <br/>
                    <b>HS &amp; JH Assistant Coaches</b>
                    <br/>
                    Joel Schofield
                    <br/>
                    Cayden Auch
                    <br/>
                    Landon Kivett
                    <br/>
                    <br/>
                    <b>Youth Coaches</b>
                    <br/>
                    Landon Kivett
                    <br/>
                    Caden Auch
                    <br/>
                    Collyn Kivett
                    <br/>
                    Clayton Swadley

                    <br/>
                    <br/>
                    <b>Youth Girls Coach</b>
                    <br/>
                    James Townsend
                    <br/>
                    <br/>

                </div>

                <div
                    className="page_link_locator"
                    id="club_fees">
                </div>

                <div className="container">

                    <SectionHeading>Club Fees</SectionHeading>
                    <hr/>

                    <br/>
                    $150.00 Per wrestler

                    <br/>
                    <br/>
                    Fees Cover 2 Practice shirts, 1 Tournament Team Shirt, Shorts, USA Wrestling Card, Coaches Fees.

                    <br/>
                    <br/>
                    If a team singlet is needed, we will have them for sale starting at $30.00, up to $50.00
                    <br/>
                    Must wear a Neosho singlet during tournaments

                    <br/>
                    Tournament cost is about $25 a tournament.
                </div>

                <div
                    className="page_link_locator"
                    id="fundraising">
                </div>

                <div className="container">
                    <SectionHeading>Fundraising</SectionHeading>
                    <hr/>

                    <b>Banner Sales Form</b>
                    <br/>
                    We will be displaying our team banner at all tournaments that our club attends. Earn credit toward tournament dues, gear and other wrestling related expenses by recruiting sponsors for our banner. Use the form below to enroll a sponsor for our banner and earn credit toward your account.

                    <br/>
                    <br/>
                    <b>Tournament Sponsorship Form</b>
                    <br/>
                    We need help with our home tournament to offset the cost of the awards. There are three levels of sponsorship.
                    <br/>
                    More information at parent meeting
                </div>

                <div
                    className="page_link_locator"
                    id="scholarships">
                </div>

                <div className="container">
                    <SectionHeading>Scholarships</SectionHeading>
                    <hr/>

                    <b>Application</b>
                    <br/>
                    <a href="https://www.theallianceofswmo.org/full-potential-program/">https://www.theallianceofswmo.org/full-potential-program/</a>
                </div>

                <div
                    className="page_link_locator"
                    id="volunteer_coaches">
                </div>

                <div className="container">
                    <SectionHeading>Volunteer Coaches</SectionHeading>

                    <hr/>
                    To sign up for a coaches card, you will have to take the safe sport course, pass the background check, upload a photo, take the bronze course and affiliate with Neosho Youth Wrestling. Volunteer coaches will pay for their USA Wrestling coaches cards, background check and bronze course.

                    <br/>
                    <br/>
                    All valunteer coaches must follow the direction of the coaching staff and club board members. Volunteer coaches can be removed from the club for any reason subject to review by the coaching staff and club board.

                    <br/>
                    <br/>
                    TheMatt.com or https://www.teamusa.org/usa-wrestling
                </div>
            </React.Fragment>
        );
    }
}

export default Home;
