import { DateFormats } from 'core.frontend';
import * as React from 'react';
import Moment from 'react-moment';

export interface FormattedDateProps {
    date: Date;
    format?: DateFormats;
}

class FormattedDate extends React.PureComponent<FormattedDateProps> {

    public static defaultProps: Partial<FormattedDateProps> = {
        format: DateFormats.MMDDYYYY
    };

    public render(): JSX.Element {
        const { date, format } = this.props;

        return (
            <Moment
                format={format}
                date={date} />
        );
    }
}

export default FormattedDate;
