import * as React from 'react';

interface TableRecordCountProps {
    isLoading: boolean;
    recordsShown: number;
    totalRecords: number;
}

class TableRecordCount extends React.PureComponent<TableRecordCountProps> {

    public render(): JSX.Element {
        const { isLoading, recordsShown, totalRecords } = this.props;

        return (
            <div className="record-count">
                { isLoading ? (
                    <React.Fragment>
                         <span className="loading-placeholder shimmer"></span>
                     </React.Fragment>
                ) : (
                    <React.Fragment>
                        Showing <b>{recordsShown}</b> of <b>{totalRecords}</b> entries
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default TableRecordCount;
