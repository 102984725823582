import { ActionUtils, TextField as FrontEndField, FieldType } from 'core.frontend';
import FieldActions from 'core.frontend/dist/components/fieldActions';
import * as React from 'react';
import ObservingComponent from '../ObservingComponent';

export interface TextFieldProps {
    field: FrontEndField;
    labelClassName: string;
    className?: string;
}

class TextField extends ObservingComponent<TextFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public valueChanged = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.setValue(newValue);
    }

    public render(): JSX.Element {
        const { field, labelClassName, className } = this.props;

        return (
            <div className={className +
                            (field.fieldType === FieldType.TextArea ? ' textarea-field' : ' input-field')}>

                <label className={labelClassName}
                        htmlFor={field.uniqueKey}>

                    {field.label}
                </label>

                { field.isActive && !field.stringValue && field.placeholderLabel && <label>{ field.placeholderLabel }</label> }

                { field.fieldType !== FieldType.TextArea &&
                    <input
                        type="text"
                        key={field.uniqueKey}
                        id={field.uniqueKey}
                        name={field.uniqueKey}
                        onFocus={field.focus}
                        onBlur={field.blur}
                        value={field.stringValue}
                        onChange={this.valueChanged}
                        className={field.errorMessage ? 'invalid' : ''}
                        disabled={field.isDisabled}
                        maxLength={field.maxCharacters} />
                }

                { field.fieldType === FieldType.TextArea &&
                    <textarea
                        key={field.uniqueKey}
                        rows={3}
                        id={field.uniqueKey}
                        name={field.uniqueKey}
                        onFocus={field.focus}
                        onBlur={field.blur}
                        onChange={this.valueChanged}
                        className={field.errorMessage ? 'invalid' : ''}
                        disabled={field.isDisabled}
                        value={field.stringValue}
                        maxLength={field.maxCharacters}>
                            {field.stringValue}
                        </textarea>
                }

                { field.errorMessage && (
                    <span className="invalid error-message">{field.errorMessage}</span>
                )}
            </div>
        );
    }
}

export default TextField;
