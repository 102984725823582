import NavigatorActions from 'core.frontend/dist/navigator/navigatorActions';
import * as React from 'react';
import ReactApp from '../reactApp';
import ObservingProvider from './ObservingComponent';

interface NavMenuBackgroundProps {
    reactApp: ReactApp;
}

class NavMenuBackground extends ObservingProvider<NavMenuBackgroundProps> {

    public componentWillMount(): void {
        const { reactApp } = this.props;

        this.registerUpdateObserver(reactApp.app.navigator.observationProvider, [NavigatorActions.MenuClosed, NavigatorActions.MenuOpened]);
    }

    public render(): JSX.Element {
        const { reactApp } = this.props;

        return (
            <div
                className={reactApp.app.navigator.menuOpen ? 'active nav-background' : 'nav-background'}
                onClick={reactApp.app.navigator.closeMenu}>
            </div>
        );
    }
}

export default NavMenuBackground;
