"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FieldType;
(function (FieldType) {
    FieldType[FieldType["TextBox"] = 0] = "TextBox";
    FieldType[FieldType["Password"] = 1] = "Password";
    FieldType[FieldType["Money"] = 2] = "Money";
    FieldType[FieldType["CheckBox"] = 3] = "CheckBox";
    FieldType[FieldType["DropDown"] = 4] = "DropDown";
    FieldType[FieldType["Date"] = 5] = "Date";
    FieldType[FieldType["Phone"] = 6] = "Phone";
    FieldType[FieldType["DateTime"] = 7] = "DateTime";
    FieldType[FieldType["TextArea"] = 8] = "TextArea";
    FieldType[FieldType["Int"] = 9] = "Int";
    FieldType[FieldType["Decimal"] = 10] = "Decimal";
})(FieldType || (FieldType = {}));
exports.default = FieldType;
