"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var debounce_1 = require("../../utils/debounce");
var endpointUrls_1 = require("../../api/endpointUrls");
var components_1 = require("../../components");
var submittableForm_1 = require("../../components/submittableForm");
var observationProvider_1 = require("../../utils/observationProvider");
var _1 = require(".");
var TournamentDetailPage = /** @class */ (function (_super) {
    __extends(TournamentDetailPage, _super);
    function TournamentDetailPage(api, navigator, tournamentId) {
        var _this = _super.call(this, __1.PageTypes.TournamentDetail, 'New Tournament') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.tournamentId = tournamentId;
        _this.backToTournaments = function () {
            _this.navigator.goToTournaments();
        };
        _this.onExport = function () {
            _this.api.download(endpointUrls_1.default.Tournaments + '/' + _this.tournamentId + '/export', 'tournamentEntryReport.csv');
        };
        _this.signUpEntryTableLoaded = function () {
            _this._signUpEntryForms = [];
            var _loop_1 = function (signUpEntry) {
                var weightField = new components_1.DecimalField('actualWeight', 'Actual Weight', false);
                var newForm = new components_1.Form([
                    weightField
                ]);
                _this._signUpEntryForms.push(newForm);
                newForm.setModel(signUpEntry);
                weightField.observationProvider.observe(function () { return _this.actualWeightChanged(newForm); }, components_1.FieldActions.ValueChanged);
            };
            for (var _i = 0, _a = _this._signUpEntryTable.items; _i < _a.length; _i++) {
                var signUpEntry = _a[_i];
                _loop_1(signUpEntry);
            }
            _this.observationProvider.notifyObservers(_1.TournamentDetailPageActions.SignUpEntryFormsBuilt);
        };
        _this.actualWeightChanged = function (form) {
            form.submit();
            if (form.isValid()) {
                _this.debouncedSubmitActualWeight(form.model);
            }
        };
        _this.submitActualWeight = function (entry) { return __awaiter(_this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.observationProvider.notifyObservers(_1.TournamentDetailPageActions.SubmittingActualWeight);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.api.post(endpointUrls_1.default.Tournaments + '/SubmitWeight', entry)];
                    case 2:
                        _a.sent();
                        this._successfullWeightSubmissionIds.push(entry.tournamentAthleteSignUpId);
                        this.observationProvider.notifyObservers(_1.TournamentDetailPageActions.ActualWeightSubmitted);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this._errorWeightSubmissionIds.push(entry.tournamentAthleteSignUpId);
                        this.observationProvider.notifyObservers(_1.TournamentDetailPageActions.ErrorSubmittingActualWeight);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.addDivisionForm = function (division) {
            if (!division) {
                return;
            }
            var newForm = new components_1.Form([
                new components_1.TextField('name', 'Name', true, 100, 'Required'),
                new components_1.MoneyField('entryFee', 'Entry Fee', true, 'Required'),
                new components_1.WeightClassListField('weightClasses', 'Weight Classes', true, 2000, 'Required', null)
            ]);
            _this._divisionForms.push(newForm);
            newForm.setModel(division);
        };
        _this.addDivision = function () {
            _this.addDivisionForm({});
            _this.observationProvider.notifyObservers(_1.TournamentDetailPageActions.DivisionAdded);
        };
        _this.removeDivision = function (index) {
            var division = _this.divisionForms[index].model;
            if (division.id) {
                division.isRemoved = true;
            }
            else {
                _this.divisionForms.splice(index, 1);
            }
            _this.observationProvider.notifyObservers(_1.TournamentDetailPageActions.DivisionRemoved);
        };
        _this.cancelRemoveDivision = function (index) {
            _this.divisionForms[index].model.isRemoved = false;
            _this.observationProvider.notifyObservers(_1.TournamentDetailPageActions.CancelRemoveDivision);
        };
        _this.loadRecord = function (recordId) {
            _this._isLoading = true;
            _this.observationProvider.notifyObservers(_1.TournamentDetailPageActions.RecordLoading);
            _this.api.getById(endpointUrls_1.default.Tournaments, recordId)
                .then(function (tournament) {
                _this.form.setModel(tournament);
                for (var _i = 0, _a = tournament.divisions; _i < _a.length; _i++) {
                    var division = _a[_i];
                    _this.addDivisionForm(division);
                }
                _this._isLoading = false;
                _this.observationProvider.notifyObservers(_1.TournamentDetailPageActions.RecordLoaded);
            })
                .catch(function () {
                _this._isLoading = false;
                _this.observationProvider.notifyObservers(_1.TournamentDetailPageActions.RecordLoadFailure, 'Unable to load this information');
            });
            _this.navigator.goToTournaments();
        };
        _this.save = function () {
            var isValid = true;
            for (var _i = 0, _a = _this._divisionForms; _i < _a.length; _i++) {
                var divisionForm = _a[_i];
                divisionForm.submit();
                isValid = isValid && divisionForm.isValid();
            }
            if (isValid) {
                _this._form.model.divisions = _this._divisionForms.map(function (divisionForm) { return divisionForm.model; });
                _this._form.submit();
            }
        };
        _this.cancel = function () {
            _this.observationProvider.notifyObservers(_1.TournamentDetailPageActions.Cancel);
            _this.navigator.goToTournaments();
        };
        _this.delete = function () {
            if (!_this.isDeletable) {
                throw new Error('Cannot delete because the record Id is not specified');
            }
            _this.observationProvider.notifyObservers(_1.TournamentDetailPageActions.RecordDeleting, _this.form.model);
            _this.api.delete(endpointUrls_1.default.Tournaments, _this.tournamentId);
        };
        _this.observationProvider = new observationProvider_1.default();
        _this._divisionForms = [];
        _this.buildForm();
        _this.debouncedSubmitActualWeight = debounce_1.debounce(_this.submitActualWeight.bind(_this), 600);
        _this._signUpEntryTable = new components_1.Table(endpointUrls_1.default.Tournaments + "/" + tournamentId + "/sign-ups", api, _this.onExport);
        _this._signUpEntryTable.setPagingEnabled(false);
        _this._successfullWeightSubmissionIds = [];
        _this._errorWeightSubmissionIds = [];
        if (tournamentId) {
            _this._signUpEntryTable.load();
            _this._signUpEntryTable.observationProvider.observe(_this.signUpEntryTableLoaded, components_1.TableActions.Loaded);
            _this.loadRecord(tournamentId);
            _this.title = 'Edit Tournament';
        }
        return _this;
    }
    Object.defineProperty(TournamentDetailPage.prototype, "successfullWeightSubmissionIds", {
        get: function () {
            return this._successfullWeightSubmissionIds;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournamentDetailPage.prototype, "errorWeightSubmissionIds", {
        get: function () {
            return this._errorWeightSubmissionIds;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournamentDetailPage.prototype, "signUpEntryForms", {
        get: function () {
            return this._signUpEntryForms;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournamentDetailPage.prototype, "divisionForms", {
        get: function () {
            return this._divisionForms;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournamentDetailPage.prototype, "signUpEntryTable", {
        get: function () {
            return this._signUpEntryTable;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournamentDetailPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournamentDetailPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    TournamentDetailPage.prototype.buildForm = function () {
        var fields = [
            new components_1.TextField('name', 'Tournament Name', true, 100, 'Name is required'),
            new components_1.TextField('location', 'Location', true, 100, 'Location is required'),
            new components_1.DateTimeField('date', 'Tournament Date', true, 'Date & Time is required'),
            new components_1.DateTimeField('signUpStartDateTime', 'Sign-up Start', true, 'Start date & time is required'),
            new components_1.DateTimeField('signUpEndDateTime', 'Sign-up Deadline', true, 'Deadline date & time is required'),
            new components_1.TextField('divisionCuttoffDescription', 'Division Cutoff', true, 100, 'Cutoff is required'),
        ];
        this._form = new submittableForm_1.default(endpointUrls_1.default.Tournaments, this.api, fields);
    };
    Object.defineProperty(TournamentDetailPage.prototype, "isDeletable", {
        get: function () {
            return !!this.tournamentId;
        },
        enumerable: false,
        configurable: true
    });
    return TournamentDetailPage;
}(__1.BasePage));
exports.default = TournamentDetailPage;
