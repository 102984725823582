"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../");
var endpointUrls_1 = require("../../api/endpointUrls");
var table_1 = require("../../components/table");
var ParentAccountsPage = /** @class */ (function (_super) {
    __extends(ParentAccountsPage, _super);
    function ParentAccountsPage(api, navigator) {
        var _this = _super.call(this, __1.PageTypes.ParentAccounts, 'Parent Accounts') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.goToParentAccountDetail = function (registrationId) {
            _this.navigator.goToParentAccountDetail(registrationId);
        };
        _this.onExport = function () {
            _this.api.download(endpointUrls_1.default.Registrations + '/export', 'registrationReport.csv');
        };
        _this.internalUsersTable = new table_1.default(endpointUrls_1.default.Registrations, api, _this.onExport);
        _this.internalUsersTable.load();
        return _this;
    }
    Object.defineProperty(ParentAccountsPage.prototype, "parentAccountsTable", {
        get: function () {
            return this.internalUsersTable;
        },
        enumerable: false,
        configurable: true
    });
    return ParentAccountsPage;
}(__1.BasePage));
exports.default = ParentAccountsPage;
