import { ActionUtils, DateTimeField as FrontEndDateTimeField, FieldOption } from 'core.frontend';
import FieldActions from 'core.frontend/dist/components/fieldActions';
import * as React from 'react';
import ObservingComponent from '../ObservingComponent';

export interface DateTimeFieldProps {
    field: FrontEndDateTimeField;
    labelClassName: string;
    className?: string;
}

class DateTimeField extends ObservingComponent<DateTimeFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    private buildOptions = (fieldOptions: FieldOption[]): JSX.Element[] => {

        const options: JSX.Element[] = [];
        options.push(<option
                            key={0}
                            value=""
                            disabled
                            selected>
                        </option>);

        let i: number = 1;
        for (const option of fieldOptions) {
            options.push(
                <option
                    key={i++}
                    value={option.value ? option.value.toString() : null}>

                    {option.label}
                </option>
            );
        }

        return options;
    }

    public dateValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.setDateValue(newValue);
    }

    public timeValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.setTimeValue(newValue);
    }

    public amPmValueChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.setAmPmValue(newValue);
    }

    public timezoneValueChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.setTimezoneValue(newValue);
    }

    public render(): JSX.Element {
        const { field, labelClassName, className } = this.props;

        return (
            <div className={className + ' input-field datetime-field-wrapper select-wrapper'}>

                <label className={labelClassName}
                        htmlFor={field.dateKey}>

                    {field.label}
                </label>

                <div className="inline-input-wrapper">

                    { field.isActive && !field.dateStringValue && field.dateFieldPlaceHolderLabel && <label htmlFor={field.dateKey} className="date-placeholder-label">{ field.dateFieldPlaceHolderLabel }</label> }

                    <input
                        type="text"
                        id={field.dateKey}
                        name={field.dateKey}
                        onFocus={field.focusDate}
                        onBlur={field.blurDate}
                        value={field.dateStringValue}
                        onChange={this.dateValueChanged}
                        className={field.dateErrorMessage ? 'date-input invalid' : 'date-input'}
                        disabled={field.isDisabled}
                        maxLength={10} />

                    { field.isActive && !field.timeStringValue && field.timeFieldPlaceHolderLabel && <label htmlFor={field.timeKey} className="time-placeholder-label">{ field.timeFieldPlaceHolderLabel }</label> }

                    <input
                        type="text"
                        id={field.timeKey}
                        name={field.timeKey}
                        onFocus={field.focusTime}
                        onBlur={field.blurTime}
                        value={field.timeStringValue}
                        onChange={this.timeValueChanged}
                        className={field.timeErrorMessage ? 'time-input invalid' : 'time-input'}
                        disabled={field.isDisabled}
                        maxLength={5} />

                    { field.isActive && !field.amPmStringValue && field.amPmFieldPlaceHolderLabel && <label htmlFor={field.amPmKey} className="amPm-placeholder-label">{ field.amPmFieldPlaceHolderLabel }</label> }

                    <select
                        id={field.amPmKey}
                        name={field.amPmKey}
                        onFocus={field.focusApPm}
                        onBlur={field.blurAmPm}
                        value={field.amPmStringValue}
                        onChange={this.amPmValueChanged}
                        className={field.amPmErrorMessage ? 'ampm-select invalid' : 'ampm-select'}
                        disabled={field.isDisabled} >

                        { this.buildOptions(field.amPmOptions) }

                    </select>

                    { field.isActive && !field.timezoneStringValue && field.timezoneFieldPlaceHolderLabel && <label htmlFor={field.timezoneKey} className="timezone-placeholder-label">{ field.timezoneFieldPlaceHolderLabel }</label> }

                    <select
                        id={field.timezoneKey}
                        name={field.timezoneKey}
                        onFocus={field.focusTimezone}
                        onBlur={field.blurTimezone}
                        value={field.timezoneStringValue}
                        onChange={this.timezoneValueChanged}
                        className={field.timezoneErrorMessage ? 'timezone-select invalid' : 'timezone-select'}
                        disabled={field.isDisabled} >

                        { this.buildOptions(field.timezoneOptions) }

                    </select>
                </div>

                { field.errorMessage && (
                    <span className="invalid error-message">{field.errorMessage}</span>
                )}
            </div>
        );
    }
}

export default DateTimeField;
