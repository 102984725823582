import * as React from 'react';
import ObservingProvider from './ObservingComponent';

interface ErrorMessageProps {
    errorMessage: string;
}

class ErrorMessage extends ObservingProvider<ErrorMessageProps> {

    public render(): JSX.Element {
        const { errorMessage } = this.props;

        return (
            <React.Fragment>
                { errorMessage && <div className="alert alert-danger">{errorMessage}</div> }
            </React.Fragment>
        );
    }
}

export default ErrorMessage;
