"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EndpointUrls;
(function (EndpointUrls) {
    EndpointUrls["Users"] = "Users";
    EndpointUrls["Registrations"] = "Registrations";
    EndpointUrls["Login"] = "Authentication/Login";
    EndpointUrls["Tournaments"] = "Tournaments";
})(EndpointUrls || (EndpointUrls = {}));
exports.default = EndpointUrls;
