"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../");
var endpointUrls_1 = require("../../api/endpointUrls");
var submittableFormActions_1 = require("../../components/submittableFormActions");
var table_1 = require("../../components/table");
var userDetailPageActions_1 = require("./userDetailPageActions");
var UsersPage = /** @class */ (function (_super) {
    __extends(UsersPage, _super);
    function UsersPage(api, navigator) {
        var _this = _super.call(this, __1.PageTypes.Users, 'Admins') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.addUser = function () {
            _this.navigator.goToUserDetail();
            var userDetailPage = _this.navigator.getCurrentPage();
            userDetailPage.form.observationProvider.observe(_this.addUserToTable, submittableFormActions_1.default.RecordAdding);
            userDetailPage.form.observationProvider.observe(_this.usersTable.recordAddedSuccessfully, submittableFormActions_1.default.RecordAdded);
        };
        _this.editUser = function (userId) {
            _this.navigator.goToUserDetail(userId);
            var userDetailPage = _this.navigator.getCurrentPage();
            userDetailPage.form.observationProvider.observe(_this.markUserAsEditing, submittableFormActions_1.default.RecordEditing);
            userDetailPage.form.observationProvider.observe(_this.markUserAsEdited, submittableFormActions_1.default.RecordEdited);
            userDetailPage.observationProvider.observe(_this.markUserAsDeleting, userDetailPageActions_1.default.RecordDeleting);
        };
        _this.addUserToTable = function (user) {
            _this._usersTable.insertAddingRecord(user);
            _this.navigator.goToUsers();
        };
        _this.markUserAsEditing = function (user) {
            _this._usersTable.markRecordAsSaving(user.id);
            _this.navigator.goToUsers();
        };
        _this.markUserAsEdited = function (user) {
            _this._usersTable.markRecordAsNotSaving(user.id);
        };
        _this.markUserAsDeleting = function (user) {
            _this._usersTable.markRecordAsDeleting(user.id);
            _this.navigator.goToUsers();
        };
        _this._usersTable = new table_1.default(endpointUrls_1.default.Users, api);
        _this._usersTable.load();
        return _this;
    }
    Object.defineProperty(UsersPage.prototype, "usersTable", {
        get: function () {
            return this._usersTable;
        },
        enumerable: false,
        configurable: true
    });
    return UsersPage;
}(__1.BasePage));
exports.default = UsersPage;
