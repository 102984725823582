import { GuardianDto } from 'core.frontend';
import * as React from 'react';
import Table from '../../misc/components/Table';

interface GuardianTableProps {
    guardians: GuardianDto[];
}

class GuardianTable extends React.PureComponent<GuardianTableProps> {

    public render(): JSX.Element {
        const { guardians } = this.props;

        return (
            <div className="form-container">
                <h6>Guardians</h6>
                <Table
                    columns={[{
                            heading: 'Name',
                            cellTemplate: (guardian) => guardian.fullName
                        }, {
                            heading: 'Contact Info',
                            cellTemplate: (guardian) => <span>{guardian.cellPhone} / {guardian.email}</span>
                        }]}
                    items={guardians} />
            </div>
        );
    }
}

export default GuardianTable;
