import { TournamentSignUpPage } from 'core.frontend';
import * as React from 'react';
import AccountBalance from '../../misc/components/AccountBalance';
import Button from '../../misc/components/Button';
import ObservingComponent from '../../misc/components/ObservingComponent';
import PayPalButton from '../../misc/components/PayPalButton';

interface TournamentSignUpPayProps {
    page: TournamentSignUpPage;
}

class TournamentSignUpPay extends ObservingComponent<TournamentSignUpPayProps> {

    private getPayPalButtonId = () => {
        const { page } = this.props;

//        return 'R99V9LEMTUYVA';

        switch (page.tournamentSignUpDto.totalAmountDue) {
            case 1:
                return 'MJR9CW8CW94AS';
            case 5:
                return 'V86LYZSBVYAY2';
            case 10:
                return 'NB477VQBA5SXQ';
            case 12:
                return 'P29G4MARVYFVJ';
            case 15:
                return 'S37V5Q2P5GYFN';
            case 20:
                return '39M4R7RCMFJYY';
            case 21:
                return 'G8TJ866XJLQ36';
            case 24:
                return '954XDQTKLYGLA';
            case 25:
                return 'K625X63DTXP6N';
            case 28:
                return 'K3PD8CGHC24WA';
            case 30:
                return '94ZKF9FXFX44U';
            case 32:
                return 'QCKHFZGJA3VX8';
            case 35:
                return '3GWAXJYDWJN4N';
            case 36:
                return 'VX6PH9RM9KRJY';
            case 38:
                return '6JD32KX6V7REY';
            case 40:
                return '9AU7WKX47U5PE';
            case 42:
                return 'KY3FXVFDLWQVY';
            case 43:
                return 'DNA89BVQ6NEBG';
            case 45:
                return '23BNVZEH676BG';
            case 47:
                return '2U3V2485V5AG6';
            case 48:
                return 'GMDAR2EHG6CRY';
            case 50:
                return 'KUDLKB58SE3AW';
            case 55:
                return 'C3YYMPURUGEVU';
            case 56:
                return '4GWB9TB7G8KJC';
            case 60:
                return 'WXBDWP38NXYS2';
            case 64:
                return 'DG2MQ8FREVBXA';
            case 65:
                return 'EC4RN7VH3DXJ6';
            case 70:
                return 'FQQU3LY6QWFN6';
            case 75:
                return 'U2XQHJVRBHHLJ';
            case 76:
                return 'E7GWES9F2427C';
            case 80:
                return '9FFMN6XNBXR86';
            case 84:
                return 'PBF6QKNYUGHZY';
            case 85:
                return '696NB8SAVE6FJ';
            case 86:
                return '7ACKJTPH7VVQW';
            case 90:
                return 'J364T4HT9Z9FW';
            case 94:
                return '4CKNE3SYEZ24N';
            case 95:
                return '89ETNPRB4MRBN';
            case 96:
                return '3ANUE99JY6RM6';
            case 100:
                return 'JQKSNLVDK3L3E';
            case 105:
                return '72QTTYJA2YVHQ';
            case 110:
                return '6HF8B9G28DEM6';
            case 112:
                return 'AT39SE9ZHL566';
            case 114:
                return 'WUGPQKTMWSXEE';
            case 115:
                return '78PLYKBSPLEGA';
            case 120:
                return '882B86SQ8K6AG';
            case 126:
                return 'NPCP36QMVAKXL';
            case 125:
                return 'ADLWE5S2QQFJ2';
            case 128:
                return 'V4TRSRR7NBLJ2';
            case 129:
                return 'XTZWNKRKRZT4W';
            case 130:
                return '6C5MP565P2X8C';
            case 135:
                return '2WCVE4KNKWAH4';
            case 140:
                return '68UBV63Z3KG5A';
            case 145:
                return 'T5A3KYG9WBMU6';
            case 150:
                return '4XLDYVUNH8XZ6';
            case 152:
                return 'XZEKQHKLX9S9E';
            case 155:
                return 'UFSSZPGY3SCW6';
            case 160:
                return 'G9P2QSLMTHF6E';
            case 165:
                return '99QA782NQK4WL';
            case 170:
                return 'LH4V3XHZSS3XN';
            case 172:
                return 'KYAQC82YUZTKE';
            case 175:
                return 'FXNUU8NXX4T62';
            case 180:
                return 'VELQRMD2SL4KY';
            case 185:
                return '6YP5TVMRLS78C';
            case 190:
                return 'JRL3PL6GQN6P2';
            case 195:
                return 'PLW3CL54QTCYJ';
            case 200:
                return '4WETGGAXFJFM6';
            case 141:
                return 'CEJ3VLVWRRX3U';
            case 188:
                return 'DFTM2DMG3QUZJ';
            case 215:
                return 'N74CKBQZWLXP4';
            case 235:
                return '3VR78KB72YSHG';
        }
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <div className="tournament-signup-payment">
                <form>
                    <div className="parent-detail-balance">
                        <h4>Fundraising Account Balance</h4>
                        <AccountBalance balance={page.currentParentAccountManager.parentAccount.balance} />
                    </div>

                    <div className="tournament-signup-total-due">
                        Total Due $<AccountBalance balance={page.tournamentSignUpDto.totalAmountDue} />
                    </div>

                    {!page.allowPaymentFromAccount && (
                        <p>At this time you can only pay for tournament entry fees if your account can cover the entire amount due. We are working on our site to allow for partial payments from the account balance and hope to have this in place mid-season. Thank you for your patience on this.</p>
                    )}

                    <div className="payment-button-container">
                        {page.allowPaymentFromAccount && (
                            <React.Fragment>
                                <Button
                                    className="btn btn-primary btn-wide-phone"
                                    onClick={page.payFromAccount}
                                    text="Pay From Fundraising Account" />

                                <span className="or-span">OR</span>
                            </React.Fragment>
                        )}

                        <PayPalButton
                            registrationId={page.tournamentSignUpDto.registrationId}
                            buttonId={this.getPayPalButtonId()}
                            tournamentSignUpId={page.tournamentSignUpDto.id}
                            tournamentName={page.tournament.name} />
                    </div>
                </form>
            </div>
        );
    }
}

export default TournamentSignUpPay;
