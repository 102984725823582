"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("../utils");
var observationProvider_1 = require("../utils/observationProvider");
var currentUserManagerActions_1 = require("./currentUserManagerActions");
var CurrentUserManager = /** @class */ (function () {
    function CurrentUserManager(localStoragePlugin) {
        var _this = this;
        this.localStoragePlugin = localStoragePlugin;
        this.observationProvider = new observationProvider_1.default();
        this.loadCurrentUserToken = function () {
            _this.authenticatedUserDto = _this.localStoragePlugin.getCurrentUser();
            if (_this.authenticatedUserDto && _this.isTokenExpired()) {
                _this.logout();
            }
        };
        this.isTokenExpired = function () {
            return (!_this.authenticatedUserDto.expirationDateTime) || utils_1.DateUtils.hasDatePast(_this.authenticatedUserDto.expirationDateTime);
        };
        this.login = function (authenticatedUserDto) {
            _this.authenticatedUserDto = authenticatedUserDto;
            _this.localStoragePlugin.setCurrentUser(authenticatedUserDto);
            _this.observationProvider.notifyObservers(currentUserManagerActions_1.default.LoggedIn);
        };
        this.logout = function () {
            _this.authenticatedUserDto = null;
            _this.localStoragePlugin.setCurrentUser(null);
            _this.observationProvider.notifyObservers(currentUserManagerActions_1.default.LoggedOut);
        };
        this.loadCurrentUserToken();
    }
    CurrentUserManager.prototype.isLoggedIn = function () {
        return this.authenticatedUserDto !== null;
    };
    Object.defineProperty(CurrentUserManager.prototype, "authenticationToken", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.authenticationToken;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "userId", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.userId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentUserManager.prototype, "userFullName", {
        get: function () {
            if (!this.authenticatedUserDto) {
                return null;
            }
            return this.authenticatedUserDto.firstName + " " + this.authenticatedUserDto.lastName;
        },
        enumerable: false,
        configurable: true
    });
    return CurrentUserManager;
}());
exports.default = CurrentUserManager;
