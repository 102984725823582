"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("../");
var endpointUrls_1 = require("../../api/endpointUrls");
var table_1 = require("../../components/table");
var __2 = require("../..");
var TournamentsPage = /** @class */ (function (_super) {
    __extends(TournamentsPage, _super);
    function TournamentsPage(api, navigator) {
        var _this = _super.call(this, __1.PageTypes.Tournaments, 'Tournaments') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.goToTournamentDetail = function (id) { return function () {
            _this.navigator.goToTournamentDetail(id);
            var tournamentDetailPage = _this.navigator.getCurrentPage();
            tournamentDetailPage.form.observationProvider.observe(_this.markRecordAsEditing, __2.SubmittableFormActions.RecordEditing);
            tournamentDetailPage.form.observationProvider.observe(_this.markRecordAsEdited, __2.SubmittableFormActions.RecordEdited);
            tournamentDetailPage.observationProvider.observe(_this.markRecordAsDeleting, __1.TournamentDetailPageActions.RecordDeleting);
        }; };
        _this.addTournament = function () {
            _this.navigator.goToTournamentDetail();
            var tournamentDetailPage = _this.navigator.getCurrentPage();
            tournamentDetailPage.form.observationProvider.observe(_this.addRecordToTable, __2.SubmittableFormActions.RecordAdding);
            tournamentDetailPage.form.observationProvider.observe(_this._table.recordAddedSuccessfully, __2.SubmittableFormActions.RecordAdded);
        };
        _this.addRecordToTable = function (record) {
            _this._table.insertAddingRecord(record);
            _this.navigator.goToTournaments();
        };
        _this.markRecordAsEditing = function (record) {
            _this._table.markRecordAsSaving(record.id);
            _this.navigator.goToTournaments();
        };
        _this.markRecordAsEdited = function (record) {
            _this._table.markRecordAsNotSaving(record.id);
        };
        _this.markRecordAsDeleting = function (record) {
            _this._table.markRecordAsDeleting(record.id);
            _this.navigator.goToTournaments();
        };
        _this._table = new table_1.default(endpointUrls_1.default.Tournaments, api);
        _this._table.load();
        return _this;
    }
    Object.defineProperty(TournamentsPage.prototype, "table", {
        get: function () {
            return this._table;
        },
        enumerable: false,
        configurable: true
    });
    return TournamentsPage;
}(__1.BasePage));
exports.default = TournamentsPage;
