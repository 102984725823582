import { BaseField, Form, RegistrationPage, RegistrationPageState } from 'core.frontend';
import * as React from 'react';
import Button from '../../misc/components/Button';
import ObservingComponent from '../../misc/components/ObservingComponent';
import PayPalButton from '../../misc/components/PayPalButton';

interface RegistrationReviewStepProps {
    page: RegistrationPage;
}

class RegistrationReviewStep extends ObservingComponent<RegistrationReviewStepProps> {

    private goToFormStep = (): void => {
        const { page } = this.props;

        page.setState(RegistrationPageState.RegistrationForm);
    }

    private getPayPalButtonId = (athleteCount: number): string => {
        switch (athleteCount) {
            case 1:
                return 'VHM7JVLN6KWJ2';
            case 2:
                return '5PQGGS7N7Y5SW';
            case 3:
                return 'XGK6RY6HUXB4N';
            case 4:
                return 'TGUU24KN88D2Q';
            case 5:
                return 'E2X3RSMWHV26A';
        }
    }

    private getItemDescription = (athleteCount: number): string => {
        switch (athleteCount) {
            case 1:
                return '1 Wrestler Registration $150.00';
            case 2:
                return '2 Wrestler Registration $300.00';
            case 3:
                return '3 Wrestler Registration $450.00';
            case 4:
                return '4 Wrestler Registration $600.00';
            case 5:
                return '5 Wrestler Registration $750.00';
        }
    }

    private getFieldValueDiv = (fields: BaseField<any>[]): JSX.Element[] => {
        return fields.map((field) => (
            <div className="col-12 col-sm-4">
                {field.label} : <b>{field.stringValue}</b>
            </div>
        ));
    }

    private getFormContainer = (forms: Form<any>[] | Form<any>, heading: string): JSX.Element => {
        if (!Array.isArray(forms)) {
            forms = [forms];
        }

        return <div className="form-container">
            <h6>{heading}</h6>

            {forms.map((form, index) => {
                return (
                    <div className={index % 2 === 1 ? 'alt bordered-row' : 'bordered-row'}>
                        <div className="row">
                            { this.getFieldValueDiv(form.fields) }
                        </div>
                    </div>
                );
            })}
        </div>;
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <div className="container">
                <div className="form-container">
                    <p className="text-left">
                        <Button
                            onClick={this.goToFormStep}
                            className="btn btn-link"
                            leftIcon="fa-chevron-left"
                            text="Back" />
                    </p>

                    <div className="registration-access-code-wrapper">
                        <span>{page.accessCode}</span>
                        Your phone number is your access code
                    </div>

                    <p>
                        You'll need this number to sign-up for tournaments. Please be sure to review the form below.
                        Use the back button if you need to make corrections to your registration.
                    </p>

                    <div className="payment-breakdown">
                        <span className="item-description">{this.getItemDescription(page.atheleteForms.length)}</span>

                        <PayPalButton
                            buttonId={this.getPayPalButtonId(page.atheleteForms.length)}
                            registrationId={page.registrationId} />
                    </div>
                </div>

                { this.getFormContainer(page.atheleteForms, 'Wrestlers')}
                { this.getFormContainer(page.guardianForms, 'Guardians')}
                { this.getFormContainer(page.emergencyContactForm, 'Additional Emergency Contacts')}
                { this.getFormContainer(page.insuranceForm, 'Insurance Information')}
                { this.getFormContainer(page.healthCareProviderForm, 'Health Care Provider')}

            </div>
        );
    }
}

export default RegistrationReviewStep;
