"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ParentAccountBalanceAdjustmentFormActions;
(function (ParentAccountBalanceAdjustmentFormActions) {
    ParentAccountBalanceAdjustmentFormActions["Cancel"] = "Cancel";
    ParentAccountBalanceAdjustmentFormActions["Saving"] = "Saving";
    ParentAccountBalanceAdjustmentFormActions["Saved"] = "Saved";
    ParentAccountBalanceAdjustmentFormActions["Error"] = "Error";
    ParentAccountBalanceAdjustmentFormActions["FormInvalid"] = "FormInvalid";
    ParentAccountBalanceAdjustmentFormActions["Shown"] = "Shown";
    ParentAccountBalanceAdjustmentFormActions["Hidden"] = "Hidden";
    ParentAccountBalanceAdjustmentFormActions["AdjustmentTypeChanged"] = "AdjustmentTypeChanged";
})(ParentAccountBalanceAdjustmentFormActions || (ParentAccountBalanceAdjustmentFormActions = {}));
exports.default = ParentAccountBalanceAdjustmentFormActions;
