import { App, IPage, PageTypes } from 'core.frontend';
import NavigatorActions from 'core.frontend/dist/navigator/navigatorActions';
import * as React from 'react';
import PageRoute from './pageRoute';
import routes from './routes';

export default class Router {
    private _pageRoutes: PageRoute<IPage>[];
    private _pageTypesDictionary: PageRoute<IPage>[];
    private _pageHashDictionary: PageRoute<IPage>[];

    constructor(private app: App) {
        window.onpopstate = () => {
            this.routeToPage();
        };

        this.buildPageRoutes();

        app.navigator.observationProvider.observe(this.setRoute, NavigatorActions.PageChanged);

        this.routeToPage();
    }

    private routeToPage = () => {
        const hash = window.location.hash
            .replace('#', '')
            .replace(/[0-9]/g, '');

        const page = this._pageHashDictionary[hash];

        if (page) {
            page.navigateTo(window.location.hash, this.app.navigator);
        } else {
            this._pageTypesDictionary[PageTypes.Home].navigateTo(window.location.hash, this.app.navigator);
        }
    }

    private setRoute = (currentPage: IPage) => {
        window.location.hash = this._pageTypesDictionary[currentPage.pageType].buildUrlHash(currentPage);
    }

    public getCurrentPageComponent = (): JSX.Element => {
        const currentPage = this.app.navigator.getCurrentPage();

        return this._pageTypesDictionary[currentPage.pageType].getComponent(currentPage);
    }

    private buildPageRoutes = () => {
        this._pageHashDictionary = [];
        this._pageTypesDictionary = [];
        this._pageRoutes = routes;

        for (const route of this._pageRoutes) {
            this._pageTypesDictionary[route.pageType] = route;
            this._pageHashDictionary[route.hash] = route;
        }
    }
}
