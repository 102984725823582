"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TournamentDetailPageActions;
(function (TournamentDetailPageActions) {
    TournamentDetailPageActions["RecordDeleting"] = "RecordDeleting";
    TournamentDetailPageActions["RecordLoading"] = "RecordLoading";
    TournamentDetailPageActions["RecordLoaded"] = "RecordLoaded";
    TournamentDetailPageActions["RecordLoadFailure"] = "RecordLoadFailure";
    TournamentDetailPageActions["Cancel"] = "Cancel";
    TournamentDetailPageActions["DivisionAdded"] = "DivisionAdded";
    TournamentDetailPageActions["DivisionRemoved"] = "DivisionRemoved";
    TournamentDetailPageActions["CancelRemoveDivision"] = "CancelRemoveDivision";
    TournamentDetailPageActions["SignUpEntryFormsBuilt"] = "SignUpEntryFormsBuilt";
    TournamentDetailPageActions["ActualWeightSubmitted"] = "ActualWeightSubmitted";
    TournamentDetailPageActions["SubmittingActualWeight"] = "SubmittingActualWeight";
    TournamentDetailPageActions["ErrorSubmittingActualWeight"] = "ErrorSubmittingActualWeight";
})(TournamentDetailPageActions || (TournamentDetailPageActions = {}));
exports.default = TournamentDetailPageActions;
