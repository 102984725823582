"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Config = /** @class */ (function () {
    function Config() {
    }
    Object.defineProperty(Config.prototype, "ApiBaseUrl", {
        get: function () {
            return globalConfig.apiBaseUrl;
        },
        enumerable: false,
        configurable: true
    });
    return Config;
}());
exports.default = Config;
