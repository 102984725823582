"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FieldActions;
(function (FieldActions) {
    FieldActions["Blured"] = "Blured";
    FieldActions["Focus"] = "Focus";
    FieldActions["Disabled"] = "Disabled";
    FieldActions["Enabled"] = "Enabled";
    FieldActions["ValueChanged"] = "ValueChanged";
    FieldActions["Shown"] = "Shown";
    FieldActions["RequiredStatusChanged"] = "RequiredStatusChanged";
    FieldActions["Hidden"] = "Hidden";
    FieldActions["Valid"] = "Valid";
    FieldActions["Invalid"] = "Invalid";
})(FieldActions || (FieldActions = {}));
exports.default = FieldActions;
