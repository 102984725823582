"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var endpointUrls_1 = require("../api/endpointUrls");
var observationProvider_1 = require("../utils/observationProvider");
var currentParentAccountManagerActions_1 = require("./currentParentAccountManagerActions");
var CurrentParentAccountManager = /** @class */ (function () {
    function CurrentParentAccountManager(api, localStoragePlugin) {
        this.api = api;
        this.localStoragePlugin = localStoragePlugin;
        this.observationProvider = new observationProvider_1.default();
        this.accountAccountCode = this.localStoragePlugin.getCurrentAccountCode();
        if (this.accountAccountCode) {
            this.loadAccountToken(this.accountAccountCode);
        }
    }
    Object.defineProperty(CurrentParentAccountManager.prototype, "isSubmitting", {
        get: function () {
            return this._isSubmitting;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CurrentParentAccountManager.prototype, "errorMessage", {
        get: function () {
            return this._errorMessage;
        },
        enumerable: false,
        configurable: true
    });
    CurrentParentAccountManager.prototype.loadAccountToken = function (accountAccessCode) {
        var _this = this;
        this._isSubmitting = true;
        this.observationProvider.notifyObservers(currentParentAccountManagerActions_1.default.ParentAccountLoading);
        this.api.get(endpointUrls_1.default.Registrations + "/AccountToken/" + accountAccessCode)
            .then(function (accountTokenDto) {
            _this.accountTokenDto = accountTokenDto;
            _this.localStoragePlugin.setCurrentAccountCode(accountAccessCode);
            _this._errorMessage = '';
            _this._isSubmitting = false;
            _this.observationProvider.notifyObservers(currentParentAccountManagerActions_1.default.ParentAccountLoaded);
        })
            .catch(function () {
            _this._errorMessage = 'We were unable to locate your account. Make sure your access code is correct.';
            _this.localStoragePlugin.clearCurrentAccountCode();
            _this._isSubmitting = false;
            _this.observationProvider.notifyObservers(currentParentAccountManagerActions_1.default.FailedToLoadParentAccount);
        });
    };
    CurrentParentAccountManager.prototype.clearAccountToken = function () {
        this.accountTokenDto = null;
        this.localStoragePlugin.clearCurrentAccountCode();
        this.observationProvider.notifyObservers(currentParentAccountManagerActions_1.default.ParentAccountCleared);
    };
    Object.defineProperty(CurrentParentAccountManager.prototype, "parentAccount", {
        get: function () {
            if (!this.accountTokenDto) {
                return null;
            }
            return this.accountTokenDto;
        },
        enumerable: false,
        configurable: true
    });
    return CurrentParentAccountManager;
}());
exports.default = CurrentParentAccountManager;
