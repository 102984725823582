"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var RegistrationSuccessPage = /** @class */ (function (_super) {
    __extends(RegistrationSuccessPage, _super);
    function RegistrationSuccessPage(api, navigator) {
        var _this = _super.call(this, __1.PageTypes.RegistrationSuccess, 'Registration Success') || this;
        _this.api = api;
        _this.navigator = navigator;
        return _this;
    }
    return RegistrationSuccessPage;
}(__1.BasePage));
exports.default = RegistrationSuccessPage;
