"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BasePage = /** @class */ (function () {
    function BasePage(pageType, title) {
        this.pageType = pageType;
        this.title = title;
    }
    return BasePage;
}());
exports.default = BasePage;
