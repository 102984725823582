"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TournamentSignUpPageActions;
(function (TournamentSignUpPageActions) {
    TournamentSignUpPageActions["StateChanged"] = "StateChanged";
    TournamentSignUpPageActions["TournamentLoading"] = "TournamentLoading";
    TournamentSignUpPageActions["TournamentLoaded"] = "TournamentLoaded";
    TournamentSignUpPageActions["SignUpSubmitting"] = "SignUpSubmitting";
    TournamentSignUpPageActions["SignUpSubmitted"] = "SignUpSubmitted";
    TournamentSignUpPageActions["SignUpSubmissionFailed"] = "SignUpSubmissionFailed";
    TournamentSignUpPageActions["PayingFromAccount"] = "PayingFromAccount";
    TournamentSignUpPageActions["PayingFromAccountFailed"] = "PayingFromAccountFailed";
    TournamentSignUpPageActions["PaidFromAccount"] = "PaidFromAccount";
})(TournamentSignUpPageActions || (TournamentSignUpPageActions = {}));
exports.default = TournamentSignUpPageActions;
