import { Table } from 'core.frontend';
import * as React from 'react';
import ObservingComponent from './ObservingComponent';
import TablePager from './TablePager';
import TableRecordCount from './TableRecordCount';

interface TableFooterProps {
    table: Table<{id: number}>;
}

class TableFooter extends ObservingComponent<TableFooterProps> {

    public componentWillMount(): void {
        const { table } = this.props;

        this.registerUpdateObserver(table.observationProvider);
    }

    public render(): JSX.Element {
        const { table } = this.props;

        return (
            <div className="table-footer">
                <TableRecordCount
                    isLoading={table.isLoading}
                    recordsShown={table.items.length}
                    totalRecords={table.totalRecordCount}
                />

                {table.pagingEnabled && (
                    <TablePager
                        isLoading={table.isLoading}
                        isNextPageAvailable={table.isNextPageAvailable}
                        currentPage={table.currentPage}
                        isPreviousPageAvailable={table.isPreviousPageAvailable}
                        pageNumberOptions={table.getPageButtonOptions(7)}
                        onGoToPage={table.goToPage}
                        onPrevPageClicked={table.goToPreviousPage}
                        onNextPageClicked={table.goToNextPage}
                    />
                )}
            </div>
        );
    }
}

export default TableFooter;
