import { LedgerEntryDto } from 'core.frontend';
import * as React from 'react';
import AccountBalance from '../../misc/components/AccountBalance';
import FormattedDate from '../../misc/components/FormattedDate';
import Table from '../../misc/components/Table';
import TableColumn from '../../misc/TableColumn';

interface LedgerEntriesListProps {
    ledgerEntries: LedgerEntryDto[];
    isAddingNew: boolean;
}

class LedgerEntriesList extends React.PureComponent<LedgerEntriesListProps> {

    private getMemoLine = (ledgerEntry: LedgerEntryDto ) : JSX.Element => {
        if (!ledgerEntry.paymentMethod) {
            return <span>{ledgerEntry.memo}</span>;
        } else if (ledgerEntry.paymentMethod.toLowerCase() === 'paypal') {
            return this.getPaymentDetailsPayPalLink(ledgerEntry);
        } else {
            return this.getPaymentDetailsString(ledgerEntry);
        }
    }

    private getPaymentDetailsString = (ledgerEntry: LedgerEntryDto) : JSX.Element => {
        let paymentDetails: string = ' ';

        if (ledgerEntry.paymentMethod) {
            paymentDetails += ledgerEntry.paymentMethod;
        }

        if (ledgerEntry.paymentMethod && ledgerEntry.paymentTransactionId) {
            paymentDetails += ' - ';
        }

        if (ledgerEntry.paymentTransactionId) {
            paymentDetails += ledgerEntry.paymentTransactionId;
        }

        return <span>{paymentDetails}</span>;
    }

    private getPaymentDetailsPayPalLink = (ledgerEntry: LedgerEntryDto) : JSX.Element => {
        return (
            <a
                className="paypal-ledger-entry-link"
                href={'https://www.paypal.com/activity/payment/' + ledgerEntry.paymentTransactionId}
                target="_blank">

                    <img
                        src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png"
                        alt="PayPal Logo" />

                    {ledgerEntry.paymentTransactionId}
            </a>
        );
    }

    private buildColumns = (): TableColumn<LedgerEntryDto>[] => {
        return [
            {
                heading: 'Date/Time',
                cellTemplate: (ledgerEntry: LedgerEntryDto) =>
                    <FormattedDate date={ledgerEntry.createdDateTime} />
            }, {
                heading: 'Admin User',
                className: 'dt-only',
                cellTemplate: (ledgerEntry: LedgerEntryDto) => <span>{ledgerEntry.usersFullName}</span>
            }, {
                heading: 'Memo',
                cellTemplate: (ledgerEntry: LedgerEntryDto) => this.getMemoLine(ledgerEntry)
            },
            {
                heading: 'Amount',
                cellTemplate: (ledgerEntry: LedgerEntryDto) => (
                                    ledgerEntry.credit > 0 ?
                                        <AccountBalance balance={ledgerEntry.credit} /> :
                                        <AccountBalance balance={-ledgerEntry.debit} />)
            }
        ];
    }

    public render(): JSX.Element {
        const { ledgerEntries } = this.props;

        return (
            <Table
                items={ledgerEntries}
                columns={ this.buildColumns() } />
        );
    }
}

export default LedgerEntriesList;
