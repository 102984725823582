"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var endpointUrls_1 = require("../../api/endpointUrls");
var components_1 = require("../../components");
var observationProvider_1 = require("../../utils/observationProvider");
var parentAccountDetailPageActions_1 = require("./parentAccountDetailPageActions");
var ParentAccountDetailPage = /** @class */ (function (_super) {
    __extends(ParentAccountDetailPage, _super);
    function ParentAccountDetailPage(api, navigator, registrationId) {
        var _this = _super.call(this, __1.PageTypes.ParentAccountDetail, 'Account Detail') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.registrationId = registrationId;
        _this.backToParentAccounts = function () {
            _this.navigator.goToParentAccounts();
        };
        _this.loadRegistration = function (registrationId) {
            _this.observationProvider.notifyObservers(parentAccountDetailPageActions_1.default.RecordLoading);
            _this.api.getById(endpointUrls_1.default.Registrations, registrationId)
                .then(function (parentAccountDetail) {
                _this._parentAccountDetailDto = parentAccountDetail;
                _this.buildAccountBalanceAdjustment();
                _this.observationProvider.notifyObservers(parentAccountDetailPageActions_1.default.RecordLoaded, parentAccountDetail);
            })
                .catch(function () {
                _this.observationProvider.notifyObservers(parentAccountDetailPageActions_1.default.ErrorLoading);
            });
        };
        _this.onBalanceAdjustmentSaved = function (ledgerEntryDto) {
            _this._parentAccountDetailDto.ledgerEntries.unshift(ledgerEntryDto);
            _this.observationProvider.notifyObservers(parentAccountDetailPageActions_1.default.LedgerEntryAdded);
        };
        _this.buildAccountBalanceAdjustment = function () {
            _this._parentAccountBalanceAdjustment = new components_1.ParentAccountBalanceAdjustment(_this.api, _this._parentAccountDetailDto.balance, _this._parentAccountDetailDto.id);
            _this._parentAccountBalanceAdjustment.observationProvider.observe(_this.updateBalance, components_1.ParentAccountBalanceAdjustmentActions.Saving);
            _this._parentAccountBalanceAdjustment.observationProvider.observe(_this.onBalanceAdjustmentSaved, components_1.ParentAccountBalanceAdjustmentActions.Saved);
        };
        _this.updateBalance = function (balance) {
            _this._parentAccountDetailDto.balance = balance;
            _this.observationProvider.notifyObservers(parentAccountDetailPageActions_1.default.BalancedChanged);
        };
        _this.observationProvider = new observationProvider_1.default();
        if (!registrationId) {
            navigator.goToParentAccounts();
        }
        _this.loadRegistration(registrationId);
        return _this;
    }
    Object.defineProperty(ParentAccountDetailPage.prototype, "lastBalanceActivityDate", {
        get: function () {
            if (this._parentAccountDetailDto.ledgerEntries.length) {
                return this._parentAccountDetailDto.ledgerEntries[0].createdDateTime;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ParentAccountDetailPage.prototype, "balanceAdjustmentForm", {
        get: function () {
            return this._parentAccountBalanceAdjustment;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ParentAccountDetailPage.prototype, "accountDetail", {
        get: function () {
            return this._parentAccountDetailDto;
        },
        enumerable: false,
        configurable: true
    });
    return ParentAccountDetailPage;
}(__1.BasePage));
exports.default = ParentAccountDetailPage;
