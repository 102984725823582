
import { ActionUtils, BaseDropdownField as FrontEndField } from 'core.frontend';
import FieldActions from 'core.frontend/dist/components/fieldActions';
import * as React from 'react';
import ObservingComponent from '../ObservingComponent';

export interface DropdownFieldProps {
    field: FrontEndField<any>;
    labelClassName: string;
    className?: string;
}

class DropdownField extends ObservingComponent<DropdownFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public valueChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.setValue(newValue);
    }

    private buildOptions = (): JSX.Element[] => {
        const { field } = this.props;

        const options: JSX.Element[] = [];
        options.push(<option
                            key={0}
                            value=""
                            disabled
                            selected>
                        </option>);

        let i: number = 1;
        for (const option of field.options) {
            options.push(
                <option
                    key={i++}
                    value={option.value ? option.value.toString() : null}>

                    {option.label}
                </option>
            );
        }

        return options;
    }

    public render(): JSX.Element {
        const { field, labelClassName, className } = this.props;

        return (
            <div className={className +
                            (field.isActive ? ' select-wrapper active' : ' select-wrapper')}>

                <label className={labelClassName}
                        htmlFor={field.key}>

                    {field.label}
                </label>

                <select
                    id={field.key}
                    name={field.key}
                    onFocus={field.focus}
                    onBlur={field.blur}
                    value={field.value}
                    onChange={this.valueChanged}
                    className={field.errorMessage ? 'invalid' : ''}
                    disabled={field.isDisabled} >

                    { this.buildOptions() }

                </select>

                { field.errorMessage && (
                    <span className="invalid error-message">{field.errorMessage}</span>
                )}
            </div>
        );
    }
}

export default DropdownField;
