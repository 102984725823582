import { TournamentDetailPage, TournamentDetailPageActions, TournamentSignUpEntryTableItem } from 'core.frontend';
import * as React from 'react';
import ObservingComponent from '../../misc/components/ObservingComponent';
import SmartTable from '../../misc/components/smartTable';
import SmartTableColumn from '../../misc/smartTableColumn';
import Field from '../../misc/components/fields/Field';

interface TournamentDetailProps {
    page: TournamentDetailPage;
}

class TournamentEntries extends ObservingComponent<TournamentDetailProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, [TournamentDetailPageActions.ErrorSubmittingActualWeight]);
    }

    private buildColumns = (): SmartTableColumn<TournamentSignUpEntryTableItem>[] => {
        const { page } = this.props;

        return [
            {
                heading: 'Name',
                sortExpression: 'a.lastName,a.firstName',
                cellTemplate: (item) => <span>{item.athlete}</span>,
                appendSavingIndicator: true
            }, {
                heading: 'Division',
                sortExpression: 'd.name',
                cellTemplate: (item) => <span>{item.division}</span>
            }, {
                heading: 'Weight class',
                sortExpression: 'signUpAthlete.weightClass',
                className: 'text-center',
                cellTemplate: (item) => <span>{item.weightClass}</span>
            }, {
                heading: 'Actual',
                className: 'text-center fit-content',
                cellTemplate: (item, index) => (
                    <span>
                        { page.errorWeightSubmissionIds.indexOf(item.tournamentAthleteSignUpId) !== -1 &&
                            <span className="red">Error </span>
                        }

                        <Field
                            className="table-input-field text-center no-label no-error-message input-xs"
                            field={this.props.page.signUpEntryForms[index].fields[0]} />
                    </span>
                )
            }
        ];
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <SmartTable
                table={page.signUpEntryTable}
                columns={this.buildColumns()} />
        );
    }
}

export default TournamentEntries;
