"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserDetailPageActions;
(function (UserDetailPageActions) {
    UserDetailPageActions["RecordDeleting"] = "RecordDeleting";
    UserDetailPageActions["RecordLoading"] = "RecordLoading";
    UserDetailPageActions["RecordLoaded"] = "RecordLoaded";
    UserDetailPageActions["RecordLoadFailure"] = "RecordLoadFailure";
    UserDetailPageActions["Cancel"] = "Cancel";
})(UserDetailPageActions || (UserDetailPageActions = {}));
exports.default = UserDetailPageActions;
