"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var debounce_1 = require("../utils/debounce");
var observationProvider_1 = require("../utils/observationProvider");
var tableActions_1 = require("./tableActions");
var Table = /** @class */ (function () {
    /*
        Allow for custom data loading function to be passed in
        or an endpoint and api to be used to load data based on
        default standard for fetching table data from api
    */
    function Table(param1, api, onExport) {
        var _this = this;
        this.onExport = onExport;
        this.getData = function () {
            _this._isLoading = true;
            _this.observationProvider.notifyObservers(tableActions_1.default.Loading);
            return _this.callApi()
                .then(function (response) {
                _this._totalRecordCount = response.totalRecordCount;
                _this.data = response.items;
                _this.finishedGettingData();
                _this._isErrorState = false;
                _this.observationProvider.notifyObservers(tableActions_1.default.Loaded);
            })
                .catch(function (err) {
                _this.finishedGettingData();
                _this._isErrorState = true;
                _this.observationProvider.notifyObservers(tableActions_1.default.ErrorLoading);
            });
        };
        this.finishedGettingData = function () {
            _this._isLoading = false;
            _this._savingIndices = [];
            _this._deletingIndices = [];
        };
        this.setPagingEnabled = function (isEnabled) {
            _this._pagingEnabled = isEnabled;
        };
        this.buildSortExpression = function () {
            if (!_this.sortBy || !_this.isSortDescending) {
                return _this.sortBy;
            }
            return _this.sortBy.replace(/,/g, ' desc,') + ' desc';
        };
        this.load = function () {
            return _this.getData();
        };
        this.sort = function (sortBy) {
            if (sortBy === _this.sortBy) {
                _this.isSortDescending = !_this.isSortDescending;
            }
            _this.sortBy = sortBy;
            _this.observationProvider.notifyObservers(tableActions_1.default.SortChanged);
            return _this.getData();
        };
        this.isSortedAscendingBy = function (testExpression) {
            return testExpression === _this.sortExpression;
        };
        this.isSortedDescendingBy = function (testExpression) {
            return testExpression === _this.sortExpression.replace(new RegExp(' desc', 'g'), '');
        };
        this.search = function (searchString, enableDebounce) {
            if (enableDebounce === void 0) { enableDebounce = true; }
            _this.offset = 0;
            _this._searchPhrase = searchString;
            if (!enableDebounce) {
                _this.getData();
            }
            else {
                _this.debouncedGetData();
            }
            _this.observationProvider.notifyObservers(tableActions_1.default.SearchPhraseChanged);
        };
        this.getPageButtonOptions = function (maxButtonCount) {
            if (!_this.totalRecordCount) {
                return [];
            }
            else if (_this.pageCount === 1) {
                return [1];
            }
            var options = [_this.currentPage];
            var reachedLastPage;
            var reachedFirstPage;
            var isAscending;
            for (var i = 1; i < maxButtonCount; i++) {
                if (i % 2 !== 0) {
                    isAscending = true;
                }
                else {
                    isAscending = false;
                }
                if (i !== 1 && options[0] === 1) {
                    reachedFirstPage = true;
                }
                if (options[options.length - 1] === _this.pageCount) {
                    reachedLastPage = true;
                }
                if (!reachedFirstPage && (!isAscending || reachedLastPage)) {
                    options.unshift(options[0] - 1);
                }
                else if (!reachedLastPage && (isAscending || reachedFirstPage)) {
                    options.push(options[options.length - 1] + 1);
                }
            }
            return options;
        };
        this.goToPage = function (pageNumber) {
            if (_this._totalRecordCount === undefined) {
                throw new Error('load must be called before paging data');
            }
            if (pageNumber < 1) {
                _this.offset = 0;
            }
            else if (pageNumber > _this.pageCount) {
                _this.offset = (_this.pageCount - 1) * _this._recordsPerPage;
            }
            else {
                _this.offset = (pageNumber - 1) * _this._recordsPerPage;
            }
            return _this.getData();
        };
        this.setRecordsPerPage = function (recordsPerPage) {
            _this._recordsPerPage = recordsPerPage;
            _this.offset = 0;
            _this.observationProvider.notifyObservers(tableActions_1.default.RecordsPerPageChanged);
            return _this.load();
        };
        this.goToNextPage = function () {
            return _this.goToPage(_this.currentPage + 1);
        };
        this.goToPreviousPage = function () {
            return _this.goToPage(_this.currentPage - 1);
        };
        this.insertAddingRecord = function (addingRecord) {
            _this._totalRecordCount++;
            _this.data = __spreadArrays([addingRecord], _this.data);
            _this._savingIndices.push(0);
            _this.observationProvider.notifyObservers(tableActions_1.default.AddingRecord);
        };
        this.recordAddedSuccessfully = function () {
            _this._savingIndices = _this._savingIndices.filter(function (index) { return index !== 0; });
            _this.observationProvider.notifyObservers(tableActions_1.default.AddedRecord);
        };
        this.isIndexSaving = function (index) {
            return _this._savingIndices.filter(function (item) { return item === index; }).length > 0;
        };
        this.markRecordAsSaving = function (id) {
            var savingRecordIndex = _this.getIndexById(id);
            _this._savingIndices.push(savingRecordIndex);
            _this.observationProvider.notifyObservers(tableActions_1.default.RecordSaving);
        };
        this.markRecordAsNotSaving = function (id) {
            var recordIndex = _this.getIndexById(id);
            _this._savingIndices = _this._savingIndices.filter(function (index) { return index !== recordIndex; });
            _this.observationProvider.notifyObservers(tableActions_1.default.RecordSaved);
        };
        this.isIndexDeleting = function (index) {
            return _this._deletingIndices.filter(function (item) { return item === index; }).length > 0;
        };
        this.markRecordAsDeleting = function (id) {
            var recordIndex = _this.getIndexById(id);
            _this._deletingIndices.push(recordIndex);
            _this.observationProvider.notifyObservers(tableActions_1.default.RecordDeleting);
        };
        this.getIndexById = function (id) {
            return _this.data.findIndex(function (item) { return item.id === id; });
        };
        if (typeof param1 === 'function') {
            this.callApi = param1;
        }
        else {
            var endpointUrl_1 = param1;
            this.callApi = function () { return api.getTablePage(endpointUrl_1, _this._recordsPerPage, _this.currentPage, _this.buildSortExpression(), _this._searchPhrase); };
        }
        this._recordsPerPage = 10;
        this.offset = 0;
        this.sortBy = '';
        this._searchPhrase = '';
        this.data = [];
        this.isSortDescending = false;
        this._isLoading = false;
        this._savingIndices = [];
        this._deletingIndices = [];
        this.debouncedGetData = debounce_1.debounce(this.getData.bind(this), 400);
        this.observationProvider = new observationProvider_1.default();
        this._pagingEnabled = true;
    }
    Object.defineProperty(Table.prototype, "pagingEnabled", {
        get: function () {
            return this._pagingEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isErrorState", {
        get: function () {
            return this._isErrorState;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isNoRecordFoundState", {
        get: function () {
            return !this._isLoading &&
                this.searchPhrase.length !== 0 &&
                this._totalRecordCount === 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isEmptyState", {
        get: function () {
            return !this._isLoading &&
                !this.searchPhrase &&
                this._totalRecordCount === 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "savingIndices", {
        get: function () {
            return this._savingIndices;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "totalRecordCount", {
        get: function () {
            return this._totalRecordCount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "currentPage", {
        get: function () {
            return (this.offset / this._recordsPerPage) + 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "sortExpression", {
        get: function () {
            return this.buildSortExpression();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "searchPhrase", {
        get: function () {
            return this._searchPhrase;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "pageCount", {
        get: function () {
            return Math.ceil(this._totalRecordCount / this._recordsPerPage);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "items", {
        get: function () {
            return this.data;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isPreviousPageAvailable", {
        get: function () {
            return this.currentPage > 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "isNextPageAvailable", {
        get: function () {
            return this.currentPage < this.pageCount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "recordsPerPage", {
        get: function () {
            return this._recordsPerPage;
        },
        enumerable: false,
        configurable: true
    });
    return Table;
}());
exports.default = Table;
