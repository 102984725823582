import { AthleteDto } from 'core.frontend';
import * as React from 'react';
import Table from '../../misc/components/Table';

interface AthleteInfoProps {
    athlete: AthleteDto;
}

class AthleteInfo extends React.PureComponent<AthleteInfoProps> {

    public render(): JSX.Element {
        const { athlete } = this.props;

        const items = [
            {
                label: 'Address',
                value: <span>{`${athlete.address} ${athlete.aptNumber}`} <br/> {`${athlete.city}, ${athlete.state} ${athlete.zip}`}</span>
            },
            {
                label: 'Birth date',
                value: athlete.dateOfBirth
            },
            {
                label: 'School',
                value: athlete.school
            },
            {
                label: 'Grade',
                value: athlete.grade
            },
            {
                label: 'Allergies',
                value: athlete.allergies
            },
            {
                label: 'Medical Conditions',
                value: athlete.medicalConditions
            }
        ];

        return (
            <div className="form-container">
                <h6>{athlete.firstName} {athlete.middleName} {athlete.lastName}</h6>

                <hr/>

                <div className="icon-badge-row">
                    <div className="icon-badge">
                        <i className={athlete.gender === 'Male' ? 'fa fa-male' : 'fa fa-female'}></i>
                        <span>{athlete.gender}</span>
                    </div>

                    <div className="icon-badge">
                        <i className="fa fa-tshirt"></i>
                        <span>{athlete.shirtSize}</span>
                    </div>
                </div>

                <Table
                    hideHeading={true}
                    columns={[{
                            heading: '',
                            cellTemplate: (item) => item.label
                        }, {
                            heading: '',
                            cellTemplate: (item) => item.value
                        }]}
                    items={items} />
            </div>
        );
    }
}

export default AthleteInfo;
