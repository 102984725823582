"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var endpointUrls_1 = require("../../api/endpointUrls");
var components_1 = require("../../components");
var submittableForm_1 = require("../../components/submittableForm");
var observationProvider_1 = require("../../utils/observationProvider");
var userDetailPageActions_1 = require("./userDetailPageActions");
var confirmPasswordField_1 = require("../../components/confirmPasswordField");
var UserDetailPage = /** @class */ (function (_super) {
    __extends(UserDetailPage, _super);
    function UserDetailPage(api, navigator, userId) {
        var _this = _super.call(this, __1.PageTypes.UserDetail, 'New User') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.userId = userId;
        _this.loadUser = function (userId) {
            _this._isLoading = true;
            _this.observationProvider.notifyObservers(userDetailPageActions_1.default.RecordLoading);
            _this.api.getById(endpointUrls_1.default.Users, userId)
                .then(function (user) {
                _this.form.setModel(user);
                _this._isLoading = false;
                _this.observationProvider.notifyObservers(userDetailPageActions_1.default.RecordLoaded);
            })
                .catch(function () {
                _this._isLoading = false;
                _this.observationProvider.notifyObservers(userDetailPageActions_1.default.RecordLoadFailure, 'Unable to load this information');
            });
            _this.navigator.goToUsers();
        };
        _this.cancel = function () {
            _this.observationProvider.notifyObservers(userDetailPageActions_1.default.Cancel);
            _this.navigator.goToUsers();
        };
        _this.backToUsers = function () {
            _this.navigator.goToUsers();
        };
        _this.delete = function () {
            if (!_this.isDeletable) {
                throw new Error('Cannot delete because userId is not specified');
            }
            _this.observationProvider.notifyObservers(userDetailPageActions_1.default.RecordDeleting, _this.form.model);
            _this.api.delete(endpointUrls_1.default.Users, _this.userId);
            _this.navigator.goToUsers();
        };
        _this.observationProvider = new observationProvider_1.default();
        _this.buildForm();
        if (userId) {
            _this.loadUser(userId);
            _this.title = 'Edit User';
        }
        return _this;
    }
    Object.defineProperty(UserDetailPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserDetailPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    UserDetailPage.prototype.buildForm = function () {
        var passwordField = new components_1.PasswordField('password', !this.userId);
        var fields = [
            new components_1.TextField('firstName', 'First Name', true, 50, 'First name is required'),
            new components_1.TextField('lastName', 'Last Name', true, 50, 'Last name is required'),
            new components_1.EmailField('email', true),
            passwordField,
            new confirmPasswordField_1.default('confirmPassword', !this.userId, passwordField)
        ];
        this._form = new submittableForm_1.default(endpointUrls_1.default.Users, this.api, fields);
    };
    Object.defineProperty(UserDetailPage.prototype, "isDeletable", {
        get: function () {
            return !!this.userId;
        },
        enumerable: false,
        configurable: true
    });
    return UserDetailPage;
}(__1.BasePage));
exports.default = UserDetailPage;
