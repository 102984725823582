import { App } from 'core.frontend';
import ApiPlugin from '../plugins/apiPlugin';
import LocalStoragePlugin from '../plugins/localStoragePlugin';
import Router from './router';

class ReactApp {
    private router: Router;

    constructor(){
        this.app = new App(new ApiPlugin(), new LocalStoragePlugin());
        this.router = new Router(this.app);
    }

    public app: App;

    public getCurrentPageComponent(): JSX.Element {
        return this.router.getCurrentPageComponent();
    }
}

export default ReactApp;
