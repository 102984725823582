import { ActionUtils, TextField as FrontEndField } from 'core.frontend';
import FieldActions from 'core.frontend/dist/components/fieldActions';
import * as React from 'react';
import ObservingComponent from '../ObservingComponent';

export interface PasswordFieldProps {
    field: FrontEndField;
    labelClassName: string;
    className?: string;
}

class PasswordField extends ObservingComponent<PasswordFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider, ActionUtils.actionsToArray(FieldActions, [FieldActions.Hidden, FieldActions.Shown]));
    }

    public valueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { field } = this.props;
        const newValue = e.target.value;

        field.setValue(newValue);
    }

    public render(): JSX.Element {
        const { field, labelClassName, className } = this.props;

        return (
            <div className={className + ' input-field'}>

                <label className={labelClassName}
                        htmlFor={field.key}>

                    {field.label}
                </label>

                <input
                    type="password"
                    id={field.key}
                    name={field.key}
                    onFocus={field.focus}
                    onBlur={field.blur}
                    value={field.value}
                    onChange={this.valueChanged}
                    className={field.errorMessage ? 'invalid' : ''}
                    disabled={field.isDisabled} />

                { field.errorMessage && (
                    <span className="invalid error-message">{field.errorMessage}</span>
                )}
            </div>
        );
    }
}

export default PasswordField;
