"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var fieldActions_1 = require("./fieldActions");
var BaseField = /** @class */ (function () {
    function BaseField(fieldType, key, _label, _isRequired, maxCharacters, _requiredValidationMessage) {
        var _this = this;
        this.fieldType = fieldType;
        this.key = key;
        this._label = _label;
        this._isRequired = _isRequired;
        this.maxCharacters = maxCharacters;
        this._requiredValidationMessage = _requiredValidationMessage;
        this.focus = function () {
            _this._isFocused = true;
            _this.observationProvider.notifyObservers(fieldActions_1.default.Focus);
        };
        this.blur = function () {
            _this._isFocused = false;
            _this.observationProvider.notifyObservers(fieldActions_1.default.Blured);
        };
        this.notifyOfValidityChange = function () {
            if (_this._isValid) {
                _this.observationProvider.notifyObservers(fieldActions_1.default.Valid);
            }
            else {
                _this.observationProvider.notifyObservers(fieldActions_1.default.Invalid);
            }
        };
        BaseField._systemWideFieldCount++;
        this._uniqueKey = this.key + BaseField._systemWideFieldCount;
        this._isValid = null;
        this._isVisible = true;
        this._isDirty = false;
        this.observationProvider = new __1.ObservationProvider();
        if (!this._requiredValidationMessage) {
            this._requiredValidationMessage = this.label + " is required";
        }
        this.setRequiredStatus(this._isRequired, this._requiredValidationMessage);
    }
    Object.defineProperty(BaseField.prototype, "uniqueKey", {
        get: function () {
            return this._uniqueKey;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseField.prototype, "placeholderLabel", {
        get: function () {
            return this._placeholderLabel;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseField.prototype, "requiredValidationMessage", {
        get: function () {
            return this._requiredValidationMessage;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseField.prototype, "isRequired", {
        get: function () {
            return this._isRequired;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseField.prototype, "isVisible", {
        get: function () {
            return this._isVisible;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseField.prototype, "label", {
        get: function () {
            return this._label;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseField.prototype, "isDirty", {
        get: function () {
            return this._isDirty;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseField.prototype, "isActive", {
        get: function () {
            return this._isFocused || !!this._stringValue;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseField.prototype, "stringValue", {
        get: function () {
            if (this._stringValue) {
                return this._stringValue;
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseField.prototype, "isDisabled", {
        get: function () {
            return this._disabled;
        },
        set: function (disabled) {
            this._disabled = disabled;
            if (this._disabled) {
                this.observationProvider.notifyObservers(fieldActions_1.default.Disabled);
            }
            else {
                this.observationProvider.notifyObservers(fieldActions_1.default.Enabled);
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseField.prototype, "isFocused", {
        get: function () {
            return this._isFocused;
        },
        enumerable: false,
        configurable: true
    });
    BaseField.prototype.setPlaceholderLabel = function (labelText) {
        this._placeholderLabel = labelText;
    };
    BaseField.prototype.setStringValue = function (newValue) {
        this._stringValue = newValue;
        this._isDirty = true;
        this.observationProvider.notifyObservers(fieldActions_1.default.ValueChanged);
    };
    BaseField.prototype.setIsValid = function (newIsValid) {
        if (newIsValid === this._isValid) {
            return;
        }
        this._isValid = newIsValid;
        this.notifyOfValidityChange();
    };
    BaseField.prototype.markAsDirty = function () {
        this._isDirty = true;
    };
    BaseField.prototype.setRequiredStatus = function (isRequired, requiredValidationMessage) {
        if (requiredValidationMessage) {
            this._requiredValidationMessage = requiredValidationMessage;
        }
        if (isRequired && !this._label.endsWith('*')) {
            this._label += '*';
        }
        if (isRequired !== this.isRequired) {
            this.observationProvider.notifyObservers(fieldActions_1.default.RequiredStatusChanged);
            this._isRequired = isRequired;
        }
    };
    BaseField.prototype.setVisibility = function (isVisible) {
        if (isVisible === this.isVisible) {
            return;
        }
        this._isVisible = isVisible;
        if (isVisible) {
            this.setRequiredStatus(this._requiredAtTimeOfHiding);
            this.observationProvider.notifyObservers(fieldActions_1.default.Shown);
        }
        else {
            this._requiredAtTimeOfHiding = this._isRequired;
            this.setRequiredStatus(false);
            this.observationProvider.notifyObservers(fieldActions_1.default.Hidden);
        }
    };
    BaseField.prototype.checkRequiredStatus = function () {
        if (!this._isRequired) {
            return true;
        }
        if (this._stringValue === null || this._stringValue === undefined) {
            return false;
        }
        return this._stringValue.length > 0;
    };
    BaseField.prototype.getErrorMessage = function () {
        if (this._isRequired && !this.stringValue && this.isDirty) {
            return this._requiredValidationMessage;
        }
        return '';
    };
    BaseField._systemWideFieldCount = 0;
    return BaseField;
}());
exports.default = BaseField;
