import * as React from 'react';

class SectionHeading extends React.PureComponent<{children: string}> {
    private goToTop() {
        window.scrollTo(0, 0);
    }

    public render(): JSX.Element {
        const { children } = this.props;

        return (
            <h2 className="flex">
                <span className="v-center fill">{children}</span>

                <a
                    className="v-center xsmall text-center flex flex-column"
                    onClick={this.goToTop}>

                    <i className="fa fa-chevron-up"></i>
                    <span className="xxsmall">
                        Top
                    </span>
                </a>
            </h2>
        );
    }
}

export default SectionHeading;
