"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var baseField_1 = require("./baseField");
var MoneyField = /** @class */ (function (_super) {
    __extends(MoneyField, _super);
    function MoneyField(key, label, isRequired, requiredValidationMessage, initialValue) {
        var _this = _super.call(this, _1.FieldType.Money, key, label, isRequired, 10, requiredValidationMessage) || this;
        _this.key = key;
        if (initialValue) {
            _this.setValue(initialValue);
        }
        return _this;
    }
    Object.defineProperty(MoneyField.prototype, "errorMessage", {
        get: function () {
            var baseErrorMessage = _super.prototype.getErrorMessage.call(this);
            if (baseErrorMessage) {
                return baseErrorMessage;
            }
            if (this.value && !this.isValidMoney() && this.isDirty) {
                return 'Amount is invalid';
            }
            return baseErrorMessage;
        },
        enumerable: false,
        configurable: true
    });
    MoneyField.prototype.isValidMoney = function () {
        var regex = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
        return regex.test(this.value.toString());
    };
    MoneyField.prototype.isValid = function () {
        var isValid = true;
        if (!this.checkRequiredStatus()) {
            isValid = false;
        }
        if (this.stringValue && !this.isValidMoney()) {
            isValid = false;
        }
        this.setIsValid(isValid);
        return isValid;
    };
    MoneyField.prototype.setValue = function (newValue) {
        if (newValue === undefined || newValue === null) {
            this.setStringValue('');
        }
        else {
            this.setStringValue(newValue.toString());
        }
    };
    Object.defineProperty(MoneyField.prototype, "value", {
        get: function () {
            if (!this.stringValue) {
                return null;
            }
            return parseFloat(this.stringValue);
        },
        enumerable: false,
        configurable: true
    });
    return MoneyField;
}(baseField_1.default));
exports.default = MoneyField;
