"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var __1 = require("..");
var TimeField = /** @class */ (function (_super) {
    __extends(TimeField, _super);
    function TimeField(key, label, isRequired, requiredValidationMessage, initialValue) {
        var _this = _super.call(this, key, label, isRequired, 5, requiredValidationMessage, initialValue) || this;
        _this.key = key;
        _this.setPlaceholderLabel('hh:mm');
        _this._invalidMessage = "Time is invalid (" + _this.placeholderLabel + ")";
        return _this;
    }
    Object.defineProperty(TimeField.prototype, "errorMessage", {
        get: function () {
            var baseErrorMessage = _super.prototype.getErrorMessage.call(this);
            if (baseErrorMessage) {
                return baseErrorMessage;
            }
            if (this.value && !this.isValidTime() && this.isDirty) {
                return this._invalidMessage;
            }
            return baseErrorMessage;
        },
        enumerable: false,
        configurable: true
    });
    TimeField.prototype.isValidTime = function () {
        return __1.DateUtils.isValidTime(this.stringValue);
    };
    TimeField.prototype.isValid = function () {
        var isValid = true;
        if (!this.checkRequiredStatus()) {
            isValid = false;
        }
        if (this.stringValue && !this.isValidTime()) {
            isValid = false;
        }
        this.setIsValid(isValid);
        return isValid;
    };
    TimeField.prototype.setValue = function (newValue) {
        this.setStringValue(this.formatDatePartial(newValue));
    };
    TimeField.prototype.formatDatePartial = function (dateString) {
        var formattedStr = '';
        for (var i = 0; i < dateString.length; i++) {
            var char = dateString.charAt(i);
            if ('0123456789:'.indexOf(char) !== -1) {
                if (formattedStr.length === 1 && char === ':') {
                    formattedStr = '0' + formattedStr + ':';
                }
                else if (formattedStr.length === 2 && char !== ':') {
                    formattedStr += ':' + char;
                }
                else if (formattedStr.length === 2 && char === ':') {
                    formattedStr += char;
                }
                else if (char !== ':') {
                    formattedStr += char;
                }
            }
        }
        return formattedStr;
    };
    return TimeField;
}(_1.TextField));
exports.default = TimeField;
