
import { FunctionUtils } from 'core.frontend';
import * as React from 'react';
import TableColumn from '../tableColumn';
import ObservingComponent from './ObservingComponent';
import TableLoadingPlaceholderTBody from './TableLoadingPlaceholderTBody';

interface TableProps<T> {
    columns: TableColumn<T>[];
    isLoading?: boolean;
    items: T[];
    loadingRowCount?: number;
    rowClassName?: ((rowIndex: number) => string) | string;
    hideHeading?: boolean;
}

class Table<T> extends ObservingComponent<TableProps<T>> {

    public render(): JSX.Element {
        const { columns, isLoading, items, rowClassName, hideHeading, loadingRowCount} = this.props;

        return (
            <React.Fragment>
                <table className="table table-striped table-hover" >
                    { !hideHeading && (
                        <thead>
                            <tr>
                                {columns.map((column, colIndex) => {
                                    return (
                                        <th
                                            key={colIndex}
                                            className={column.headingClassName + ' ' + column.className}
                                            style={column.width ? {width: column.width} : null}>

                                                <span>{ column.heading }</span>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                    )}
                    {isLoading ? (
                        <TableLoadingPlaceholderTBody
                            columnCount={columns.length}
                            rowCount={loadingRowCount ? loadingRowCount : 3} />
                    ) : (
                        <tbody>
                            { items.map((item, rowIndex) => {
                                return (
                                    <tr
                                        key={rowIndex}
                                        className={FunctionUtils.functionify<number, string>(rowClassName)(rowIndex)}>

                                        {columns.map((column, colIndex) => {
                                            return (
                                                <td
                                                    key={colIndex}
                                                    className={column.cellClassName + ' ' + column.className}
                                                    style={column.width ? {width: column.width} : null}>

                                                    { column.cellTemplate(item, rowIndex) }
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    )}
                </table>
            </React.Fragment>
        );
    }
}

export default Table;
