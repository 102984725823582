import * as React from 'react';

interface EmptyStateProps {
    title?: string;
    message?: string;
}

class EmptyState extends React.PureComponent<EmptyStateProps> {

    public render(): JSX.Element {
        const { title, message } = this.props;

        return (
            <div className="empty-state">
                <i className="fa fa-inbox"/>
                <br/>
                <b>{ title ? title : 'Nothing here'}</b>
                <br/>
                { message ? message : 'There haven\'t been any records created yet'}
            </div>
        );
    }
}

export default EmptyState;
