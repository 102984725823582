"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Api = /** @class */ (function () {
    function Api(apiPlugin, config, currentUserManager) {
        var _this = this;
        this.apiPlugin = apiPlugin;
        this.config = config;
        this.currentUserManager = currentUserManager;
        this.buildListQueryString = function (recordsPerPage, pageNumber, sort, searchPhrase, parameters) {
            if (pageNumber === void 0) { pageNumber = 1; }
            return "?recordsPerPage=" + recordsPerPage
                + ("" + _this.buildPageNumberExpression(pageNumber))
                + ("" + _this.buildSortExpression(sort))
                + ("" + _this.buildSearchExpression(searchPhrase))
                + ("" + _this.buildAdditionalParameterString(parameters));
        };
        this.buildSearchExpression = function (searchPhrase) {
            if (!searchPhrase) {
                return '';
            }
            return "&searchPhrase=" + searchPhrase;
        };
        this.buildSortExpression = function (sort) {
            if (!sort) {
                return '';
            }
            return "&sort=" + sort;
        };
        this.handleError = function (err) {
            if (err.response.status === 401) {
                _this.currentUserManager.logout();
            }
        };
        this.download = function (endpointUrl, filename) {
            _this.apiPlugin.download(_this.baseUrl + endpointUrl, filename, _this.currentUserManager.authenticationToken);
        };
        this.baseUrl = this.config.ApiBaseUrl;
    }
    Api.prototype.getTablePage = function (endpointUrl, recordsPerPage, pageNumber, sort, searchPhrase, parameters) {
        var _this = this;
        if (pageNumber === void 0) { pageNumber = 0; }
        var url = endpointUrl + this.buildListQueryString(recordsPerPage, pageNumber, sort, searchPhrase, parameters);
        return this.apiPlugin.getTablePage(this.baseUrl + url, this.currentUserManager.authenticationToken)
            .catch(function (err) {
            _this.handleError(err);
            throw new Error('Unable to process request');
        });
    };
    Api.prototype.get = function (endpointUrl) {
        return this.apiPlugin.getSingle(this.baseUrl + endpointUrl, this.currentUserManager.authenticationToken);
    };
    Api.prototype.getById = function (endpointUrl, id) {
        var _this = this;
        return this.apiPlugin.getSingle(this.baseUrl + endpointUrl + '\\' + id, this.currentUserManager.authenticationToken)
            .catch(function (err) {
            _this.handleError(err);
            throw new Error('Unable to process request');
        });
    };
    Api.prototype.buildAdditionalParameterString = function (parameters) {
        if (!parameters) {
            return '';
        }
        var paramString = '&';
        for (var key in parameters) {
            if (parameters.hasOwnProperty(key)) {
                paramString += key + "=" + parameters[key] + "&";
            }
        }
        return paramString.substring(0, paramString.length - 1);
    };
    Api.prototype.buildPageNumberExpression = function (pageNumber) {
        if (pageNumber <= 1) {
            return '';
        }
        return "&pageNumber=" + pageNumber;
    };
    Api.prototype.post = function (endpointUrl, payload) {
        var _this = this;
        return this.apiPlugin.post(this.baseUrl + endpointUrl, this.currentUserManager.authenticationToken, payload)
            .catch(function (err) {
            _this.handleError(err);
            throw new Error('Unable to process request');
        });
    };
    Api.prototype.put = function (endpointUrl, id, payload) {
        var _this = this;
        return this.apiPlugin.put("" + this.baseUrl + endpointUrl + "/" + id, this.currentUserManager.authenticationToken, id, payload)
            .catch(function (err) {
            _this.handleError(err);
            throw new Error('Unable to process request');
        });
    };
    Api.prototype.delete = function (endpointUrl, id) {
        var _this = this;
        return this.apiPlugin.delete("" + this.baseUrl + endpointUrl + "/" + id, this.currentUserManager.authenticationToken, id)
            .catch(function (err) {
            _this.handleError(err);
            throw new Error('Unable to process request');
        });
    };
    return Api;
}());
exports.default = Api;
