"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TournamentSignUpDto = exports.TournamentSignUpAthleteDto = exports.TournamentForSignUp = exports.TournamentDivision = void 0;
var tournamentDivision_1 = require("./tournamentDivision");
exports.TournamentDivision = tournamentDivision_1.default;
var tournamentForSignUp_1 = require("./tournamentForSignUp");
exports.TournamentForSignUp = tournamentForSignUp_1.default;
var tournamentSignUpAthleteDto_1 = require("./tournamentSignUpAthleteDto");
exports.TournamentSignUpAthleteDto = tournamentSignUpAthleteDto_1.default;
var tournamentSignUpDto_1 = require("./tournamentSignUpDto");
exports.TournamentSignUpDto = tournamentSignUpDto_1.default;
