import { TournamentSignUpSuccessPage } from 'core.frontend';
import * as React from 'react';
import Button from '../../misc/components/Button';
import ObservingComponent from '../../misc/components/ObservingComponent';

interface TournamentSignUpSuccessProps {
    page: TournamentSignUpSuccessPage;
}

class TournamentSignUpSuccess extends ObservingComponent<TournamentSignUpSuccessProps> {
    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <div className="container top-p-2">
                <div className="text-center content-header-block">
                    <i className="fa fa-check green"></i>
                    <h3>You're all signed up!</h3>
                </div>

                <p>
                    Thank you for using our site to register for your tournaments. Remember to have your wrestlers on the mat, ready to warm up with gear on.
                </p>

                <Button
                    className="btn btn-primary btn-wide-phone"
                    onClick={page.goToHome}
                    text="Back to Home Page"
                    leftIcon="fa-chevron-left"/>
            </div>
        );
    }
}

export default TournamentSignUpSuccess;
