import { ParentAccountDetailDto } from 'core.frontend';
import * as React from 'react';
import GuardianTable from './GuardianTable';
import RegistrationAdditionalInfo from './RegistrationAdditionalInfo';

interface ParentAccountDetailAdditionalInfoTabProps {
    accountDetail: ParentAccountDetailDto;
}

class ParentAccountDetailAdditionalInfoTab extends React.PureComponent<ParentAccountDetailAdditionalInfoTabProps> {

    public render(): JSX.Element {
        const { accountDetail } = this.props;

        return (
            <React.Fragment>
                 <div className="registration-access-code-wrapper">
                    <span>{accountDetail.accessCode}</span>
                    Parent access code
                </div>

                <GuardianTable guardians={accountDetail.guardians} />

                <RegistrationAdditionalInfo registration={accountDetail} />
            </React.Fragment>
        );
    }
}

export default ParentAccountDetailAdditionalInfoTab;
