"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debounce = void 0;
function debounce(fn, ms) {
    var timer;
    var debouncedFunc = function () {
        return new Promise(function (resolve) {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(function () {
                resolve(fn());
            }, ms);
        });
    };
    return debouncedFunc;
}
exports.debounce = debounce;
