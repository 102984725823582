import * as React from 'react';
import TableLoadingPlaceholderRow from './TableLoadingPlaceholderRow';

interface TableLoadingPlaceholderTBodyProps {
    columnCount: number;
    rowCount: number;
}

class TableLoadingPlaceholderTBody extends React.PureComponent<TableLoadingPlaceholderTBodyProps> {

    private getRows = (): JSX.Element[] => {
        const { columnCount, rowCount } = this.props;

        const rows: JSX.Element[] = [];

        for (let i = 0; i < rowCount; i++){
              rows.push(
                <TableLoadingPlaceholderRow
                    key={i}
                    columnCount={columnCount} />
            );
        }

        return rows;
    }

    public render(): JSX.Element {
        return (
            <tbody>
                { this.getRows() }
            </tbody>
        );
    }
}

export default TableLoadingPlaceholderTBody;
