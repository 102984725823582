import { SubmittableFormActions, UserDetailPage, UserDetailPageActions } from 'core.frontend';
import * as React from 'react';
import AnchorButton from '../../misc/components/AnchorButton';
import Button from '../../misc/components/Button';
import Card from '../../misc/components/Card';
import Field from '../../misc/components/fields/Field';
import ObservingComponent from '../../misc/components/ObservingComponent';

interface UserDetailProps {
    page: UserDetailPage;
}

class UserDetail extends ObservingComponent<UserDetailProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, UserDetailPageActions.RecordLoaded);
        this.registerUpdateObserver(page.form.observationProvider, SubmittableFormActions.SubmissionFailure);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <Card
                heading={(
                    <span>
                        <AnchorButton
                            onClick={page.backToUsers}
                            leftIcon="fa-chevron-left"/>

                        {page.title}
                    </span>)}>

                <form noValidate>

                    <div className="field-container">
                        {page.form.fields.map((field) => <Field field={field} isLoading={page.isLoading} />)}
                    </div>

                    <footer>
                        {page.isDeletable && !page.isLoading && (
                            <AnchorButton
                                className="button"
                                onClick={page.delete}
                                leftIcon="fa-trash" />
                        )}

                        <div>
                            <Button
                                type="button"
                                className="btn btn-secondary"
                                onClick={page.cancel}
                                text="Cancel"
                                isLoading={page.isLoading} />

                            <Button
                                type="submit"
                                className="btn btn-primary"
                                onClick={page.form.submit}
                                text="Save"
                                isLoading={page.isLoading} />
                        </div>
                    </footer>

                </form>
            </Card>
        );
    }
}

export default UserDetail;
