import { CurrentUserManagerActions } from 'core.frontend';
import NavigatorActions from 'core.frontend/dist/navigator/navigatorActions';
import * as React from 'react';
import ReactApp from '../reactApp';
import Button from './Button';
import ObservingProvider from './ObservingComponent';

interface NavMenuProps {
    reactApp: ReactApp;
}

class NavMenu extends ObservingProvider<NavMenuProps> {

    public componentWillMount(): void {
        const { reactApp } = this.props;

        this.registerUpdateObserver(reactApp.app.currentUserManager.observationProvider, [CurrentUserManagerActions.LoggedIn, CurrentUserManagerActions.LoggedOut]);
        this.registerUpdateObserver(reactApp.app.navigator.observationProvider, [NavigatorActions.MenuClosed, NavigatorActions.MenuOpened]);
    }

    public render(): JSX.Element {
        const { reactApp } = this.props;
        const userIsLoggedIn = reactApp.app.currentUserManager.isLoggedIn();

        return (
            <React.Fragment>
                <Button
                    className="btn btn-link menu-toggle"
                    onClick={reactApp.app.navigator.toggleMenu}
                    leftIcon="fa-ellipsis-v" />

                <ul className={reactApp.app.navigator.menuOpen ? 'active' : ''}>
                    <li>
                        <Button
                            className="btn btn-link"
                            onClick={reactApp.app.navigator.goToHome}
                            text="Home" />
                    </li>

                    { !userIsLoggedIn && (
                        <li>
                            <Button
                                className="btn btn-link"
                                onClick={reactApp.app.navigator.goToLogin}
                                text="Login" />
                        </li>
                    )}

                    { userIsLoggedIn && (
                        <React.Fragment>
                            <li>
                                <Button
                                    className="btn btn-link"
                                    onClick={reactApp.app.navigator.goToUsers}
                                    text="Admins" />
                            </li>
                            <li>
                                <Button
                                    className="btn btn-link"
                                    onClick={reactApp.app.navigator.goToParentAccounts}
                                    text="Parent Accounts" />
                            </li>
                            <li>
                                <Button
                                    className="btn btn-link"
                                    onClick={reactApp.app.navigator.goToTournaments}
                                    text="Tournaments" />
                            </li>
                            <li>
                                <Button
                                    className="btn btn-link"
                                    onClick={reactApp.app.currentUserManager.logout}
                                    text="Logout" />
                            </li>
                        </React.Fragment>
                    )}
                </ul>
            </React.Fragment>
        );
    }
}

export default NavMenu;
