import { RegistrationActions, RegistrationPage } from 'core.frontend';
import * as React from 'react';
import Button from '../../misc/components/Button';
import Field from '../../misc/components/fields/Field';
import ObservingComponent from '../../misc/components/ObservingComponent';

interface RegistrationGuardianFormContainerProps {
    page: RegistrationPage;
}

class RegistrationGuardianFormContainer extends ObservingComponent<RegistrationGuardianFormContainerProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, [RegistrationActions.GuardianAdded, RegistrationActions.GuardianRemoved, RegistrationActions.SubmissionFailure]);
    }

    private removeGuardian = (index: number) => (): void => {
        const { page } = this.props;

        page.removeGuardian(index);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <div className="form-container">
                <div className="form-container-header">
                    <h4>Guardians</h4>

                    <Button
                        onClick={page.addGuardian}
                        className="btn btn-link"
                        text="+ Add Guardian" />
                </div>

                <p>You can provide up to 3 guardians below. Guardians will receive notifications from the team regarding schedule changes, events, updates and more.</p>

                {page.guardianForms.map((form, index) => {
                    return (
                        <div className={index % 2 === 1 ? 'alt row' : 'row'}>

                            { page.guardianForms.length > 1 && (
                                <div className="col-12">
                                    <div className="form-section-header">
                                        <span>Guardian {index + 1}</span>

                                        <Button
                                            className="btn btn-link"
                                            onClick={this.removeGuardian(index)}
                                            leftIcon="fa-trash"
                                            text="Remove" />
                                    </div>
                                </div>
                            )}

                            {form.fields.map((field) => (
                                <div className="col-12 col-sm-4">
                                    <Field field={field} />
                                </div>
                            ))}

                        </div>
                    );
                })}
            </div>
        );
    }
}

export default RegistrationGuardianFormContainer;
