"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var WeightClassListField = /** @class */ (function (_super) {
    __extends(WeightClassListField, _super);
    function WeightClassListField(key, label, isRequired, maxCharacters, requiredValidationMessage, initialValue) {
        var _this = _super.call(this, key, label, isRequired, maxCharacters, requiredValidationMessage, initialValue, _1.FieldType.TextArea) || this;
        _this.key = key;
        _this._invalidMessage = 'Weight class list is invalid';
        if (initialValue) {
            _this.setValue(initialValue);
        }
        return _this;
    }
    Object.defineProperty(WeightClassListField.prototype, "errorMessage", {
        get: function () {
            var baseErrorMessage = _super.prototype.getErrorMessage.call(this);
            if (baseErrorMessage) {
                return baseErrorMessage;
            }
            if (this.value && !this.isWeightClassListValid() && this.isDirty) {
                return this._invalidMessage;
            }
            return baseErrorMessage;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WeightClassListField.prototype, "value", {
        get: function () {
            return this.stringValue;
        },
        enumerable: false,
        configurable: true
    });
    WeightClassListField.prototype.isValid = function () {
        var isValid = this.checkRequiredStatus();
        isValid = isValid && this.isWeightClassListValid();
        this.setIsValid(isValid);
        return isValid;
    };
    WeightClassListField.prototype.isWeightClassListValid = function () {
        var regex = /^([0-9]{2,3},){1,}([0-9]{2,3}|HWT)$/g;
        return regex.test(this.value.toString());
    };
    WeightClassListField.prototype.setValue = function (newValue) {
        if (!newValue) {
            this.setStringValue(null);
        }
        else {
            this.setStringValue(this.formatFromPartial(newValue));
        }
    };
    WeightClassListField.prototype.formatFromPartial = function (newValueCandidate) {
        var newValue = '';
        newValueCandidate = newValueCandidate.toUpperCase();
        newValueCandidate = newValueCandidate.split('HWT').join('H');
        for (var i = 0; i < newValueCandidate.length; i++) {
            var character = newValueCandidate[i];
            if (i === newValueCandidate.length - 1 && character === 'H') {
                newValue += 'HWT';
            }
            else if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ','].indexOf(character) !== -1) {
                newValue += character;
            }
        }
        return newValue;
    };
    return WeightClassListField;
}(_1.TextField));
exports.default = WeightClassListField;
