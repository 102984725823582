import { HomePage, INavigator, LoginPage, PageTypes, ParentAccountDetailPage, ParentAccountsPage, RegistrationPage, RegistrationSuccessPage, TournamentDetailPage, TournamentSignUpPage, TournamentSignUpSuccessPage, TournamentsPage, UserDetailPage, UsersPage } from 'core.frontend';
import * as React from 'react';
import Home from '../home/Components/Home';
import Login from '../login/Components/Login';
import ParentAccountDetail from '../registration/Components/ParentAccountDetail';
import ParentAccountsList from '../registration/Components/ParentAccountsList';
import Registration from '../registration/Components/Registration';
import RegistrationSuccess from '../registration/Components/RegistrationSuccess';
import TournamentDetail from '../tournaments/Components/TournamentDetail';
import TournamentSignUp from '../tournaments/Components/TournamentSignUp';
import TournamentSignUpSuccess from '../tournaments/Components/TournamentSignUpSuccess';
import TournamentsList from '../tournaments/Components/TournamentsList';
import UserDetail from '../users/Components/UserDetail';
import UserList from '../users/Components/UserList';

export default [
    {
        hash: '/',
        pageType: PageTypes.Home,
        getComponent: (page: HomePage) => <Home page={page as HomePage} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToHome(); },
        buildUrlHash: (page: HomePage) => '/'
    }, {
        hash: '/users',
        pageType: PageTypes.Users,
        getComponent: (page: UsersPage) => <UserList page={page as UsersPage} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToUsers(); },
        buildUrlHash: (page: UsersPage) => '/users'
    }, {
        hash: '/users/',
        pageType: PageTypes.UserDetail,
        getComponent: (page: UserDetailPage) => <UserDetail page={page as UserDetailPage} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const userIdStr = urlHash.replace('#/users/', '');

            if (userIdStr) {
                const userId = parseInt(userIdStr, 10);
                navigator.goToUserDetail(userId);
            } else {
                navigator.goToUserDetail();
            }
        },
        buildUrlHash: (page: UserDetailPage) => {
            if (page.userId) {
                return `/users/${page.userId}`;
            } else {
                return '/users/';
            }
        }
    }, {
        hash: '/registration',
        pageType: PageTypes.Registration,
        getComponent: (page: RegistrationPage) => <Registration page={page as RegistrationPage} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToRegistration(); },
        buildUrlHash: (page: RegistrationPage) => '/registration'
    }, {
        hash: '/registrationSuccess',
        pageType: PageTypes.RegistrationSuccess,
        getComponent: (page: RegistrationSuccessPage) => <RegistrationSuccess />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToRegistrationSuccess(); },
        buildUrlHash: (page: UsersPage) => '/registrationSuccess'
    }, {
        hash: '/login',
        pageType: PageTypes.Login,
        getComponent: (page: LoginPage) => <Login page={page as LoginPage} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToLogin(); },
        buildUrlHash: (page: UsersPage) => '/login'
    }, {
        hash: '/parentAccounts',
        pageType: PageTypes.ParentAccounts,
        getComponent: (page: ParentAccountsPage) => <ParentAccountsList page={page as ParentAccountsPage} />,
        navigateTo: (urlHash: string, navigator: INavigator) => { navigator.goToParentAccounts(); },
        buildUrlHash: (page: ParentAccountsPage) => '/parentAccounts'
    }, {
        hash: '/parentAccounts/',
        pageType: PageTypes.ParentAccountDetail,
        getComponent: (page: ParentAccountDetailPage) => <ParentAccountDetail page={page as ParentAccountDetailPage} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const registrationIdStr = window.location.hash.replace('#/parentAccounts/', '');

            const registrationId = parseInt(registrationIdStr, 10);
            navigator.goToParentAccountDetail(registrationId);
        },
        buildUrlHash: (page: ParentAccountDetailPage) => {
            return `/parentAccounts/${page.registrationId}`;
        }
    }, {
        hash: '/tournaments/signup/',
        pageType: PageTypes.TournamentSignUp,
        getComponent: (page: TournamentSignUpPage) => <TournamentSignUp page={page as TournamentSignUpPage} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const tournamentIdStr = window.location.hash.replace('#/tournaments/signup/', '');

            if (tournamentIdStr) {
                const tournamentId = parseInt(tournamentIdStr, 10);
                navigator.goToTournamentSignUp(tournamentId);
            } else {
                // todo go to tournaments
            }
        },
        buildUrlHash: (page: TournamentSignUpPage) => `/tournaments/signup/${page.tournamentId}`
    }, {
        hash: '/tournaments',
        pageType: PageTypes.Tournaments,
        getComponent: (page) => <TournamentsList page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => navigator.goToTournaments(),
        buildUrlHash: (page: TournamentsPage) => '/tournaments'
    }, {
        hash: '/tournaments/',
        pageType: PageTypes.TournamentDetail,
        getComponent: (page) => <TournamentDetail page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => {
            const idStr = urlHash.replace('#/tournaments/', '');

            if (idStr) {
                const id = parseInt(idStr, 10);
                navigator.goToTournamentDetail(id);
            } else {
                navigator.goToTournamentDetail();
            }
        },
        buildUrlHash: (page: TournamentDetailPage) => {
            if (page.tournamentId) {
                return `/tournaments/${page.tournamentId}`;
            } else {
                return '/tournaments/';
            }
        }
    }, {
        hash: '/tournaments/signup-success',
        pageType: PageTypes.TournamentSignUpSuccess,
        getComponent: (page) => <TournamentSignUpSuccess page={page} />,
        navigateTo: (urlHash: string, navigator: INavigator) => navigator.goToTournamentSignUpSuccess(),
        buildUrlHash: (page: TournamentSignUpSuccessPage) => '/tournaments/signup-success'
    }
];
