import * as React from 'react';

interface PayPalButtonProps {
    buttonId: string;
    registrationId: number;
    tournamentSignUpId?: number;
    tournamentName?: string;
}

class PayPalButton extends React.Component<PayPalButtonProps> {

    private buildCustomField = () => {
        const { registrationId, tournamentSignUpId, tournamentName } = this.props;

        let customFieldValue: string = '';

        if (registrationId) {
            customFieldValue += `registrationId:${registrationId}`;
        }

        if (tournamentSignUpId) {
            customFieldValue += `|tournamentSignUpId:${tournamentSignUpId}`;
        }

        if (tournamentName) {
            customFieldValue += `|tournamentName:${tournamentName}`;
        }

        return customFieldValue;
    }

    public render(): JSX.Element {
        const { buttonId } = this.props;

        return (
            <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_top">

                <input
                    type="hidden"
                    name="cmd"
                    value="_s-xclick" />

                <input
                    type="hidden"
                    name="hosted_button_id"
                    value={buttonId} />

                <input
                    type="hidden"
                    name="custom"
                    value={this.buildCustomField()} />

                <input
                    type="image"
                    src="https://www.paypalobjects.com/en_US/i/btn/btn_paynowCC_LG.gif"
                    name="submit"
                    alt="PayPal - The safer, easier way to pay online!" />

                <img
                    alt=""
                    src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                    width="1"
                    height="1" />
            </form>
        );
    }
}

export default PayPalButton;
