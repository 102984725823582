import * as React from 'react';

interface TableLoadingPlaceholderRowProps {
    columnCount: number;
}

class TableLoadingPlaceholderRow extends React.PureComponent<TableLoadingPlaceholderRowProps> {

    public render(): JSX.Element {
        const { columnCount } = this.props;

        return (
            <tr className="loading">
                <td colSpan={columnCount} style={{height: '3.575rem'}}>
                    <span className="loading-placeholder shimmer"></span>
                </td>
            </tr>
        );
    }
}

export default TableLoadingPlaceholderRow;
