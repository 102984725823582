import * as React from 'react';

interface AccountBalanceProps {
    balance: number;
}

class AccountBalance extends React.PureComponent<AccountBalanceProps> {

    public render(): JSX.Element {
        const { balance } = this.props;

        return (
            <React.Fragment>
                {
                    balance >= 0 ?
                        <span className="account-balance">{Number(balance).toFixed(2)}</span> :
                        <span className="account-balance negative-balance">({Number(-balance).toFixed(2)})</span>
                }
            </React.Fragment>
        );
    }
}

export default AccountBalance;
