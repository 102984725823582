import { RegistrationTokenAthleteDto, TournamentSignUpAthleteForm, TournamentSignUpAthleteFormActions} from 'core.frontend';
import * as React from 'react';
import Field from '../../misc/components/fields/Field';
import ObservingComponent from '../../misc/components/ObservingComponent';

interface TournamentSignUpAtheleteInfoProps {
    athlete: RegistrationTokenAthleteDto;
    athleteForm: TournamentSignUpAthleteForm;
}

class TournamentSignUpAtheleteInfo extends ObservingComponent<TournamentSignUpAtheleteInfoProps> {

    public componentWillMount(): void {
        const { athleteForm } = this.props;

        this.registerUpdateObserver(athleteForm.observationProvider, [TournamentSignUpAthleteFormActions.FormSubmissionFailure, TournamentSignUpAthleteFormActions.DivisionSelected]);
    }

    public render(): JSX.Element {
        const { athlete, athleteForm } = this.props;

        return (
            <div className="tournament-signup-athlete">
                <header>
                    <b>{athlete.name}</b>
                    <Field field={athleteForm.activeField} />
                </header>

                <Field field={athleteForm.divisionField} />
                <Field field={athleteForm.weightClassField} />
            </div>
        );
    }
}

export default TournamentSignUpAtheleteInfo;
