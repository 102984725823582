"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var endpointUrls_1 = require("../api/endpointUrls");
var utils_1 = require("../utils");
var tournamentScheduleActions_1 = require("./tournamentScheduleActions");
var TournamentSchedule = /** @class */ (function () {
    function TournamentSchedule(api, navigator) {
        var _this = this;
        this.api = api;
        this.navigator = navigator;
        this.goToTournamentSignUp = function (tournamentId) {
            _this.navigator.goToTournamentSignUp(tournamentId);
        };
        this.loadSchedule = function () {
            _this._isLoading = true;
            _this.observationProvider.notifyObservers(tournamentScheduleActions_1.default.Loading);
            _this.api.get(endpointUrls_1.default.Tournaments + '/schedule')
                .then(function (schedule) {
                _this._schedule = schedule;
                _this._isLoading = false;
                _this.observationProvider.notifyObservers(tournamentScheduleActions_1.default.Loaded);
            });
        };
        this.observationProvider = new utils_1.ObservationProvider();
        this._schedule = [];
        this.loadSchedule();
    }
    Object.defineProperty(TournamentSchedule.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournamentSchedule.prototype, "schedule", {
        get: function () {
            return this._schedule;
        },
        enumerable: false,
        configurable: true
    });
    return TournamentSchedule;
}());
exports.default = TournamentSchedule;
