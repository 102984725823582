import * as React from 'react';

interface RecordNotFoundStateProps {
    title?: string;
    message?: string;
}

class RecordNotFoundState extends React.PureComponent<RecordNotFoundStateProps> {

    public render(): JSX.Element {
        const { title, message } = this.props;

        return (
            <div className="record-not-found-state">
                <i className="fa fa-search"/>
                <br/>
                <b>{ title ? title : 'Hmmmm....'}</b>
                <br/>
                { message ? message : 'We couldn\'t find anything matching your search.'}
            </div>
        );
    }
}

export default RecordNotFoundState;
