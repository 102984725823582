"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CurrentParentAccountManagerActions;
(function (CurrentParentAccountManagerActions) {
    CurrentParentAccountManagerActions["ParentAccountLoading"] = "ParentAccountLoading";
    CurrentParentAccountManagerActions["ParentAccountLoaded"] = "ParentAccountLoaded";
    CurrentParentAccountManagerActions["FailedToLoadParentAccount"] = "FailedToLoadParentAccount";
    CurrentParentAccountManagerActions["ParentAccountCleared"] = "ParentAccountCleared";
})(CurrentParentAccountManagerActions || (CurrentParentAccountManagerActions = {}));
exports.default = CurrentParentAccountManagerActions;
