import * as React from 'react';
import CardTab from '../cardTab';

interface CardProps {
    heading: string | JSX.Element;
    headingRightContent?: JSX.Element;
    tabs?: CardTab[];
}

interface CardState {
    selectedTabIndex: number;
}

class Card extends React.PureComponent<CardProps, CardState> {

    constructor(props){
        super(props);

        this.state = { selectedTabIndex: 0 };
    }

    private selectTab = (selectedIndex: number) => (event: React.MouseEvent<HTMLAnchorElement>) => {
        this.setState({ selectedTabIndex: selectedIndex });
    }

    private getActiveClass = (index: number): string => {
        const { selectedTabIndex } = this.state;

        return index === selectedTabIndex ? 'active' : '';
    }

    public render(): JSX.Element {
        const { heading, children, headingRightContent, tabs } = this.props;
        const { selectedTabIndex } = this.state;

        return (
            <div className="card">
                <div className="card-title">
                    <h2 className={!headingRightContent ? 'flex-fill' : ''}>{ heading }</h2>

                    { headingRightContent && (
                        <div className="flex-fill text-right">
                            { headingRightContent }
                        </div>
                    )}

                    { tabs && (
                        <div className="card-nav">
                            <ul>
                                { tabs.map((tab, index) => {
                                    return (
                                        <li className={ this.getActiveClass(index) }>
                                            <a onClick={ this.selectTab(index) }>{ tab.heading }</a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
                <div className="card-body">
                    { children }

                    { tabs && tabs.map((tab, index) => {
                        return index === selectedTabIndex && tab.content;
                    })}
                </div>
            </div>
        );
    }
}

export default Card;
