"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var __1 = require("..");
var api_1 = require("../api");
var observationProvider_1 = require("../utils/observationProvider");
var tournamentSignUpAthleteForm_1 = require("./tournamentSignUpAthleteForm");
var TournamentSignUpAthleteFormCollection = /** @class */ (function () {
    function TournamentSignUpAthleteFormCollection(tournament, currentParentAccountManager) {
        var _this = this;
        this.tournament = tournament;
        this.currentParentAccountManager = currentParentAccountManager;
        this.submit = function () {
            var allSignUpsInActive = true;
            var allFormsValid = true;
            for (var _i = 0, _a = _this._forms; _i < _a.length; _i++) {
                var form = _a[_i];
                form.submit();
                allFormsValid = allFormsValid && form.isValid();
                if (form.model.isActive) {
                    allSignUpsInActive = false;
                }
            }
            if (allSignUpsInActive) {
                _this._errorMessage = 'You don\'t have any wrestlers selected to sign-up';
                var submissionFailure = new __1.FormSubmissionFailure(_1.FormSubmissionFailureTypes.InValidForm, _this._errorMessage);
                _this.observationProvider.notifyObservers(__1.FormActions.FormSubmissionFailure, submissionFailure);
            }
            else if (allFormsValid) {
                _this._errorMessage = '';
                _this.observationProvider.notifyObservers(__1.FormActions.FormSubmitted, _this.buildSignUpRequestDto());
            }
            else {
                _this._errorMessage = '';
                var submissionFailure = new __1.FormSubmissionFailure(_1.FormSubmissionFailureTypes.InValidForm, '');
                _this.observationProvider.notifyObservers(__1.FormActions.FormSubmissionFailure, submissionFailure);
            }
        };
        this.buildSignUpRequestDto = function () {
            var requestDto = new api_1.TournamentSignUpDto();
            requestDto.tournamentId = _this.tournament.id;
            requestDto.athletes = [];
            for (var _i = 0, _a = _this._forms; _i < _a.length; _i++) {
                var form = _a[_i];
                if (form.model.isActive) {
                    requestDto.athletes.push(form.model);
                }
            }
            return requestDto;
        };
        this.buildForms = function () {
            _this._forms = [];
            for (var _i = 0, _a = _this.currentParentAccountManager.parentAccount.athletes; _i < _a.length; _i++) {
                var athlete = _a[_i];
                _this._forms.push(new tournamentSignUpAthleteForm_1.default(athlete.id, _this.tournament.divisions));
            }
        };
        this.observationProvider = new observationProvider_1.default();
        this.buildForms();
    }
    Object.defineProperty(TournamentSignUpAthleteFormCollection.prototype, "forms", {
        get: function () {
            return this._forms;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TournamentSignUpAthleteFormCollection.prototype, "errorMessage", {
        get: function () {
            return this._errorMessage;
        },
        enumerable: false,
        configurable: true
    });
    return TournamentSignUpAthleteFormCollection;
}());
exports.default = TournamentSignUpAthleteFormCollection;
