"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RegistrationActions;
(function (RegistrationActions) {
    RegistrationActions["FormSubmission"] = "FormSubmission";
    RegistrationActions["SubmissionFailure"] = "SubmissionFailure";
    RegistrationActions["StateChanged"] = "StateChanged";
    RegistrationActions["AthleteAdded"] = "AthleteAdded";
    RegistrationActions["AthleteRemoved"] = "AthleteRemoved";
    RegistrationActions["GuardianAdded"] = "GuardianAdded";
    RegistrationActions["GuardianRemoved"] = "GuardianRemoved";
})(RegistrationActions || (RegistrationActions = {}));
exports.default = RegistrationActions;
