import { DateFormats, TournamentSchedule as FrontEndTournamentSchedule, TournamentScheduleActions } from 'core.frontend';
import * as React from 'react';
import Button from '../Button';
import FormattedDate from '../FormattedDate';
import ObservingComponent from '../ObservingComponent';
import Table from '../Table';

interface TournamentScheduleProps {
    tournamentSchedule: FrontEndTournamentSchedule;
}

class TournamentSchedule extends ObservingComponent<TournamentScheduleProps> {

    public componentWillMount(): void {
        const { tournamentSchedule } = this.props;

        this.registerUpdateObserver(tournamentSchedule.observationProvider, TournamentScheduleActions.Loaded);
    }

    private goToTournamentSignUp = (tournamentId: number) => () => {
        const { tournamentSchedule } = this.props;

        tournamentSchedule.goToTournamentSignUp(tournamentId);
    }

    public render(): JSX.Element {
        const { tournamentSchedule } = this.props;

        return (
            <Table
                isLoading={tournamentSchedule.isLoading}
                hideHeading={true}
                items={tournamentSchedule.schedule}
                columns={[
                    {
                        cellTemplate: (tournament) => (
                            <React.Fragment>
                               <b>{tournament.name}</b>
                               <span className="phone-only"><br/><FormattedDate date={tournament.date} /></span>
                            </React.Fragment>
                        )
                    }, {
                        cellClassName: 'dt-only',
                        cellTemplate: (tournament) => <FormattedDate date={tournament.date} />
                    }, {
                        width: '10rem',
                        cellClassName: 'text-center',
                        cellTemplate: (tournament) => (
                            <React.Fragment>
                                { tournament.signUpIsOpen && <Button
                                                                className="btn btn-primary"
                                                                onClick={this.goToTournamentSignUp(tournament.id)}
                                                                text="Sign-Up"/> }

                                { tournament.signUpIsExpired && <span>Sign-up closed</span> }

                                { tournament.signUpNotYetActive && <span>Sign-up <br/><b><FormattedDate date={tournament.signUpStartDateTime} format={DateFormats.dddMMMD} /></b></span>}
                            </React.Fragment>
                        )
                    }
                ]} />
        );
    }
}

export default TournamentSchedule;
