"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TournamentSignUpAthleteFormActions;
(function (TournamentSignUpAthleteFormActions) {
    TournamentSignUpAthleteFormActions["ActiveStatusChanged"] = "ActiveStatusChanged";
    TournamentSignUpAthleteFormActions["DivisionSelected"] = "DivisionSelected";
    TournamentSignUpAthleteFormActions["FormSubmitted"] = "FormSubmitted";
    TournamentSignUpAthleteFormActions["FormSubmissionFailure"] = "FormSubmissionFailure";
    TournamentSignUpAthleteFormActions["ModelLoadedToForm"] = "ModelLoadedToForm";
})(TournamentSignUpAthleteFormActions || (TournamentSignUpAthleteFormActions = {}));
exports.default = TournamentSignUpAthleteFormActions;
