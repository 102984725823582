"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ArrayUtils = /** @class */ (function () {
    function ArrayUtils() {
    }
    ArrayUtils.arrayify = function (obj) {
        if (!Array.isArray(obj)) {
            return [obj];
        }
        return obj;
    };
    return ArrayUtils;
}());
exports.default = ArrayUtils;
