import { RegistrationActions, RegistrationPage } from 'core.frontend';
import * as React from 'react';
import Button from '../../misc/components/Button';
import Field from '../../misc/components/fields/Field';
import ObservingComponent from '../../misc/components/ObservingComponent';

interface RegistrationAthleteFormContainerProps {
    page: RegistrationPage;
}

class RegistrationAthleteFormContainer extends ObservingComponent<RegistrationAthleteFormContainerProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, [RegistrationActions.AthleteAdded, RegistrationActions.AthleteRemoved, RegistrationActions.SubmissionFailure]);
    }

    private removeAthlete = (index: number) => (): void => {
        const { page } = this.props;

        page.removeAthlete(index);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <div className="form-container">
                <div className="form-container-header">
                    <h4>Wrestlers</h4>

                    <Button
                        onClick={page.addAthlete}
                        className="btn btn-link"
                        text="+ Add Athelete" />
                </div>

                <p>Tell us about your wrestlers. We use this information to register with the Missouri USA Wrestling association.</p>

                {page.atheleteForms.map((form, index) => {
                    return (
                        <div className={index % 2 === 1 ? 'alt row' : 'row'}>
                            {page.atheleteForms.length > 1 && (

                                <div className="col-12">
                                    <div className="form-section-header">
                                        <span>Athelete {index + 1}</span>

                                        <Button
                                            className="btn btn-link"
                                            onClick={this.removeAthlete(index)}
                                            leftIcon="fa-trash"
                                            text="Remove" />
                                    </div>
                                </div>
                            )}

                            {form.fields.map((field) => (
                                <div className="col-12 col-sm-4">
                                    <Field field={field} />
                                </div>
                            ))}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default RegistrationAthleteFormContainer;
