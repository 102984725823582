import { TournamentSignUpPage, TournamentSignUpPageState } from 'core.frontend';
import TournamentSignUpPageActions from 'core.frontend/dist/pages/tournaments/tournamentSignUpPageActions';
import * as React from 'react';
import Card from '../../misc/components/Card';
import ObservingComponent from '../../misc/components/ObservingComponent';
import TournamentSignUpAccountLoader from './TournamentSignUpAccountLoader';
import TournamentSignUpForm from './TournamentSignUpForm';
import TournamentSignUpInactive from './TournamentSignUpInactive';
import ForgetAccessCodeConfirmationPrompt from './ForgetAccessCodeConfirmationPrompt';
import TournamentSignUpPay from './TournamentSignUpPay';

interface TournamentSignUpPageProps {
    page: TournamentSignUpPage;
}

class TournamentSignUp extends ObservingComponent<TournamentSignUpPageProps> {

    public componentWillMount(): void  {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, [TournamentSignUpPageActions.StateChanged, TournamentSignUpPageActions.TournamentLoaded]);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <React.Fragment>
                { page.pageState === TournamentSignUpPageState.LoadParentAccount && <TournamentSignUpAccountLoader page={page} /> }

                { page.pageState === TournamentSignUpPageState.SignUpInactive && page.tournament && <TournamentSignUpInactive page={page} />}

                { page.pageState === TournamentSignUpPageState.ForgetCodeConfirmationPrompt && <ForgetAccessCodeConfirmationPrompt page={page} />}

                { page.pageState === TournamentSignUpPageState.SignUpForm && page.tournament && (
                    <Card heading={page.tournament.name + ' Sign-Up'}>
                        <TournamentSignUpForm page={page} />
                    </Card>
                )}

                { page.pageState === TournamentSignUpPageState.Pay && (
                    <Card heading={page.tournament.name + ' Sign-Up'}>
                        <TournamentSignUpPay page={page} />
                    </Card>
                )}
            </React.Fragment>
        );
    }
}

export default TournamentSignUp;
