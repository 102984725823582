"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var tournamentSchedule_1 = require("../../components/tournamentSchedule");
var HomePage = /** @class */ (function (_super) {
    __extends(HomePage, _super);
    function HomePage(api, navigator) {
        var _this = _super.call(this, __1.PageTypes.Home, 'Welcome to Neosho Youth Wrestling!') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this._tournamentSchedule = new tournamentSchedule_1.default(api, navigator);
        return _this;
    }
    Object.defineProperty(HomePage.prototype, "tournamentSchedule", {
        get: function () {
            return this._tournamentSchedule;
        },
        enumerable: false,
        configurable: true
    });
    HomePage.prototype.goToRegistration = function () {
        this.navigator.goToRegistration();
    };
    HomePage.prototype.goToTournamentSignUp = function (tournamentId) {
        this.navigator.goToTournamentSignUp(tournamentId);
    };
    return HomePage;
}(__1.BasePage));
exports.default = HomePage;
