import Axios, { AxiosRequestConfig } from 'axios';
import { IApiPlugin, IListResponse } from 'core.frontend';

export default class ApiPlugin implements IApiPlugin {

    public getTablePage<T>(endpointUrl: string, authenticationToken: string): Promise<IListResponse<T>> {
        const config = {
            headers: this.getAuthorizationHeder(authenticationToken)
        } as AxiosRequestConfig;

        return Axios.get<IListResponse<T>>(endpointUrl, config).then((res) => res.data);
    }

    public getSingle<T>(endpointUrl: string, authenticationToken?: string): Promise<T> {
        const config = {
            headers: this.getAuthorizationHeder(authenticationToken)
        } as AxiosRequestConfig;

        return Axios.get<T>(endpointUrl, config).then((res) => res.data);
    }

    public post<T, K>(endpointUrl: string, authenticationToken: string, payload: T): Promise<K> {
        const config = {
            headers: this.getAuthorizationHeder(authenticationToken)
        } as AxiosRequestConfig;

        return Axios.post<K>(endpointUrl, payload, config).then((res) => res.data);
    }

    public put<T>(endpointUrl: string, authenticationToken: string, id: number, payload: T): Promise<void> {
        const config = {
            headers: this.getAuthorizationHeder(authenticationToken)
        } as AxiosRequestConfig;

        return Axios.put<void>(endpointUrl, payload, config).then((res) => res.data);
    }

    public delete(endpointUrl: string, authenticationToken: string, id: number): Promise<void> {
        const config = {
            headers: this.getAuthorizationHeder(authenticationToken)
        } as AxiosRequestConfig;

        return Axios.delete(endpointUrl, config).then(() => {});
    }

    private getAuthorizationHeder(authenticationToken: string): any {
        if (authenticationToken) {
            return { Authorization: `Bearer ${authenticationToken}` };
        } else {
            return null;
        }
    }

    public download(endpointUrl: string, filename: string, authenticationToken: string): void {
        const config = {
            headers: this.getAuthorizationHeder(authenticationToken)
        } as AxiosRequestConfig;

        Axios.get(endpointUrl, config)
            .then((response) => {
                const contentType = response.headers['content-type'];

                const blobData = [response.data];
                const blob = new Blob(blobData, {type: contentType});
                if (typeof window.navigator['msSaveBlob'] !== 'undefined') {
                    // IE workaround for "HTML7007: One or more blob URLs were
                    // revoked by closing the blob for which they were created.
                    // These URLs will no longer resolve as the data backing
                    // the URL has been freed."
                    window.navigator['msSaveBlob'](blob, filename);
                }
                else {
                    const blobURL = window.URL.createObjectURL(blob);
                    const tempLink = document.createElement('a');
                    tempLink.style.display = 'none';
                    tempLink.href = blobURL;
                    tempLink.setAttribute('download', filename);

                    // Safari thinks _blank anchor are pop ups. We only want to set _blank
                    // target if the browser does not support the HTML5 download attribute.
                    // This allows you to download files in desktop safari if pop up blocking
                    // is enabled.
                    if (typeof tempLink.download === 'undefined') {
                        tempLink.setAttribute('target', '_blank');
                    }

                    document.body.appendChild(tempLink);
                    tempLink.click();
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                }
            });
    }
}
