import { RegistrationActions, RegistrationPage } from 'core.frontend';
import * as React from 'react';
import Field from '../../misc/components/fields/Field';
import ObservingComponent from '../../misc/components/ObservingComponent';

interface RegistrationHealthCareProviderFormContainerProps {
    page: RegistrationPage;
}

class RegistrationHealthCareProviderFormContainer extends ObservingComponent<RegistrationHealthCareProviderFormContainerProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, RegistrationActions.SubmissionFailure);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <div className="form-container">
                <h4>Health Care Provider</h4>

                <p>The following information helps us ensure that we obtain proper medical assistance in case of an emergency.</p>

                <div className="row">
                    {page.healthCareProviderForm.fields.map((field) => (
                        <div className="col-12 col-sm-4">
                            <Field field={field} />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default RegistrationHealthCareProviderFormContainer;
