"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DateFormats;
(function (DateFormats) {
    DateFormats["MMDDYYYY"] = "MM/DD/YYYY";
    DateFormats["hmmA"] = "h:mm A";
    DateFormats["hhmm"] = "hhmm";
    DateFormats["dddMMMDhmmA"] = "ddd, MMM D h:mm A";
    DateFormats["dddMMMD"] = "ddd, MMM D";
})(DateFormats || (DateFormats = {}));
exports.default = DateFormats;
