import classnames from 'classnames';
import {    BaseDropdownField as FrontEndDropdownField,
            BaseField,
            CheckBoxField as FrontEndCheckBoxField,
            DateTimeField as FrontEndDateTimeField,
            FieldType,
            MoneyField as FrontEndMoneyField,
            PasswordField as FrontEndPasswordField,
            TextField as FrontEndTextField, } from 'core.frontend';
import * as React from 'react';
import ObservingComponent from '../ObservingComponent';
import CheckboxField from './CheckboxField';
import DateTimeField from './DateTimeField';
import DropdownField from './DropdownField';
import FieldLoadingPlaceholder from './FieldLoadingPlaceholder';
import MoneyField from './MoneyField';
import PasswordField from './PasswordField';
import TextField from './TextField';

export interface IFieldProps {
    className?: string;
    field: BaseField<any>;
    isLoading?: boolean;
}

class Field extends ObservingComponent<IFieldProps> {

    public componentWillMount(): void {
        const { field } = this.props;

        this.registerUpdateObserver(field.observationProvider);
    }

    public render(): JSX.Element {
        const { field, isLoading, className } = this.props;

        const labelClassName = classnames({ active: field.isActive, invalid: field.errorMessage });

        if (!field.isVisible) {
            return null;
        }

        if (isLoading) {
            return <FieldLoadingPlaceholder fieldType={field.fieldType} />;
        }

        switch (field.fieldType) {
            case FieldType.CheckBox:
            return <CheckboxField
                        className={className}
                        field={field as FrontEndCheckBoxField}
                        labelClassName={labelClassName} />;

            case FieldType.DropDown:
            return <DropdownField
                        className={className}
                        field={field as FrontEndDropdownField}
                        labelClassName={labelClassName} />;

            case FieldType.Password:
            return <PasswordField
                        className={className}
                        field={field as FrontEndPasswordField}
                        labelClassName={labelClassName} />;

            case FieldType.Money:
            return <MoneyField
                        className={className}
                        field={field as FrontEndMoneyField}
                        labelClassName={labelClassName} />;

            case FieldType.DateTime:
            return <DateTimeField
                        className={className}
                        field={field as FrontEndDateTimeField}
                        labelClassName={labelClassName} />;

            default:
            return <TextField
                        className={className}
                        field={field as FrontEndTextField}
                        labelClassName={labelClassName} />;
        }
    }
}

export default Field;
