"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var State_1 = require("./State");
var StateManager = /** @class */ (function () {
    function StateManager() {
        var _this = this;
        this.getState = function () {
            return _this.state;
        };
        this.setPage = function (newPage) {
            _this.state.page = newPage;
        };
        this.state = new State_1.default();
    }
    return StateManager;
}());
exports.default = StateManager;
