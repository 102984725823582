import { Table, TableActions } from 'core.frontend';
import * as React from 'react';
import AnchorButton from './AnchorButton';
import ObservingComponent from './ObservingComponent';

interface TableSortButtonProps {
    table: Table<{ id: number }>;
    sortExpression?: string;
    label: string;
}

class TableSortButton extends ObservingComponent<TableSortButtonProps> {

    public componentWillMount(): void {
        const { table } = this.props;

        this.registerUpdateObserver(table.observationProvider, TableActions.SortChanged);
    }

    private sortClicked = () => {
        const { table, sortExpression } = this.props;

        table.sort(sortExpression);
    }

    private getIcon = (): string => {
        const { table, sortExpression } = this.props;

        if (table.isSortedAscendingBy(sortExpression)) {
            return 'fa-sort-up';
        } else if (table.isSortedDescendingBy(sortExpression)) {
            return 'fa-sort-down';
        }
    }

    public render(): JSX.Element {
        const { label, sortExpression } = this.props;

        if (sortExpression) {
            return (
                <AnchorButton
                    onClick={this.sortClicked}
                    text={label}
                    rightIcon={this.getIcon()} />
            );
        } else {
            return <span>{label}</span>;
        }
    }
}

export default TableSortButton;
