import classnames from 'classnames';
import * as React from 'react';
import AnchorButton from './AnchorButton';
import Button from './Button';

interface TablePagerProps {
    isLoading: boolean;
    isNextPageAvailable: boolean;
    currentPage: number;
    isPreviousPageAvailable: boolean;
    pageNumberOptions: number[];
    onGoToPage: (pageNumber: number) => void;
    onPrevPageClicked: () => void;
    onNextPageClicked: () => void;
}

class TablePager extends React.PureComponent<TablePagerProps> {

    private goToPage = (pageNumber: number) => () => {
        const { onGoToPage } = this.props;

        onGoToPage(pageNumber);
    }

    public render(): JSX.Element {
        const { isLoading, isPreviousPageAvailable, pageNumberOptions, currentPage, isNextPageAvailable, onNextPageClicked, onPrevPageClicked } = this.props;

        return (
            <React.Fragment>
                { isLoading ? (
                    <span className="pagination">
                        <span className="loading-placeholder shimmer"></span>
                        <span className="loading-placeholder shimmer"></span>
                    </span>
                ) : (
                    <React.Fragment>
                        <ul className="pagination dt-only">
                            <li className={classnames('page-item', {disabled: !isPreviousPageAvailable})}>
                                <AnchorButton
                                    onClick={onPrevPageClicked}
                                    className="page-link"
                                    text="Previous" />
                            </li>

                            {pageNumberOptions.map((pageNumber) => (
                                <li
                                    key={pageNumber}
                                    className={classnames('page-item', { active: pageNumber === currentPage })}>

                                    <AnchorButton
                                        onClick={this.goToPage(pageNumber)}
                                        className="page-link"
                                        text={pageNumber.toString()} />
                                </li>
                            ))}

                            <li className={classnames('page-item', {disabled: !isNextPageAvailable})}>
                                <AnchorButton
                                    onClick={onNextPageClicked}
                                    className="page-link"
                                    text="Next" />
                            </li>
                        </ul>
                        <div className="pagination phone-only">
                            <Button
                                className={classnames('btn btn-secondary', {disabled: !isPreviousPageAvailable})}
                                disabled={!isPreviousPageAvailable}
                                onClick={onPrevPageClicked}
                                leftIcon="fa-chevron-left"
                                text="Previous" />

                            <Button
                                className={classnames('btn btn-primary', {disabled: !isNextPageAvailable})}
                                disabled={!isNextPageAvailable}
                                onClick={onNextPageClicked}
                                rightIcon="fa-chevron-right"
                                text="Next" />
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default TablePager;
