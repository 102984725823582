import { TournamentSignUpPage } from 'core.frontend';
import * as React from 'react';
import Card from '../../misc/components/Card';
import ObservingComponent from '../../misc/components/ObservingComponent';
import Button from '../../misc/components/Button';

interface ForgetAccessCodeConfirmationPromptProps {
    page: TournamentSignUpPage;
}

class ForgetAccessCodeConfirmationPrompt extends ObservingComponent<ForgetAccessCodeConfirmationPromptProps> {

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <Card heading="Are you sure?">
                <p className="text-left">
                    <Button
                        onClick={page.backToSignUpForm}
                        className="btn btn-link"
                        leftIcon="fa-chevron-left"
                        text="Back" />
                </p>

                <div className="registration-access-code-wrapper">
                    <span>{page.currentParentAccountManager.parentAccount.accessCode}</span>
                    Current Access Code
                </div>

                <p>
                    By clearing your access code you're browser will forget who you are. You'll be required to enter an access code again
                    before signing up for tournaments. Please be sure to take note of your access code before proceeding.
                </p>

                <Button
                    className="btn btn-primary btn-wide-phone"
                    onClick={page.forgetAccessCode}
                    text="Proceed"
                    rightIcon="fa-chevron-right"/>

            </Card>
        );
    }
}

export default ForgetAccessCodeConfirmationPrompt;
