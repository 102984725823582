import { ParentAccountBalanceAdjustmentActions, ParentAccountDetailPage, ParentAccountDetailPageActions } from 'core.frontend';
import * as React from 'react';
import Moment from 'react-moment';
import AccountBalance from '../../misc/components/AccountBalance';
import Button from '../../misc/components/Button';
import ObservingComponent from '../../misc/components/ObservingComponent';
import LedgerEntriesList from './LedgerEntriesList';
import ParentAccountBalanceAdjustmentModal from './ParentAccountBalanceAdjustmentModal';

interface ParentAccountDetailBalanceTabProps {
    parentAccountDetailPage: ParentAccountDetailPage;
}

class ParentAccountDetailBalanceTab extends ObservingComponent<ParentAccountDetailBalanceTabProps> {
    public componentWillMount(): void {
        const { parentAccountDetailPage } = this.props;

        this.registerUpdateObserver(parentAccountDetailPage.balanceAdjustmentForm.observationProvider, [ParentAccountBalanceAdjustmentActions.Shown, ParentAccountBalanceAdjustmentActions.Hidden]);
        this.registerUpdateObserver(parentAccountDetailPage.observationProvider, ParentAccountDetailPageActions.LedgerEntryAdded);
    }

    public render(): JSX.Element {
        const { parentAccountDetailPage } = this.props;

        return (
            <React.Fragment>
                <div className="parent-detail-balance">

                    <h4>Account Balance</h4>

                    <AccountBalance balance={parentAccountDetailPage.accountDetail.balance} />

                    <div>
                        <span>Last activity: </span>

                        <Moment
                            fromNow
                            date={parentAccountDetailPage.lastBalanceActivityDate} />
                    </div>

                    <Button
                        onClick={parentAccountDetailPage.balanceAdjustmentForm.show}
                        className="btn btn-primary"
                        text="Adjust Balance" />

                    { parentAccountDetailPage.balanceAdjustmentForm.visible && (
                        <ParentAccountBalanceAdjustmentModal accountBalanceAdjustmentForm={parentAccountDetailPage.balanceAdjustmentForm} />
                    )}
                </div>

                <LedgerEntriesList
                    ledgerEntries={parentAccountDetailPage.accountDetail.ledgerEntries}
                    isAddingNew={parentAccountDetailPage.balanceAdjustmentForm.isSaving} />

            </React.Fragment>
        );
    }
}

export default ParentAccountDetailBalanceTab;
