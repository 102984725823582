import * as React from 'react';
import ObservingProvider from '../components/ObservingComponent';
import ReactApp from '../reactApp';
import NavMenu from './NavMenu';
import NavMenuBackground from './NavMenuBackground';

interface IHeaderProps {
    reactApp: ReactApp;
}

class Header extends ObservingProvider<IHeaderProps> {

    public render(): JSX.Element {
        const { reactApp } = this.props;

        return (
            <React.Fragment>
                <header className="navbar navbar-top fixed-top">

                    <div className="logo-wrapper">

                        <div onClick={reactApp.app.navigator.goToHome}>
                            <img
                                src="img/wildcat_logo_small.png"
                                className="logo" />

                            <span className="title">Neosho Youth Wrestling</span>
                        </div>
                    </div>

                    <NavMenu reactApp={reactApp}/>
                </header>

                <NavMenuBackground reactApp={reactApp} />
            </React.Fragment>
        );
    }
}

export default Header;
