import { ParentAccountsPage, RegistrationTableItemDto } from 'core.frontend';
import * as React from 'react';
import AccountBalance from '../../misc/components/AccountBalance';
import AnchorButton from '../../misc/components/AnchorButton';
import Card from '../../misc/components/Card';
import SmartTable from '../../misc/components/smartTable';
import SmartTableColumn from '../../misc/smartTableColumn';

interface ParentAccountsListProps {
    page: ParentAccountsPage;
}

class ParentAccountsList extends React.PureComponent<ParentAccountsListProps> {

    public editAccountClicked = (registrationId: number) => () => {
        const { page } = this.props;

        page.goToParentAccountDetail(registrationId);
    }

    private buildColumns = (): SmartTableColumn<RegistrationTableItemDto>[] => {
        return [
            {
                heading: 'Parents',
                cellTemplate: (registration: RegistrationTableItemDto) => (
                        <React.Fragment>
                            {registration.guardians.map((guardian) => (
                                <React.Fragment>
                                    {guardian} <br/>
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ),
            }, {
                heading: 'Athletes',
                className: 'dt-only',
                cellTemplate: (registration: RegistrationTableItemDto) => (
                    <React.Fragment>
                        {registration.athletes.map((athlete) => (
                            <React.Fragment>
                                {athlete} <br/>
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                )
            }, {
                className: 'text-center',
                heading: 'Balance',
                sortExpression: 'balance',
                cellTemplate: (registration: RegistrationTableItemDto) => <AccountBalance balance={registration.balance} />
            }, {
                headingClassName: 'button-column-spacer',
                hideWhenSaving: true,
                cellTemplate: (registration: RegistrationTableItemDto) => <AnchorButton
                                                    className="btn-details"
                                                    onClick={this.editAccountClicked(registration.id)}
                                                    rightIcon="fa-arrow-right" />
            }
        ];
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <Card heading={page.title}>
                <SmartTable
                    table={page.parentAccountsTable}
                    columns={this.buildColumns()} />
            </Card>
        );
    }
}

export default ParentAccountsList;
