"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var api_1 = require("../api");
var config_1 = require("../config");
var navigator_1 = require("../navigator");
var pages_1 = require("../pages");
var state_1 = require("../state");
var currentParentAccountManager_1 = require("./currentParentAccountManager");
var currentUserManager_1 = require("./currentUserManager");
var App = /** @class */ (function () {
    function App(apiPlugin, localStoragePlugin) {
        var _this = this;
        this.config = new config_1.Config();
        this.currentUserManager = new currentUserManager_1.default(localStoragePlugin);
        this.api = new api_1.Api(apiPlugin, this.config, this.currentUserManager);
        this.currentParentAccountManager = new currentParentAccountManager_1.default(this.api, localStoragePlugin);
        this.stateManager = new state_1.StateManager();
        this.pageFactory = new pages_1.PageFactory(this.api, this.currentUserManager, this.currentParentAccountManager);
        this.navigator = new navigator_1.Navigator(this.stateManager, this.pageFactory);
        this.currentUserManager.observationProvider.observe(function () { _this.navigator.goToHome(); }, _1.CurrentUserManagerActions.LoggedOut);
        this.currentUserManager.observationProvider.observe(function () { _this.navigator.closeMenu(); }, _1.CurrentUserManagerActions.LoggedOut);
    }
    return App;
}());
exports.default = App;
