"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var stringDropdownField_1 = require("./stringDropdownField");
var WeighClassField = /** @class */ (function (_super) {
    __extends(WeighClassField, _super);
    function WeighClassField(key, isRequired, division, initialValue) {
        var _this = _super.call(this, key, 'Weight Class', isRequired, WeighClassField.buildOptions(division), 'Weight class is required', initialValue, _1.FieldType.DropDown) || this;
        _this.key = key;
        _this.setDivision = function (division) {
            _this.setOptions(WeighClassField.buildOptions(division));
        };
        return _this;
    }
    WeighClassField.buildOptions = function (division) {
        if (!division || !division.weightClasses) {
            return [];
        }
        var weightClasses = division.weightClasses.split(',');
        var options = [];
        for (var _i = 0, weightClasses_1 = weightClasses; _i < weightClasses_1.length; _i++) {
            var weight = weightClasses_1[_i];
            options.push(new _1.FieldOption(weight + " lbs.", weight));
        }
        return options;
    };
    return WeighClassField;
}(stringDropdownField_1.default));
exports.default = WeighClassField;
