"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var baseField_1 = require("./baseField");
var TextField = /** @class */ (function (_super) {
    __extends(TextField, _super);
    function TextField(key, label, isRequired, maxCharacters, requiredValidationMessage, initialValue, fieldType) {
        if (fieldType === void 0) { fieldType = _1.FieldType.TextBox; }
        var _this = _super.call(this, fieldType, key, label, isRequired, maxCharacters, requiredValidationMessage) || this;
        _this.key = key;
        if (initialValue) {
            _this.setValue(initialValue);
        }
        return _this;
    }
    Object.defineProperty(TextField.prototype, "errorMessage", {
        get: function () {
            return _super.prototype.getErrorMessage.call(this);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TextField.prototype, "value", {
        get: function () {
            return this.stringValue;
        },
        enumerable: false,
        configurable: true
    });
    TextField.prototype.isValid = function () {
        var isValid = this.checkRequiredStatus();
        this.setIsValid(isValid);
        return isValid;
    };
    TextField.prototype.setValue = function (newValue) {
        this.setStringValue(newValue);
    };
    return TextField;
}(baseField_1.default));
exports.default = TextField;
