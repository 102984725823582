"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PageTypes;
(function (PageTypes) {
    PageTypes[PageTypes["Home"] = 0] = "Home";
    PageTypes[PageTypes["Users"] = 1] = "Users";
    PageTypes[PageTypes["UserDetail"] = 2] = "UserDetail";
    PageTypes[PageTypes["Registration"] = 3] = "Registration";
    PageTypes[PageTypes["RegistrationSuccess"] = 4] = "RegistrationSuccess";
    PageTypes[PageTypes["Login"] = 5] = "Login";
    PageTypes[PageTypes["ParentAccounts"] = 6] = "ParentAccounts";
    PageTypes[PageTypes["ParentAccountDetail"] = 7] = "ParentAccountDetail";
    PageTypes[PageTypes["TournamentSignUp"] = 8] = "TournamentSignUp";
    PageTypes[PageTypes["TournamentSignUpSuccess"] = 9] = "TournamentSignUpSuccess";
    PageTypes[PageTypes["Tournaments"] = 10] = "Tournaments";
    PageTypes[PageTypes["TournamentDetail"] = 11] = "TournamentDetail";
})(PageTypes || (PageTypes = {}));
exports.default = PageTypes;
