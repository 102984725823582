"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dateFormats_1 = require("./dateFormats");
var DateUtils = /** @class */ (function () {
    function DateUtils() {
    }
    DateUtils.format = function (date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        if (!date) {
            return '';
        }
        var month = ('0' + (date.getMonth() + 1)).slice(-2);
        var dayOfMonth = ('0' + date.getDate()).slice(-2);
        var year = date.getFullYear();
        return month + "/" + dayOfMonth + "/" + year;
    };
    DateUtils.isDateObject = function (obj) {
        return obj && typeof obj.getMonth === 'function';
    };
    DateUtils.hasDatePast = function (date) {
        var now = new Date();
        date = new Date(date);
        return now > date;
    };
    DateUtils.isValidTime = function (timeStr) {
        return this.isFormatValid(timeStr, dateFormats_1.default.hhmm);
    };
    DateUtils.isValid = function (dateStr, format) {
        if (!this.isFormatValid(dateStr, format)) {
            return false;
        }
        var dateParts = this.getDateParts(dateStr, format);
        return this.areDatePartsValid(dateParts);
    };
    DateUtils.getDateParts = function (dateStr, format) {
        switch (format) {
            case dateFormats_1.default.MMDDYYYY: {
                var dateParts = dateStr.split('/');
                return {
                    month: parseInt(dateParts[0], 10),
                    day: parseInt(dateParts[1], 10),
                    year: parseInt(dateParts[2], 10)
                };
            }
        }
    };
    DateUtils.areDatePartsValid = function (dateParts) {
        return this.isMonthDatePartValid(dateParts) && this.isDayDatePartValid(dateParts);
    };
    DateUtils.isMonthDatePartValid = function (dateParts) {
        if (dateParts.month < 1 || dateParts.month > 12) {
            return false;
        }
        return true;
    };
    DateUtils.isDayDatePartValid = function (dateParts) {
        var _31DayMonths = [1, 3, 5, 7, 8, 10, 12];
        var _30DayMonths = [4, 6, 9, 11];
        if (_31DayMonths.indexOf(dateParts.month) > -1) {
            return dateParts.day >= 1 && dateParts.day <= 31;
        }
        if (_30DayMonths.indexOf(dateParts.month) > -1) {
            return dateParts.day >= 1 && dateParts.day <= 30;
        }
        if (dateParts.month === 2) {
            return this.isValidFebruaryDay(dateParts);
        }
        return false;
    };
    DateUtils.isValidFebruaryDay = function (dateParts) {
        if (dateParts.month !== 2) {
            return false;
        }
        if (!this.isLeapYear(dateParts.year) && (dateParts.day < 1 || dateParts.day > 28)) {
            return false;
        }
        if (this.isLeapYear(dateParts.year) && (dateParts.day < 1 || dateParts.day > 29)) {
            return false;
        }
        return true;
    };
    DateUtils.isLeapYear = function (year) {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    };
    DateUtils.isFormatValid = function (dateStr, format) {
        var regex;
        var isValid = false;
        switch (format) {
            case dateFormats_1.default.MMDDYYYY:
                regex = /^[0-3][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]$/;
                isValid = regex.test(dateStr);
            case dateFormats_1.default.hhmm: {
                regex = /^[0-9][0-9]:[0-9][0-9]$/;
                if (regex.test(dateStr)) {
                    var timeParts = dateStr.split(':');
                    if (timeParts.length === 2) {
                        var hour = parseInt(timeParts[0], 10);
                        var minutes = parseInt(timeParts[1], 10);
                        if (hour > 0 && hour <= 12 && minutes >= 0 && minutes < 60) {
                            isValid = true;
                        }
                    }
                }
            }
        }
        return isValid;
    };
    return DateUtils;
}());
exports.default = DateUtils;
