import { CurrentParentAccountManagerActions, FormActions, TournamentSignUpPage } from 'core.frontend';
import * as React from 'react';
import Button from '../../misc/components/Button';
import Card from '../../misc/components/Card';
import ErrorMessage from '../../misc/components/ErrorMessage';
import Field from '../../misc/components/fields/Field';
import ObservingComponent from '../../misc/components/ObservingComponent';

interface TournamentSignUpAccountLoaderProps {
    page: TournamentSignUpPage;
}

class TournamentSignUpAccountLoader extends ObservingComponent<TournamentSignUpAccountLoaderProps, {}> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.currentParentAccountManager.observationProvider, [CurrentParentAccountManagerActions.FailedToLoadParentAccount, CurrentParentAccountManagerActions.ParentAccountLoading]);
        this.registerUpdateObserver(page.loadAccountForm.observationProvider, FormActions.FormSubmissionFailure);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <Card heading="Access Your Account">
                <form noValidate>

                    <ErrorMessage errorMessage={page.currentParentAccountManager.errorMessage} />

                    <p>Before you can sign-up for a tournament you need to access your parent account. To do this, please provide the primary phone number you used when registering for the season. Your device will remember this code so that you don't have to enter it next time.</p>

                    <div className="field-container">
                        <Field field={page.loadAccountForm.fields[0]} />
                    </div>

                    <footer>
                        <div>
                            <Button
                                type="submit"
                                className="btn btn-primary btn-wide-phone"
                                onClick={page.loadAccountForm.submit}
                                processing={page.currentParentAccountManager.isSubmitting}
                                text="Submit" />
                        </div>
                    </footer>
                </form>
            </Card>
        );
    }
}

export default TournamentSignUpAccountLoader;
