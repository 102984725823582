import { ParentAccountBalanceAdjustment, ParentAccountBalanceAdjustmentActions, ParentAccountBalanceAdjustmentType } from 'core.frontend';
import * as React from 'react';
import AccountBalance from '../../misc/components/AccountBalance';
import Button from '../../misc/components/Button';
import Field from '../../misc/components/fields/Field';
import Modal from '../../misc/components/Modal';
import ObservingComponent from '../../misc/components/ObservingComponent';

interface ParentAccountBalanceAdjustmentModalProps {
    accountBalanceAdjustmentForm: ParentAccountBalanceAdjustment;
}

class ParentAccountBalanceAdjustmentModal extends ObservingComponent<ParentAccountBalanceAdjustmentModalProps, {}> {
    public componentWillMount(): void {
        const { accountBalanceAdjustmentForm } = this.props;

        this.registerUpdateObserver(accountBalanceAdjustmentForm.observationProvider, ParentAccountBalanceAdjustmentActions.FormInvalid);
        this.registerUpdateObserver(accountBalanceAdjustmentForm.observationProvider, ParentAccountBalanceAdjustmentActions.AdjustmentTypeChanged);
    }

    public setAdjustmentType = (adjustmentType: ParentAccountBalanceAdjustmentType) => () => {
        const { accountBalanceAdjustmentForm } = this.props;

        accountBalanceAdjustmentForm.setAdjustmentType(adjustmentType);
    }

    public render(): JSX.Element {
        const { accountBalanceAdjustmentForm } = this.props;

        return (
            <Modal
                title="Adjust Balance"
                onClose={accountBalanceAdjustmentForm.hide}>

                {{
                    content: (
                        <div className="parent-detail-balance">
                            <h4>Current Balance</h4>

                            <AccountBalance balance={accountBalanceAdjustmentForm.currentBalance} />

                            { accountBalanceAdjustmentForm.adjustmentType === null &&
                                <div className="button-container">
                                    <Button
                                        className="btn btn-primary btn-wide-phone"
                                        onClick={this.setAdjustmentType(ParentAccountBalanceAdjustmentType.SetBalance)}
                                        leftIcon="fa-dollar-sign"
                                        text="Set Balance" />

                                    <Button
                                        className="btn btn-primary btn-wide-phone"
                                        onClick={this.setAdjustmentType(ParentAccountBalanceAdjustmentType.Credit)}
                                        leftIcon="fa-plus"
                                        text="Credit Balance" />

                                    <Button
                                        className="btn btn-primary btn-wide-phone"
                                        onClick={this.setAdjustmentType(ParentAccountBalanceAdjustmentType.Debit)}
                                        leftIcon="fa-minus"
                                        text="Debit Balance" />
                                </div>
                            }

                            <div className="text-left top-m-4">
                                { accountBalanceAdjustmentForm.adjustmentType !== null &&
                                    <Field field={accountBalanceAdjustmentForm.memoField} /> }

                                { accountBalanceAdjustmentForm.adjustmentType === ParentAccountBalanceAdjustmentType.SetBalance &&
                                    <Field field={accountBalanceAdjustmentForm.newBalanceField} /> }

                                { accountBalanceAdjustmentForm.adjustmentType === ParentAccountBalanceAdjustmentType.Credit &&
                                    <Field field={accountBalanceAdjustmentForm.creditField} /> }

                                { accountBalanceAdjustmentForm.adjustmentType === ParentAccountBalanceAdjustmentType.Debit &&
                                    <Field field={accountBalanceAdjustmentForm.debitField} /> }
                            </div>
                        </div>
                    ),
                    footer:
                        ( accountBalanceAdjustmentForm.adjustmentType !== null &&
                            <Button
                                type="submit"
                                className="btn btn-primary"
                                onClick={accountBalanceAdjustmentForm.save}
                                text="Save" />
                        )
                }}
            </Modal>
        );
    }
}

export default ParentAccountBalanceAdjustmentModal;
