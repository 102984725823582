"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ParentAccountDetailPageActions;
(function (ParentAccountDetailPageActions) {
    ParentAccountDetailPageActions["RecordLoading"] = "RecordLoading";
    ParentAccountDetailPageActions["RecordLoaded"] = "RecordLoaded";
    ParentAccountDetailPageActions["ErrorLoading"] = "ErrorLoading";
    ParentAccountDetailPageActions["BalancedChanged"] = "BalancedChanged";
    ParentAccountDetailPageActions["LedgerEntryAdded"] = "LedgerEntryAdded";
})(ParentAccountDetailPageActions || (ParentAccountDetailPageActions = {}));
exports.default = ParentAccountDetailPageActions;
