import { LoginPage } from 'core.frontend';
import LoginPageActions from 'core.frontend/dist/pages/login/loginPageActions';
import * as React from 'react';
import Button from '../../misc/components/Button';
import ErrorMessage from '../../misc/components/ErrorMessage';
import Field from '../../misc/components/fields/Field';
import ObservingComponent from '../../misc/components/ObservingComponent';

interface LoginProps {
    page: LoginPage;
}

class Login extends ObservingComponent<LoginProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, [LoginPageActions.LoginFormSubmitted, LoginPageActions.LoginFormInvalid, LoginPageActions.LoginUnsuccessfull]);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <div className="login-form container text-center">
                <form noValidate>
                    <img src="img/wildcat_logo_small.png" />
                    <h1>Sign In</h1>

                    <ErrorMessage errorMessage={page.errorMessage} />

                    <div className="text-left">
                        {page.form.fields.map((field, index) =>
                            <Field
                                field={field}
                                key={index} />
                        )}
                    </div>

                    <Button
                        className="btn btn-primary"
                        type="submit"
                        onClick={page.form.submit}
                        text="Sign In"
                        processing={page.isLoading} />
                </form>
            </div>
        );
    }
}

export default Login;
