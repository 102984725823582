import { UserDto, UsersPage } from 'core.frontend';
import * as React from 'react';
import AnchorButton from '../../misc/components/AnchorButton';
import Button from '../../misc/components/Button';
import Card from '../../misc/components/Card';
import SmartTable from '../../misc/components/smartTable';
import SmartTableColumn from '../../misc/smartTableColumn';

interface UserListProps {
    page: UsersPage;
}

class UserList extends React.PureComponent<UserListProps> {

    private editUserClicked = (user: UserDto) => () => {
        const { page } = this.props;

        page.editUser(user.id);
    }

    private buildColumns = (): SmartTableColumn<UserDto>[] => {
        return [
            {
                heading: 'Name',
                sortExpression: 'firstName,lastName',
                cellTemplate: (user: UserDto) => <span>{user.firstName} {user.lastName}</span>,
                appendSavingIndicator: true
            }, {
                heading: 'Email',
                sortExpression: 'email',
                className: 'dt-only',
                cellTemplate: (user: UserDto) => <span>{user.email}</span>
            }, {
                headingClassName: 'button-column-spacer',
                hideWhenSaving: true,
                cellTemplate: (user: UserDto) => <AnchorButton
                                                    className="btn-details"
                                                    onClick={this.editUserClicked(user)}
                                                    rightIcon="fa-arrow-right" />
            }
        ];
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <Card
                heading={page.title}
                headingRightContent={
                    <Button
                        className="btn btn-primary"
                        onClick={page.addUser}
                        leftIcon="fa-plus"
                        text="New" />
                }>

                <SmartTable
                    table={page.usersTable}
                    columns={this.buildColumns()} />
            </Card>
        );
    }
}

export default UserList;
