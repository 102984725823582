import * as React from 'react';

interface AnchorButtonProps {
    className?: string;
    type?: string;
    leftIcon?: string;
    rightIcon?: string;
    onClick: () => void;
    text?: string;
}

class AnchorButton extends React.PureComponent<AnchorButtonProps> {

    public handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const { onClick } = this.props;

        e.preventDefault();

        if (typeof onClick === 'function'){
            onClick();
        }
    }

    public render(): JSX.Element {
        const { className, text, leftIcon, rightIcon, children } = this.props;

        return (
            <a
                className={ className }
                onClick={this.handleClick}>

                { leftIcon && <i className={'fa ' + leftIcon}></i> }

                { text && <span>{text}</span> }

                { children }

                { rightIcon && <i className={'fa ' + rightIcon}></i> }
            </a>
        );
    }
}

export default AnchorButton;
