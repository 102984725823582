"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var __1 = require("..");
var __2 = require("../..");
var endpointUrls_1 = require("../../api/endpointUrls");
var observationProvider_1 = require("../../utils/observationProvider");
var loginPageActions_1 = require("./loginPageActions");
var components_1 = require("../../components");
var LoginPage = /** @class */ (function (_super) {
    __extends(LoginPage, _super);
    function LoginPage(api, navigator, currentUserManager) {
        var _this = _super.call(this, __1.PageTypes.Login, 'Login') || this;
        _this.api = api;
        _this.navigator = navigator;
        _this.currentUserManager = currentUserManager;
        _this.notifyFormInvalid = function () {
            _this.observationProvider.notifyObservers(loginPageActions_1.default.LoginFormInvalid);
        };
        _this.formSubmitted = function () {
            _this.observationProvider.notifyObservers(loginPageActions_1.default.LoginFormSubmitted);
            _this._isLoading = true;
            _this.api.post(endpointUrls_1.default.Login, _this._form.model)
                .then(function (authenticatedUserDto) {
                _this.currentUserManager.login(authenticatedUserDto);
                _this.navigator.goToHome();
                _this._errorMessage = '';
                _this._isLoading = false;
                _this.observationProvider.notifyObservers(loginPageActions_1.default.LoginSuccessfull);
            })
                .catch(function () {
                _this._errorMessage = 'It looks like that email or password isn\'t correct';
                _this._isLoading = false;
                _this.observationProvider.notifyObservers(loginPageActions_1.default.LoginUnsuccessfull);
            });
        };
        if (_this.currentUserManager.isLoggedIn()) {
            navigator.goToHome();
        }
        else {
            _this.observationProvider = new observationProvider_1.default();
            _this.buildForm();
        }
        return _this;
    }
    Object.defineProperty(LoginPage.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LoginPage.prototype, "errorMessage", {
        get: function () {
            return this._errorMessage;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LoginPage.prototype, "form", {
        get: function () {
            return this._form;
        },
        enumerable: false,
        configurable: true
    });
    LoginPage.prototype.buildForm = function () {
        var fields = [
            new components_1.EmailField('email', true),
            new components_1.PasswordField('password', true)
        ];
        this._form = new __2.Form(fields);
        this._form.observationProvider.observe(this.notifyFormInvalid, __2.FormActions.FormSubmissionFailure);
        this._form.observationProvider.observe(this.formSubmitted, __2.FormActions.FormSubmitted);
    };
    return LoginPage;
}(__1.BasePage));
exports.default = LoginPage;
