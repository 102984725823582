import * as React from 'react';

interface ModalProps {
    children: {
        content: JSX.Element | string,
        footer: JSX.Element | string
    };
    title: string;
    onClose?: () => void;
}

class Modal extends React.PureComponent<ModalProps> {

    private closeModal = () => (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => {
        const { onClose } = this.props;

        if (onClose) {
            onClose();
        }
    }

    private windowClicked = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }

    public render(): JSX.Element {
        const { title, children } = this.props;

        return (
            <React.Fragment>
                <div
                    className="modal-background"
                    onClick={this.closeModal()}>

                    <form name="modalForm" className="modal-form">
                        <div
                            className="modal-window"
                            onClick={this.windowClicked}>

                            <div className="modal-window-header">
                                <a
                                    className="btn btn-link"
                                    onClick={this.closeModal()}>

                                    <i className="fa fa-arrow-left"></i>
                                </a>
                                {title}
                            </div>

                            <div className="modal-window-content">
                                {children.content}
                            </div>

                            <div className="modal-window-footer">
                                {children.footer}
                            </div>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}

export default Modal;
