import { FieldType } from 'core.frontend';
import * as React from 'react';

export interface FieldLoadingPlaceholderProps {
    fieldType: FieldType;
}

class FieldLoadingPlaceholder extends React.PureComponent<FieldLoadingPlaceholderProps> {

    public render(): JSX.Element {
        const { fieldType } = this.props;

        return (
            <React.Fragment>
                <label className="loading-placeholder shimmer"></label>
                <div className="text-input loading-placeholder shimmer"></div>
            </React.Fragment>
        );
    }
}

export default FieldLoadingPlaceholder;
