import { TournamentDetailPage } from 'core.frontend';
import * as React from 'react';
import Card from '../../misc/components/Card';
import ObservingComponent from '../../misc/components/ObservingComponent';
import CardTab from '../../misc/cardTab';
import TournamentDetailForm from './TournamentDetailForm';
import TournamentEntries from './TournamentEntries';
import AnchorButton from '../../misc/components/AnchorButton';

interface TournamentDetailProps {
    page: TournamentDetailPage;
}

class TournamentDetail extends ObservingComponent<TournamentDetailProps> {

    public getTabs(): CardTab[] {
        const { page } = this.props;

        return [
            new CardTab('Tournament Setup', <TournamentDetailForm page={page} /> ),
            new CardTab('Entries', <TournamentEntries page={page} /> )
        ];
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <Card
                heading={(
                    <span>
                        <AnchorButton
                            onClick={page.backToTournaments}
                            leftIcon="fa-chevron-left"/>

                        {page.title}
                    </span>)}
                tabs={ this.getTabs() } />
        );
    }
}

export default TournamentDetail;
