"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var stringDropdownField_1 = require("./stringDropdownField");
var ShirtSizeField = /** @class */ (function (_super) {
    __extends(ShirtSizeField, _super);
    function ShirtSizeField(key, isRequired, initialValue) {
        var _this = _super.call(this, key, 'T-Shirt Size', isRequired, [
            new Option('Youth X-Small', 'Youth X-Small'),
            new Option('Youth Small', 'Youth Small'),
            new Option('Youth Medium', 'Youth Medium'),
            new Option('Youth Large', 'Youth Large'),
            new Option('Adult Small', 'Adult Small'),
            new Option('Adult Medium', 'Adult Medium'),
            new Option('Adult Large', 'Adult Large'),
            new Option('Adult X-Large', 'Adult X-Large'),
            new Option('Adult XX-Large', 'Adult XX-Large'),
        ], 'Shirt size is required', initialValue, _1.FieldType.DropDown) || this;
        _this.key = key;
        return _this;
    }
    return ShirtSizeField;
}(stringDropdownField_1.default));
exports.default = ShirtSizeField;
