import { RegistrationActions, RegistrationPage, RegistrationPageState } from 'core.frontend';
import * as React from 'react';
import Button from '../../misc/components/Button';
import ObservingComponent from '../../misc/components/ObservingComponent';
import RegistrationAthleteFormContainer from './RegistrationAthleteFormContainer';
import RegistrationEmergencyContactFormContainer from './RegistrationEmergencyContactFormContainer';
import RegistrationGettingStartedStep from './RegistrationGettingStartedStep';
import RegistrationGuardianFormContainer from './RegistrationGuardianFormContainer';
import RegistrationHealthCareProviderFormContainer from './RegistrationHealthCareProviderFormContainer';
import RegistrationInsuranceFormContainer from './RegistrationInsuranceFormContainer';
import RegistrationReviewStep from './RegistrationReviewStep';

interface RegistrationProps {
    page: RegistrationPage;
}

class Registration extends ObservingComponent<RegistrationProps> {
    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider, [RegistrationActions.StateChanged, RegistrationActions.SubmissionFailure, RegistrationActions.FormSubmission]);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <div className="top-p-1">
                {page.pageState === RegistrationPageState.GettingStarted && <RegistrationGettingStartedStep page={page} /> }

                {page.pageState === RegistrationPageState.RegistrationForm && (
                    <div className="container">
                        <form noValidate>
                            <RegistrationAthleteFormContainer page={page} />
                            <RegistrationGuardianFormContainer page={page} />
                            <RegistrationEmergencyContactFormContainer page={page} />
                            <RegistrationInsuranceFormContainer page={page} />
                            <RegistrationHealthCareProviderFormContainer page={page} />

                            <footer>
                                <div>
                                    <Button
                                        type="submit"
                                        className="btn btn-primary btn-wide-phone"
                                        onClick={page.submit}
                                        rightIcon="fa-chevron-right"
                                        processing={page.isSubmitting}
                                        text="Continue" />
                                </div>
                            </footer>
                        </form>
                    </div>
                )}

                {page.pageState === RegistrationPageState.Review && <RegistrationReviewStep page={page} />}
            </div>
        );
    }
}

export default Registration;
