import { ILocalStoragePlugin } from 'core.frontend';
import AuthenticatedUserDto from 'core.frontend/dist/api/authentication/authenticatedUserDto';

export default class LocalStoragePlugin implements ILocalStoragePlugin {
    private userKey: string        = 'user';
    private accountCodeKey: string = 'accountCode';

    public getCurrentUser(): AuthenticatedUserDto {
        return JSON.parse(window.localStorage.getItem(this.userKey));
    }

    public setCurrentUser(user: AuthenticatedUserDto): void {
        window.localStorage.setItem(this.userKey, JSON.stringify(user));
    }

    public getCurrentAccountCode(): string {
        return window.localStorage.getItem(this.accountCodeKey);
    }

    public setCurrentAccountCode(accountCode: string): void {
        window.localStorage.setItem(this.accountCodeKey, accountCode);
    }

    public clearCurrentAccountCode(): void {
        window.localStorage.removeItem(this.accountCodeKey);
    }
}
