"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ObservationProvider = /** @class */ (function () {
    function ObservationProvider() {
        var _this = this;
        this.observe = function (fn, action) {
            var observer = { id: _this.observerId, fn: fn, action: action };
            _this.observers.push(observer);
            _this.observerId++;
            return observer.id;
        };
        this.stopObserving = function (id) {
            _this.observers = _this.observers.filter(function (observer) { return observer.id !== id; });
        };
        this.stopObservingAll = function () {
            for (var _i = 0, _a = _this.observers; _i < _a.length; _i++) {
                var observer = _a[_i];
                _this.stopObserving(observer.id);
            }
        };
        this.notifyObservers = function (action, param) {
            for (var i = 0; i < _this.observers.length; i++) {
                if (_this.isObservingAction(_this.observers[i], action)) {
                    _this.observers[i].fn(param);
                }
            }
        };
        this.observerId = 1;
        this.observers = [];
    }
    ObservationProvider.prototype.isObservingAction = function (observer, action) {
        return !action || !observer.action || observer.action === action;
    };
    return ObservationProvider;
}());
exports.default = ObservationProvider;
