"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var pages_1 = require("../pages");
var observationProvider_1 = require("../utils/observationProvider");
var navigatorActions_1 = require("./navigatorActions");
var Navigator = /** @class */ (function () {
    function Navigator(stateManager, pageFactory) {
        var _this = this;
        this.stateManager = stateManager;
        this.pageFactory = pageFactory;
        this.toggleMenu = function () {
            _this._menuOpen = !_this._menuOpen;
            _this.notifyOfMenuChange();
        };
        this.closeMenu = function () {
            if (_this._menuOpen) {
                _this._menuOpen = false;
                _this.notifyOfMenuChange();
            }
        };
        this.openMenu = function () {
            if (!_this._menuOpen) {
                _this._menuOpen = true;
                _this.notifyOfMenuChange();
            }
        };
        this.notifyOfMenuChange = function () {
            if (_this._menuOpen) {
                _this.observationProvider.notifyObservers(navigatorActions_1.default.MenuOpened);
            }
            else {
                _this.observationProvider.notifyObservers(navigatorActions_1.default.MenuClosed);
            }
        };
        this.getCurrentPage = function () {
            return _this.stateManager.getState().page;
        };
        this.goToHome = function () {
            _this.goToPage(pages_1.PageTypes.Home);
        };
        this.goToUsers = function () {
            _this.goToPage(pages_1.PageTypes.Users);
        };
        this.goToUserDetail = function (userId) {
            _this.goToPage(pages_1.PageTypes.UserDetail, userId);
        };
        this.goToRegistration = function () {
            _this.goToPage(pages_1.PageTypes.Registration);
        };
        this.goToRegistrationSuccess = function () {
            _this.goToPage(pages_1.PageTypes.RegistrationSuccess);
        };
        this.goToTournamentSignUpSuccess = function () {
            _this.goToPage(pages_1.PageTypes.TournamentSignUpSuccess);
        };
        this.goToLogin = function () {
            _this.goToPage(pages_1.PageTypes.Login);
        };
        this.goToParentAccounts = function () {
            _this.goToPage(pages_1.PageTypes.ParentAccounts);
        };
        this.goToParentAccountDetail = function (registrationId) {
            _this.goToPage(pages_1.PageTypes.ParentAccountDetail, registrationId);
        };
        this.goToTournamentSignUp = function (tournamentId) {
            _this.goToPage(pages_1.PageTypes.TournamentSignUp, tournamentId, true);
        };
        this.goToTournaments = function () {
            _this.goToPage(pages_1.PageTypes.Tournaments);
        };
        this.goToTournamentDetail = function (tournamentId) {
            _this.goToPage(pages_1.PageTypes.TournamentDetail, tournamentId);
        };
        this.goToPage = function (pageType, id, forceNew) {
            if (!forceNew && _this.getCurrentPage() && _this.getCurrentPage().pageType === pageType) {
                return;
            }
            var newPage = _this.pageFactory.createPage(pageType, _this, id);
            _this.stateManager.setPage(newPage);
            _this.closeMenu();
            _this.observationProvider.notifyObservers(navigatorActions_1.default.PageChanged, newPage);
        };
        this.observationProvider = new observationProvider_1.default();
        if (!this.getCurrentPage()) {
            this.goToHome();
        }
    }
    Object.defineProperty(Navigator.prototype, "menuOpen", {
        get: function () {
            return this._menuOpen;
        },
        enumerable: false,
        configurable: true
    });
    return Navigator;
}());
exports.default = Navigator;
