import NavigatorActions from 'core.frontend/dist/navigator/navigatorActions';
import * as React from 'react';
import Header from '../../misc/components/Header';
import ObservingComponent from '../../misc/components/ObservingComponent';
import ReactApp from '../../misc/reactApp';

interface IAppRoot {
    reactApp: ReactApp;
}

class AppRoot extends ObservingComponent<IAppRoot> {

    public componentWillMount(): void {
        const { reactApp } = this.props;

        this.registerUpdateObserver(reactApp.app.navigator.observationProvider, NavigatorActions.PageChanged);
    }

    public render(): JSX.Element {
        const { reactApp } = this.props;

        return (
            <React.Fragment>
                <Header reactApp={reactApp}></Header>

                <main>
                    {reactApp.getCurrentPageComponent()}
                </main>
            </React.Fragment>
        );
    }
}

export default AppRoot;
