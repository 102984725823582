"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TournamentSignUpPageState;
(function (TournamentSignUpPageState) {
    TournamentSignUpPageState[TournamentSignUpPageState["LoadParentAccount"] = 0] = "LoadParentAccount";
    TournamentSignUpPageState[TournamentSignUpPageState["SignUpForm"] = 1] = "SignUpForm";
    TournamentSignUpPageState[TournamentSignUpPageState["Pay"] = 2] = "Pay";
    TournamentSignUpPageState[TournamentSignUpPageState["SignUpInactive"] = 3] = "SignUpInactive";
    TournamentSignUpPageState[TournamentSignUpPageState["ForgetCodeConfirmationPrompt"] = 4] = "ForgetCodeConfirmationPrompt";
})(TournamentSignUpPageState || (TournamentSignUpPageState = {}));
exports.default = TournamentSignUpPageState;
