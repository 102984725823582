import { RegistrationPage, RegistrationPageState } from 'core.frontend';
import * as React from 'react';
import Button from '../../misc/components/Button';
import ObservingComponent from '../../misc/components/ObservingComponent';

interface RegistrationGettingStartedStepProps {
    page: RegistrationPage;
}

class RegistrationGettingStartedStep extends ObservingComponent<RegistrationGettingStartedStepProps> {

    private goToFormStep = (): void => {
        const { page } = this.props;

        page.setState(RegistrationPageState.RegistrationForm);
    }

    public render(): JSX.Element {
        return (
            <div className="container">
                <h4>Let's get started</h4>

                <p>
                    When you click the next button you'll find a short registration form. Once completed you'll reivew the information and pay using your
                    credit card or paypal account. Use the buttons in the form to add multiple wrestlers to your account.
                    Be sure to provide a valid email address so that you can get access to your fundraiser account.
                </p>

                <p>
                    <b>Please Note </b> that we no longer accept new wrestlers that are not part of Neosho School District unless approved prior to registering.
                </p>

                <p>
                    Please ensure that we have a copy of your wrestlers' birth certificate prior to practice starting.
                </p>

                <p>
                    <b>Team Registration Costs</b>
                    <br />
                    1 wrestler registration... <b>$150.00</b>
                    <br />
                    2 wrestler registration... <b>$300.00</b>
                    <br />
                    3 wrestler registration... <b>$450.00</b>
                    <br />
                    4 wrestler registration... <b>$600.00</b>
                    <br />
                    5 wrestler registration... <b>$750.00</b>
                </p>

                <div className="text-right">
                    <Button
                        className="btn btn-primary btn-wide-phone"
                        onClick={this.goToFormStep}
                        rightIcon="fa-chevron-right"
                        text="Next" />
                </div>
            </div>
        );
    }
}

export default RegistrationGettingStartedStep;
