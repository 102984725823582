"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var _1 = require(".");
var ConfirmPasswordField = /** @class */ (function (_super) {
    __extends(ConfirmPasswordField, _super);
    function ConfirmPasswordField(key, isRequired, passwordField, initialValue) {
        var _this = _super.call(this, key, 'Confirm Password', isRequired, 25, 'Password is required', initialValue, _1.FieldType.Password) || this;
        _this.key = key;
        _this.passwordField = passwordField;
        return _this;
    }
    Object.defineProperty(ConfirmPasswordField.prototype, "errorMessage", {
        get: function () {
            var baseErrorMessage = _super.prototype.getErrorMessage.call(this);
            if (baseErrorMessage) {
                return baseErrorMessage;
            }
            if (this.value && !this.passwordsMatch() && this.isDirty) {
                return 'Passwords must match';
            }
            if (this.value && !this.isValidPassword() && this.isDirty) {
                return 'Must be at least 10 characters long';
            }
            return baseErrorMessage;
        },
        enumerable: false,
        configurable: true
    });
    ConfirmPasswordField.prototype.isValidPassword = function () {
        return this.value && this.value.length >= 10;
    };
    ConfirmPasswordField.prototype.passwordsMatch = function () {
        return this.value === this.passwordField.value;
    };
    ConfirmPasswordField.prototype.isValid = function () {
        var isValid = true;
        if (!this.checkRequiredStatus()) {
            isValid = false;
        }
        if (this.stringValue && !this.passwordsMatch()) {
            isValid = false;
        }
        if (this.stringValue && !this.isValidPassword()) {
            isValid = false;
        }
        this.setIsValid(isValid);
        return isValid;
    };
    return ConfirmPasswordField;
}(_1.TextField));
exports.default = ConfirmPasswordField;
