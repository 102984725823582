"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var observationProvider_1 = require("../utils/observationProvider");
var _1 = require("./");
var Form = /** @class */ (function () {
    function Form(_fields, _model) {
        var _this = this;
        if (_fields === void 0) { _fields = []; }
        if (_model === void 0) { _model = {}; }
        this._fields = _fields;
        this._model = _model;
        this.setModel = function (model) {
            _this._model = model;
            _this.setFieldValues();
            _this.observationProvider.notifyObservers(_1.FormActions.ModelLoadedToForm);
        };
        this.setFields = function (fields) {
            _this._fields = fields;
        };
        this.setFieldValues = function () {
            for (var _i = 0, _a = _this._fields; _i < _a.length; _i++) {
                var field = _a[_i];
                if (field.key in _this._model) {
                    field.setValue(_this._model[field.key]);
                }
            }
        };
        this.submit = function () {
            _this.markAllFieldsAsDirty();
            _this.setModelValues();
            if (_this.isValid()) {
                _this.observationProvider.notifyObservers(_1.FormActions.FormSubmitted);
            }
            else {
                _this.observationProvider.notifyObservers(_1.FormActions.FormSubmissionFailure, new _1.FormSubmissionFailure(_1.FormSubmissionFailureTypes.InValidForm, 'Form is not valid'));
            }
        };
        this.markAllFieldsAsDirty = function () {
            for (var _i = 0, _a = _this._fields; _i < _a.length; _i++) {
                var field = _a[_i];
                field.markAsDirty();
            }
        };
        this.setModelValues = function () {
            for (var _i = 0, _a = _this._fields; _i < _a.length; _i++) {
                var field = _a[_i];
                if (field.isValid) {
                    _this._model[field.key] = field.value;
                }
            }
        };
        this.isValid = function () {
            _this.markAllFieldsAsDirty();
            var valid = true;
            for (var _i = 0, _a = _this._fields; _i < _a.length; _i++) {
                var field = _a[_i];
                if (!field.isValid()) {
                    valid = false;
                }
            }
            return valid;
        };
        this.observationProvider = new observationProvider_1.default();
        if (Object.keys(this._model).length) {
            this.setFieldValues();
        }
    }
    Object.defineProperty(Form.prototype, "model", {
        get: function () {
            return this._model;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Form.prototype, "fields", {
        get: function () {
            return this._fields;
        },
        enumerable: false,
        configurable: true
    });
    return Form;
}());
exports.default = Form;
