import { ParentAccountDetailDto } from 'core.frontend';
import * as React from 'react';
import RegistrationAthleteInfo from './RegistrationAtheleteInfo';

interface ParentAccountDetailAthletesTabProps {
    accountDetail: ParentAccountDetailDto;
}

class ParentAccountDetailAthletesTab extends React.PureComponent<ParentAccountDetailAthletesTabProps> {

    public render(): JSX.Element {
        const { accountDetail } = this.props;

        return (
            <div className="row">
                { accountDetail.athletes.map((athlete) => {
                    return (
                        <div className="col-12 col-md-6 guardian-wrapper">
                            <RegistrationAthleteInfo athlete={athlete} />
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default ParentAccountDetailAthletesTab;
